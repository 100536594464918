import { Injectable } from '@angular/core';
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root'
  })
export class PagerService {
    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 15) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage: number, endPage: number;       
        
        if (totalPages <= 4) {
            startPage = 1;
            endPage = totalPages;
            console.log("in <=5");
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 3;
                // console.log("in <=3");
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 3;
                endPage = totalPages;
                console.log("in >=total");
            } else {               
                if((totalPages - (currentPage - 2)) == 4) {
                    console.log("in ===1");
                   startPage = currentPage - 1;
                  endPage = currentPage+2;
                } else {
                    console.log("in last");
                    startPage = currentPage - 1;
                    endPage = currentPage+1;
                }
            }
        }
        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = _.range(startPage, endPage + 1);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}