<div class="custom-modal-overlay">
    <div class="custom-modal  custom-modal-sm subscribe-modal">
        <div class="custom-modal-content p-3 pb-0">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title"><i class="icon-dollar-sign text-info mr-2"></i> Renew
                    Subscription Plan</h4>
                <button type="button" class="close" *ngIf="cancel" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="text-right" *ngIf="!cancel">
                    <button class="btn btn-primary mr-3" (click)="logout()">Logout</button>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-md bg-details pt-5">
                    <!-- <a href="javascript:void(0)" *ngIf="cancel" (click)="closeModal()" class="close">×</a> -->
                    
                    <h4 class="alert alert-info text-danger text-center">
                        Note: Please make sure the payment email address in Stripe matches your Engage login. 
                    </h4>

                    <form [formGroup]="customStripeForm">
                        <div class="row  pricing-plans">
                            <div class="col-md-12">
                              <h4 for="subscriptionPlan" class="custom-class w-100 mb-5" [ngClass]="user_type == 'clinician' ? 'custom_renew_popup_text' : ''">
                                  <!-- <span>Your Plan & Subscription</span> -->
                                  <span>Click the below plan to renew your Subscription </span>
                                <!-- <span class="ml-auto mt-0 clearfix"> Balance Session Time : {{balanceHour}} -->
                                <span class="text-center" [ngClass]="user_type == 'clinician' ? 'hide_session_time' : ''"> Balance Session Time : {{balanceHour}}
                                </span>
                              </h4>
                              
                                <div class="form-group plans-box" [ngClass]="user_type == 'clinician' ? 'custom_renew_popup_box' : ''">
                                    <!-- <div class="custom-control custom-radio custom-control-inline"
                                        *ngFor="let plans of planList">
                                        <input id="plans_{{plans.type}}" value='{{plans.id}}' type="radio"
                                            name="selectPlan" formControlName="selectPlan"
                                            (click)="selectPlanType(plans)" class="custom-control-input"
                                            [(ngModel)]="selectedPlanId">
                                            <label class="custom-control-label" for="plans_{{plans.name}}">
                                                {{plans.name}} ({{plans.hrs}})
                                           </label>
                                    </div> -->
                                    <div class="custom-control custom-radio custom-control-inline" *ngFor="let plans of planList">
                                      <label class="custom-control-label" for="plans_{{plans.name}}">
                                        <input id="plans_{{plans.name}}" value='{{plans.id}}' type="radio" name="selectPlan" formControlName="selectPlan" (click)="selectPlanType(plans)" class="custom-control-input"  [(ngModel)]="selectedPlanId">
                                        <div class="plan-wrapper">
                                          <div class="plan-header">
                                            <h4 class="text-center plan-name">{{plans.name}}</h4>
                                            <h1 class="text-center plan-time">${{plans.amount}} <span><!-- /500000{{plans.display_hrs}}--></span></h1>
                                            <h5 class="text-center plan-time"><span>Billed {{plans.type=='Annual'?'Annually':'Monthly'}}</span></h5>
                                          </div>

                                          <div class="plan-note">
                                            Note: {{plans.notes}}
                                          </div>

                                        </div>
                                      </label>
                                      
                                   </div>
                                   
                                   <!-- <div>
                                      Note:
                                   </div>  -->
                                    <!-- <div *ngFor="let plans of planList">
                                        <span class="text-big"
                                            *ngIf="plans.id == selectedPlanId">${{plans.amount}}</span>
                                    </div> -->
                                    <span *ngIf="formErrors.selectPlan" class="error-msg">
                                        {{formErrors.selectPlan}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="is_show_payment"  style="display:none;">
                          <div class="col-md-12" *ngIf="nextPaymentDue">
                            <div class="form-group d-flex payment-due-date">
                                <label for="subscriptionPlan" class="w-100">Next Payment Due Date: {{nextPaymentDue}}</label>
                                <div class="">
                                    <label>Card No : *******{{stripeCardDtls['lastdigits']}}</label>
                                </div>
                                <div class="">
                                    <label>Exp Date:
                                        {{stripeCardDtls['exp_month']}}/{{stripeCardDtls['exp_year']}}</label>
                                </div>
                            </div>
                        </div>

                          <div class="col-md-6 mx-auto">


                            <div class="row">



                            <div class="col-md-12 mb-5">
                                <label for="paymentMethod" class="w-100 mb-3"> Payment Method</label>
                                <span class="credit-card"><img src="assets/img/credit-card-visa.svg" /></span>
                                <span class="credit-card"><img src="assets/img/credit-card-mastercard.svg" /></span>
                                <span class="credit-card"><img
                                        src="assets/img/credit-card-american-express.svg" /></span>
                            </div>

                            <div class="col-md-12 example example2">
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <div id="card-number" class="input empty"></div>
                                        <label for="card-number"
                                            data-tid="elements_examples.form.card_number_label">Card number</label>
                                        <div class="baseline">
                                            <span *ngIf="cardNumberError" class="error-msg">{{cardNumberError}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <div id="card-expiry" class="input empty"></div>
                                        <label for="card-expiry"
                                            data-tid="elements_examples.form.card_expiry_label">Expiration</label>
                                        <div class="baseline"><span *ngIf="expiryError"
                                                class="error-msg">{{expiryError}}</span> </div>

                                    </div>

                                    <div class="col-md-6 form-group">
                                        <div id="card-cvc" class="input empty"></div>
                                        <label for="card-cvc"
                                            data-tid="elements_examples.form.card_cvc_label">CVC</label>
                                        <div class="baseline">
                                            <span *ngIf="csvError" class="error-msg">{{csvError}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-12">
                    <div class="form-group has-float-label">
                        <input class="form-control" type="text" id="cardNumber" formControlName="cardNumber" placeholder="Card Number">
                        <label for="cardNumber">Card Number</label>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group has-float-label">
                            <input class="form-control" type="text" id="expiryDate" formControlName="expiryDate" placeholder="MM/YY">
                            <label for="expiryDate">Expiration Date</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group has-float-label">
                            <input class="form-control" type="text" id="securityCode" formControlName="CVC"  placeholder="CVC">
                            <label for="securityCode">Security Code</label>
                        </div>
                    </div> -->
                            <div class="col-md-12 mt-5">
                                <div class="form-group has-float-label">
                                    <input class="form-control" type="text" id="nameOnCard" formControlName="nameOnCard"
                                        placeholder="Name on Card">
                                    <label for="nameOnCard">Name on Card</label>
                                    <span *ngIf="formErrors.nameOnCard" class="error-msg">
                                        {{formErrors.nameOnCard}}</span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="subscriptionPlan" class="w-100 mb-3">Billing Address</label>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadioInline3" formControlName="addressRadio"
                                            name="addressRadio" value="existingAddress" class="custom-control-input"
                                            (click)="selectAddressType('existing')" #existing>
                                        <label class="custom-control-label" for="customRadioInline3">Existing
                                            Address</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" value="addNewAddress" id="customRadioInline4"
                                            formControlName="addressRadio" name="addressRadio"
                                            class="custom-control-input" (click)="selectAddressType('addNew')" #addNew>
                                        <label class="custom-control-label" for="customRadioInline4">Add New
                                            Address</label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedAddressType === 'existing'" formGroupName="existingAddress"
                                class="row mx-0">
                                <div class="col-md-12">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" id="addressLine1"
                                            formControlName="addressLine1" placeholder="Address Line 1 (optional)"
                                            value="Schoolhouse St">
                                        <label for="addressLine1">Address Line 1 (optional)</label> <small
                                            class="text-secondary info-text mt-1">(e.g. 635 E 9th St.)</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" id="addressLine2"
                                            formControlName="addressLine2" placeholder="Address Line2 (optional)"
                                            value="Algonquin">
                                        <label for="addressLine2">Address Line 2 (optional)</label> <small
                                            class="text-secondary info-text mt-1">(e.g. Suite 100, Apt 200,
                                            etc.)</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group has-float-label">
                                        <select id="country" formControlName="country" (change)="changecountry($event)"
                                            name="country" class="form-control">
                                            <option value="">-- Select One --</option>
                                            <!-- <option value="Canada">Canada</option>
                            <option value="United States" selected>United States</option> -->
                                            <option *ngFor="let cntry of countryList|keyvalue" [value]=cntry.key>
                                                {{cntry.value}}
                                            </option>
                                        </select>
                                        <label for="country">Country</label>
                                        <span *ngIf="formErrors['existingAddress.country']" class="error-msg">
                                            {{formErrors["existingAddress.country"]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" id="cityTownSuburb"
                                            formControlName="city" placeholder="City" value="Algonquin">
                                        <label for="cityTownSuburb">City</label>
                                        <span *ngIf="formErrors['existingAddress.city']" class="error-msg">
                                            {{formErrors["existingAddress.city"]}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3">
                            <div class="form-group has-float-label">
                                <input class="form-control" type="text" id="stat" formControlName="state"  placeholder="Stat" value="IL">
                                <label for="stat">State</label>
                            </div>
                        </div> -->
                                <div class="col-md-4">
                                    <div class="form-group has-float-label">
                                        <select id="state" name="state" class="form-control" formControlName="state">
                                            <option value="">-- Select One --</option>
                                            <option *ngFor="let state of stateList" [value]=state.id>
                                                {{state.shortName}}
                                            </option>
                                        </select>
                                        <label for="stat" *ngIf="selectedCountry === 'United State'">State</label>
                                        <label for="proviance" *ngIf="selectedCountry === 'canada'">Province</label>
                                        <span *ngIf="formErrors['existingAddress.state']" class="error-msg">
                                            {{countryError}}
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" id="zipcode"
                                            placeholder=" {{selectedCountry === 'United State' ? 'ZIP Code' : 'Postal Code'}}"
                                            formControlName="zipcode">
                                        <label for="zipcode" *ngIf="selectedCountry === 'United State'">ZIP Code</label>
                                        <label for="zipcode" *ngIf="selectedCountry === 'canada'">Postal Code</label>
                                        <div
                                            *ngIf="customStripeForm.controls['existingAddress'].controls['zipcode'].invalid && (customStripeForm.controls['existingAddress'].controls['zipcode'].dirty || customStripeForm.controls['existingAddress'].controls['zipcode'].touched)">
                                            <span
                                                *ngIf="customStripeForm.controls['existingAddress'].controls['zipcode'].errors.required"
                                                class="error-msg">
                                                {{postalcodeError}}
                                            </span>
                                            <span
                                                *ngIf="customStripeForm.controls['existingAddress'].controls['zipcode'].errors.pattern"
                                                class="error-msg">
                                                {{postalcodeValidateError}}
                                            </span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div *ngIf="selectedAddressType === 'addNew'" formGroupName="newAddress" class="row mx-0">

                                <div class="col-md-12">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" formControlName="newAddressLine1" type="text"
                                            id="addressLine1" placeholder="Address Line 1 (optional)">
                                        <label for="addressLine1">Address Line 1 (optional)</label> <small
                                            class="text-secondary info-text mt-1">(e.g. 635 E 9th St.)</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" formControlName="newAddressLine2"
                                            id="addressLine2" placeholder="Address Line2 (optional)">
                                        <label for="addressLine2">Address Line 2 (optional)</label> <small
                                            class="text-secondary info-text mt-1">(e.g. Suite 100, Apt 200,
                                            etc.)</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group has-float-label">
                                        <select id="country" name="country" class="form-control"
                                            formControlName="newCountry" (change)="changecountry($event)">
                                            <option value="">-- Select One --</option>
                                            <option *ngFor="let cntry of countryList|keyvalue" [value]=cntry.key>
                                                {{cntry.value}}
                                            </option>
                                        </select>
                                        <label for="country">Country</label>
                                        <span *ngIf="formErrors['newAddress.newCountry']" class="error-msg">
                                            {{formErrors["newAddress.newCountry"]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" id="city" formControlName="newCity"
                                            placeholder="City">
                                        <label for="city">City</label>
                                        <span *ngIf="formErrors['newAddress.newCity']" class="error-msg">
                                            {{formErrors["newAddress.newCity"]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group has-float-label">
                                        <select id="state" name="state" class="form-control" formControlName="newState">
                                            <option value="">-- Select One --</option>
                                            <option *ngFor="let state of stateList" [value]=state.id>
                                                {{state.shortName}}
                                            </option>
                                        </select>
                                        <label for="stat" *ngIf="selectedCountry === 'United State'">State</label>
                                        <label for="proviance" *ngIf="selectedCountry === 'canada'">Province</label>
                                        <span *ngIf="formErrors['newAddress.newState']" class="error-msg">
                                            {{countryError}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group has-float-label">
                                        <input class="form-control" type="text" id="newZipcode"
                                            placeholder=" {{selectedCountry === 'United State' ? 'ZIP Code' : 'Postal Code'}}"
                                            formControlName="newZipcode">
                                        <label for="newZipcode" *ngIf="selectedCountry === 'United State'">ZIP
                                            Code</label>
                                        <label for="newZipcode" *ngIf="selectedCountry === 'canada'">Postal Code</label>
                                        <div
                                            *ngIf="customStripeForm.controls['newAddress'].controls['newZipcode'].invalid && (customStripeForm.controls['newAddress'].controls['newZipcode'].dirty || customStripeForm.controls['newAddress'].controls['newZipcode'].touched)">
                                            <span
                                                *ngIf="customStripeForm.controls['newAddress'].controls['newZipcode'].errors.required"
                                                class="error-msg">
                                                {{postalcodeError}}
                                            </span>
                                            <span
                                                *ngIf="customStripeForm.controls['newAddress'].controls['newZipcode'].errors.pattern"
                                                class="error-msg">
                                                {{postalcodeValidateError}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-3">
                                <span class="mb-3" style="font-weight: 600">Note: </span>
                                <span class="mb-3">Recurring payment will be automatically takes place based on opted subscription plan.</span>
                            </div>
                            <div class="col-md-12 mb-5 text-center">
                                <button class="btn btn-primary mr-3" (click)="renewSubscriptionModal()">Pay</button>
                                <button *ngIf="cancel" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
                            </div>
                        </div>
                      </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-8 mr-auto ml-auto">
        <div class="panel panel-default">
          <form [formGroup]="customStripeForm" (ngSubmit)="pay(customStripeForm.value)">
            <div class="panel-body mt-5">
              <h4>Stripe - Custom form demo</h4>
              <p><b>{{ message }}</b></p>
              <div class="row">
                <div class="col-xs-12 col-md-12">
                    <div id="card-number"></div>
                       <div *ngIf="stripecardNumberError">
                           {{ stripecardNumberError }}
                       </div>

                  <div class="form-group">
                    <label>CARD NUMBER</label>
                    <div class="input-group">
                      <input type="text" formControlName="cardNumber" class="form-control" placeholder="Valid Card Number" name="cardNumber" maxlength="18" />
                      <span class="input-group-addon">
                        <span class="fa fa-credit-card"></span>
                      </span>
                    </div>
                    <div *ngIf="submitted &amp;&amp; customStripeForm.controls.cardNumber.errors" class="text-danger">
                      <div *ngIf="customStripeForm.controls.cardNumber.errors.required">Card number is required</div>
                    </div>
                    <span *ngIf="formErrors.customStripeForm" class="error-msg">
                        {{formErrors.customStripeForm}}
                     </span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-7 col-md-7">
                  <div class="form-group">
                    <label><span class="hidden-xs">EXPIRATION</span> Date</label>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <select formControlName="expMonth" class="form-control">
                            <option value="">Select Month</option>
                            <option value="1">01</option>
                            <option value="2">02</option>
                            <option value="3">03</option>
                            <option value="4">04</option>
                            <option value="5">05</option>
                            <option value="6">06</option>
                            <option value="7">07</option>
                            <option value="8">08</option>
                            <option value="9">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 pull-right">
                      <div class="form-group">
                        <select formControlName="expYear" class="form-control">
                          <option value="">Select Year</option>
                          <option value="20">2020</option>
                          <option value="21">2021</option>
                          <option value="22">2022</option>
                          <option value="23">2023</option>
                          <option value="24">2024</option>
                          <option value="25">2025</option>
                          <option value="26">2026</option>
                          <option value="27">2027</option>
                          <option value="28">2028</option>
                          <option value="29">2029</option>
                          <option value="30">2030</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="submitted &amp;&amp; customStripeForm.controls.expMonth.errors" class="text-danger">
                    <div *ngIf="customStripeForm.controls.expMonth.errors.required">Expiration month is required</div>
                  </div>

                  <div *ngIf="submitted &amp;&amp; customStripeForm.controls.expYear.errors" class="text-danger">
                    <div *ngIf="customStripeForm.controls.expYear.errors.required">Expiration year is required</div>
                  </div>
                </div>
              </div>
              <div class="col-xs-5 col-md-5 pull-right">
                <div class="form-group">
                  <label>CV CODE</label>
                  <input type="text" formControlName="cvv" class="form-control" placeholder="CVC" maxlength="4" />
                </div>
                <div *ngIf="submitted &amp;&amp; customStripeForm.controls.cvv.errors" class="text-danger">
                  <div *ngIf="customStripeForm.controls.cvv.errors.required">CVV is required</div>
                  <div *ngIf="customStripeForm.controls.cvv.errors.minlength">CVV must be at least 3 characters</div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <button class="btn btn-warning btn-lg btn-block" *ngIf="!formProcess">Process payment</button>
                <span class="btn btn-warning btn-lg btn-block" *ngIf="formProcess">Processing please wait...</span>
              </div>
            </div>
          </div>
        </form>
        <p class="mt-2">Try it out using the test card number 4242 4242 4242 4242, a random three-digit CVC number and any expiration date in the future.</p>
      </div>
    </div>
  </div>
  </div> -->
