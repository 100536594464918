<div class="sidebar-inner">
  <div class="d-block d-lg-none">
    <div class="mobile-header clearfix">
      <div class="profile-img mb-3 d-none">
        <div *ngIf="!getProfileImage()" class="profile-initial">
          {{getFirstName() | slice:0:1 }}
        </div>
        <img *ngIf="getProfileImage()" src="{{getProfileImage()}}" alt="profile" class="img-fluid">
        <!-- <img src="{{getProfileImage()}}" alt="profile" class="img-fluid"> -->
        <!-- <img src="assets/img/profile.jpg" alt="profile" class="img-fluid"> -->
        <!-- <img *ngIf="profileImagePath" src="profileImagePath" alt="profile" class="img-fluid">
            <img src="assets/img/profile.jpg" alt="profile" class="img-fluid"> -->
      </div>
    </div>
    <div class="welcome-text text-center mb-3">
      <small>Welcome</small>
      <h4>{{getFirstName()}} {{getLastName()}}</h4>
      <button class="btn text-white logout-btn mr-3 ml-5" (click)="logout()"><i class="icon-power"></i></button>
    </div>
    <!-- <div class="sidebar-actions" id="sidebar-actions">
            <ul>
                <li *ngIf="getAccessControl() == 'admin'">
                    <a href="javascript:void(0)" class="bg-primary" routerLink="/admin-profile">
                        <i class="icon-edit-2"></i>
                    </a>
                </li>
                <li *ngIf="getAccessControl() == 'clinician'">
                    <a href="javascript:void(0)" class="bg-primary" routerLink="/clinicians/clinician-profile">
                        <i class="icon-edit-2"></i>
                    </a>
                </li>
                <li *ngIf="getAccessControl() == 'clinician'">
                    <a href="javascript:void(0)" class="bg-success" routerLink="/change-password" tabindex="-1"
                        aria-disabled="true">
                        <i class="icon-key"></i>
                    </a>
                </li>
                <li *ngIf="getAccessControl() == 'admin'">
                    <a href="javascript:void(0)" class="bg-success" routerLink="/change-password" tabindex="-1"
                        aria-disabled="true">
                        <i class="icon-key"></i>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="bg-danger" (click)="logout()" tabindex="-1"
                        aria-disabled="true">
                        <i class="icon-power"></i>
                    </a>
                </li>
            </ul>
        </div>
        <hr> -->
  </div>
  <div class="sidebar-menu" id="sidebar-menu">
    <ul *ngIf="isAdminLoggedIn">
      <li>
        <a href="javascript:void(0)" routerLink="/dashboard" routerLinkActive="is-active">
          <i class="icon-home1"></i> Dashboard
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/clinicians-management" routerLinkActive="is-active">
          <i class="icon-users"></i>Clinicians
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/survey-dashboard" routerLinkActive="is-active">
          <i class="icon-bar-chart"></i> Survey
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/message-board-mgmt" routerLinkActive="is-active">
          <i class="icon-message-square"></i> Message Board
        </a>
      </li>

      <li>
        <a href="javascript:void(0)" routerLink="/message-mgmt" routerLinkActive="is-active">
          <i class="icon-message-circle"></i> Inappropriate
          Message/<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Comments
        </a>
      </li>

    </ul>
    <ul *ngIf="!isAdminLoggedIn">
      <li>
        <!-- <a href="javascript:void(0)">
                 <i class="icon-users"></i> Waiting Clients ({{count}})
                  <i class="icon-refresh-ccw mr-5" ngbTooltip="refresh Session" (click)="getSessions()"  style="float: right;
                  padding-right: 0px; vertical-align: top; margin-top: 6px;display: inline-block;"></i>
                </a> -->
        <a href="javascript:void(0)" style="padding-right: 0px">
          <div class="d-flex justify-content-between align-items-start">
            <div><i class="icon-users"></i> Waiting Clients ({{count}})
            </div>
            <div> <i class="icon-refresh-ccw mr-3" ngbTooltip="Refresh Session" (click)="getSessionsRefresh()"
                style="font-size:18px;"></i> </div>
          </div>
        </a>
        <ul class="waiting-room">
          <li *ngFor="let data of waitingroom_data" class="mb-3 d-flex justify-content-between align-items-start">
            <i *ngIf="waiting_room_id != data.waiting_id" class="icon-trash-2 text-danger" placement="right"
              ngbTooltip="Delete Session" (click)="isdeleteSession(data)"></i>
            <div class="detail flex-1">
              <p class="text-white mb-0">{{data.client_name}} </p>
              <!-- <p class="text-white mb-0" placement="bottom" ngbTooltip="Last Seen" style="font-size: 12px;">(Last Seen : {{data.lastSeen}})</p> -->
              <span class="text-danger">Waiting {{data.duration}}</span>
            </div>
            <div>
              <i *ngIf="waiting_room_id != data.waiting_id" class="icon-video text-info start-session" placement="left"
                ngbTooltip="Start Session" (click)="isValidSession(data)"></i>
              <!-- <i *ngIf="waiting_room_id != data.waiting_id" class="icon-trash-2 text-danger" placement="left"
                         ngbTooltip="Delete Session" (click)="isdeleteSession(data)"></i> -->
            </div>

          </li>
        </ul>
      </li>

      <li>
        <a href="javascript:void(0)" routerLink="/clinicians/dashboard" routerLinkActive="is-active">
          <i class="icon-home1"></i> Dashboard
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/survey-dashboard" routerLinkActive="is-active">
          <i class="icon-bar-chart"></i> Survey
        </a>
      </li>
      <li>
        <a href="javascript:void(0)"  (click)="subscriptionPopup()" routerLinkActive="is-active">
          <!-- <i class="icon-aperture"></i> -->
          <img class="w-10" src="assets/img/newIcons/icon-subscription.png" alt="Manage Subscription">
          Manage Subscription
        </a>
      </li>
      <!-- <li>
                <a href="javascript:void(0)" routerLink="/clinicians/appointments" routerLinkActive="is-active">
                    <i class="icon-calendar"></i> Sessions
                </a>
            </li> -->
      <!-- <li> -->
      <!-- <a href="https://headway.co/" routerLinkActive="is-active"> -->
      <!-- <a href="https://headway.co/" routerLinkActive="is-active"> -->
      <!-- <a [routerLink]="" (click)="OnClick()" target="_blank">
            <i class="icon-external-link"></i> Headway
        </a>
      </li> -->
      <!-- <li>
        <a href="javascript:void(0)" routerLink="/clinicians/game" routerLinkActive="is-active">
          <i class="icon-calendar"></i> Games
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/clinicians/patients-management" routerLinkActive="is-active">
          <i class="icon-users"></i> Clients
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/survey-dashboard" routerLinkActive="is-active">
          <i class="icon-bar-chart"></i> Survey
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/clinicians/subscription" routerLinkActive="is-active">
          <i class="icon-dollar-sign"></i> Subscription
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" routerLink="/clinicians/calendar" routerLinkActive="is-active">
          <i class="icon-calendar"></i> Calendar
        </a>
      </li> -->
      <!-- <li>
        <a href="javascript:void(0)" routerLink="/clinicians/subscription" routerLinkActive="is-active">
          <i class="icon-dollar-sign"></i> Subscription
        </a>
      </li> -->
      <!-- <li>
        <a href="javascript:void(0)" routerLink="/clinicians/game" routerLinkActive="is-active">
          <i class="icon-calendar"></i> Games
        </a>
      </li> -->
      <li>
        <a href="javascript:void(0)" routerLink="/support/clinician" routerLinkActive="is-active">
          <i class="icon-help-circle"></i> Help/Support
        </a>
      </li>

      <li>
        <a href="javascript:void(0)" (click)="redirectToYoutube()" routerLinkActive="is-active">
          <i class="icon-video"></i> Play Onboarding Video
        </a>
      </li>

      <li>
        <a href="javascript:void(0)" (click)="redirectToCalendly()" routerLinkActive="is-active">
          <i class="icon-calendar"></i> Schedule a demo</a>
      </li>

      <!-- <li>
        <a href="javascript:void(0)" routerLink="/clinicians/message" routerLinkActive="is-active">
          <i class="icon-message-square"></i> Message Board <span id="commentCnt" *ngIf="unreadCommentCount > 0"
            class="badge">{{unreadCommentCount}}</span>
        </a>
      </li> -->

      <!-- <li>
        <a href="javascript:void(0)" routerLink="/clinicians/tutorial-videos-link" routerLinkActive="is-active">
          <i class="icon-video"></i> Tutorial Videos
        </a>
      </li> -->

      <li class="d-list d-lg-none" *ngIf="getAccessControl() == 'admin'">
        <a href="javascript:void(0)" routerLink="/admin-profile">
          <i class="icon-user"></i> Profile
        </a>
      </li>
      <li class="d-list d-lg-none" *ngIf="getAccessControl() == 'clinician'">
        <a href="javascript:void(0)" routerLink="/clinicians/clinician-profile">
          <i class="icon-user"></i> Profile
        </a>
      </li>
      <li class="d-list d-lg-none" *ngIf="getAccessControl() == 'clinician'">
        <a href="javascript:void(0)" routerLink="/change-password" tabindex="-1" aria-disabled="true">
          <i class="icon-key"></i> Change Password
        </a>
      </li>
      <li class="d-list d-lg-none" *ngIf="getAccessControl() == 'admin'">
        <a href="javascript:void(0)" routerLink="/change-password" tabindex="-1" aria-disabled="true">
          <i class="icon-key"></i> Change Password
        </a>
      </li>
      <li class="d-list d-lg-none">
        <a href="javascript:void(0)" (click)="logout()" tabindex="-1" aria-disabled="true">
          <i class="icon-power"></i> Log Out
        </a>
      </li>
    </ul>
  </div>
  <div class="d-flex align-items-end">
    <ul>
      <li class="text-center ">
        <a class="d-inline social-icons" target="_blank" href="https://www.instagram.com/engageplaytherapy/?hl=en"
          routerLinkActive="is-active" title="Instagram">
          <img class="w-10" src="assets/img/instagram.png" alt="instagram">
        </a>
        <a class="d-inline social-icons" target="_blank"
          href="https://www.facebook.com/pages/category/Mental-Health-Service/Engage-Play-Therapy-112333163881078/"
          routerLinkActive="is-active" title="Facebook">
          <img class="w-10" src="assets/img/facebook.png" alt="facebook">
        </a>
        <a class="d-inline social-icons" target="_blank" href=" https://www.linkedin.com/company/engage-play-therapy/"
          routerLinkActive="is-active" title="Linkedin">
          <img class="w-10" src="assets/img/linkedin.png" alt="linkedin">
        </a>

        <a target="_blank" href="https://youtube.com/channel/UCv3E0zVvM38oJrOiHfrDlkQ" title="Youtube">
          <img class="w-10" src="assets/img/youtube.png" alt="youtube">
        </a>
        <a target="_blank" href="https://mobile.twitter.com/EngagePlayGrow" routerLinkActive="is-active"
          title="Twitter X">
          <img class="w-10" src="../../assets/img/twitterx.svg" alt="twitter x" style=" width: 20px;">
        </a>
        <a class="d-inline social-icons" target="_blank" href="https://engageplaytherapy.com/"
          routerLinkActive="is-active" title="Rocket">
          <img class="w-10 circle" src="assets/img/rocket-circle.png" alt="Rocket">
        </a>
      </li>
    </ul>
  </div>
</div>