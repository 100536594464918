<main class="wrapper">
  <app-header></app-header>
  <div class="inner-wrapper" #mainContent>
    <div class="humburger-menu">
      <a href="javascript:void(0)" (click)="toggleSidebar()">
        <i class="icon-menu1"></i>
      </a>
    </div>
    <div *ngIf="sidebarToggle" class="sidebar-overlay" (click)="toggleSidebar()"></div>
    <div *ngIf="isUserTypeSet()" [ngClass]="sidebarToggle ? 'sidebar sidebar-toggle card' : 'sidebar card' ">
      <app-sidebar></app-sidebar>
    </div>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    </ngx-spinner>

    <div class="video-overlay" *ngIf="showVideo">
      <div class="video-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/hxKOgbtBY7k?autoplay=1&mute=0"
          frameborder="0" allowfullscreen allow="autoplay"></iframe>
        <button class="close-button" (click)="closeVideo()">Close</button>
      </div>
    </div>


    <div [ngClass]="isUserLoggedin() ? 'main-panel' : 'main-panel not-loggedin'">
      <div class="inner-panel card">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
      </div>

      <div class="footer">
        <app-footer></app-footer>
      </div>
    </div>


  </div>
</main>