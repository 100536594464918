import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService, HttpReqMethod } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

constructor(private httpClient: HttpClient,
            private apiService: ApiService) { 

    }
    
 /* * *
 * Added By: Bhagyashri Nikam.<bhagyashrinikam@benchmarkit.solution>
 * Date on: 09 - Oct - 2020;
 * Get Clinicians Client.
 */
getClientSearchData(data){
    return this.apiService.Request(environment.userServiceUrl + 'dashboard/client-search-data', HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );
  }

 /* * *
 * Added By: Bhagyashri Nikam.<bhagyashrinikam@benchmarkit.solution>
 * Date on: 09 - Oct - 2020;
 * Get Clinicians Appointments.
 */
getAppointmentSearchData(data){
    return this.apiService.Request(environment.userServiceUrl + 'dashboard/appointment-search-data', HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );
  }

 /* * *
 * Added By: Bhagyashri Nikam.<bhagyashrinikam@benchmarkit.solution>
 * Date on: 09 - Oct - 2020;
 * Get Clinicians Appointments.
 */
getAdminDashboardDetails(data){
  return this.apiService.Request(environment.userServiceUrl + 'dashboard/admin-dashboard-details', HttpReqMethod.POST.toString(), data).pipe(
    map(res => {
      return res;
    }),
   catchError(this.handleError)
  );
}


/* * *
 * Added By: Bhagyashri Nikam.<bhagyashrinikam@benchmarkit.solution>
 * Date on: 09 - Oct - 2020;
 * Get Clinicians Appointments.
 */
getWaitingRoomDetails(){
  // const url = environment.userServiceUrl + 'dashboard/waiting-room-data';
  // return this.apiService.Request(url, HttpReqMethod.GET.toString());
  return this.apiService.Request(environment.userServiceUrl + 'dashboard/waiting-room-data', HttpReqMethod.POST.toString()).pipe(
    map(res => {
      return res;
    }),
   catchError(this.handleError)
  );
}


  //handle error
  handleError(error: HttpErrorResponse) {
    return throwError(error.error || 'Server error');
  }
}
