<div class="login-page">
    <!--ACCOUNT CREATED FORM-->
    <div class="inner-login" [style.background]="isUserLoggedIn ? '#fafafa' : null"> 
        <div class="logo" *ngIf="!isUserLoggedIn">
            <img src="assets/img/logo.png" alt="logo">
        </div>
        <h1 class="text-center mt-0">Help/Support</h1>
        <!-- start User Type Youtube video link  -->
        <br>
        <div class="container">
            <div class="row">
                <div class="col-md-4 m-0 p-0 text-center">
                    <a href="javascript:void(0)" (click)="openYoutubeModel('Clinician',userVideoUrl.Clinician)" ngbTooltip="Checkout video for Clinician view">
                        <i class="icon-video"></i>&nbsp;Clinician view 
                    </a>
                </div>
                <div class="col-md-4 m-0 p-0 text-center">
                    <a href="javascript:void(0)" (click)="openYoutubeModel('Client',userVideoUrl.Client)" ngbTooltip="Checkout video for Client view"> <i class="icon-video"></i>&nbsp;Client view </a>
                </div>
                <div class="col-md-4 m-0 p-0 text-center">
                    <a href="javascript:void(0)" (click)="openYoutubeModel('Session',userVideoUrl.Session)" ngbTooltip="Checkout video for Session view"> <i class="icon-video"></i>&nbsp;Session view</a>
                </div>
            </div>
        </div>
        <!-- end -->
        <form [formGroup]="supportForm" novalidate class="mt-5">

            <div class="form-group has-float-label field-with-icon position-relative">
                <input class="form-control" type="text" id="username" placeholder="Username" formControlName="username" [(ngModel)]="userName" (change)="trimVal('u')">
                <label for="username">Username</label>
                <span *ngIf="formErrors.username" class="error-msg">
                        {{formErrors.username}}
                 </span>
            </div>

            <div class="form-group has-float-label field-with-icon position-relative">
                <input class="form-control" type="text" id="email" placeholder="Email" formControlName="email" [(ngModel)]="email" (change)="trimVal('e')">
                <label for="email">Email</label>
                <span *ngIf="formErrors.subject" class="error-msg">
                        {{formErrors.email}}
                 </span>
            </div>

            <div class="form-group has-float-label field-with-icon position-relative">
                <input class="form-control" type="text" id="subject" placeholder="Subject" formControlName="subject" (change)="trimVal('s')">
                <label for="subject">Subject</label>
                <span *ngIf="formErrors.subject" class="error-msg">
                        {{formErrors.subject}}
                 </span>
            </div>

            <div class="form-group has-float-label field-with-icon position-relative">
                <textarea class="form-control" type="text" id="message" placeholder="Message" formControlName="message" rows="4" (change)="trimVal('m')"></textarea>
                <label for="message">Message</label>
                <span *ngIf="formErrors.message" class="error-msg">
                        {{formErrors.message}}
                 </span>
            </div>

            <div class="d-flex mb-3">
                <button class="btn btn-lg btn-primary w-100" (click)="sendMail()">Send</button>
            </div>

            <div class="d-flex justify-content-center align-items-center pt-4" *ngIf="!isUserLoggedIn">
                <a href="javascript:void(0)" routerLink="/login">Sign In</a>
           </div>

           <div class="d-flex justify-content-center align-items-center pt-4 mb-5" >
            <a href="{{BBAPDFPath}}"  target="_blank">Business Associate Agreement</a>
           </div>
        </form>
    </div>
</div>