<div class="container">
    <div class="row">
        <div class="modal-header w-100">
            <h4 class="modal-title justify-content-center">{{selectedUsertype}} View</h4>
            <button type="button" class="close" aria-label="Close" (click)="closeVideoModel()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <iframe class="mr-l6 w-100" [src]="safeurl" height="400" frameborder="0" allowfullscreen></iframe>
    </div>    
</div>