import { Component, OnInit,ChangeDetectorRef ,Input } from '@angular/core';
import { FormGroup,FormControl, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ValidateFormService } from '../../../app/services/validate-form.service';
import{ GlobalConstants } from '../../constants/global-constants';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { DynamicScriptLoaderService } from '../../../app/services/dynamic-script-loader.service';
import { cliniciansService } from '../../../app/services/clinicians.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../../app/services/auth.service';
import { Router,NavigationStart} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {finalize } from 'rxjs/operators';

declare var Stripe: any;

@Component({
  selector: 'app-subscription-expire-modal',
  templateUrl: './subscription-expire-modal.component.html',
  styleUrls: ['./subscription-expire-modal.component.scss'],
  providers: [ValidateFormService,cliniciansService]
})
export class SubscriptionExpireModalComponent implements OnInit {
  submitted : boolean = false; 
  @Input() fromParent;
  @Input() cancel;
  clinicianId='';
  userData: object;
  selectedState:string = '';
  selectedPlanId = '';
  stripeSubscriptionId = '';
  stripeSubscriptionIdChk = '';
  clinicianSubsciptionId='';
  clinicianSubsciptionIdChk='';
  stripeCardDtls:any;
  selectedStateName = '';
  user_type: string;

  constructor(private formBuilder: FormBuilder,  
    protected validateForm: ValidateFormService,
    private cd: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    private scriptoader:DynamicScriptLoaderService,
    public cliniciansService: cliniciansService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private spinner :  NgxSpinnerService
    ) { 
      this.onBrowserBack();
     }
  customStripeForm: FormGroup;
  cardNumber: any;
  stripe:any;
  cardNumberError: any;
  expiryError: any;
  csvError: any;
  cardExpiry:any;
  cardCvc:any;
  cardHandler = this.onChange.bind(this);
  selectedAddressType : string = 'existing';
  countryList = [];
  planList = [];
  stateList = [];
  selectedCountryId='';
  selectedCountry='';
  countryError :string = GlobalConstants.STATE_REQUIRED;
  postalcodeError: string = GlobalConstants.ZIPCODE_REQUIRED;
  postalcodeValidateError:any;
  isPresent;
  nextPaymentDue='';

  ngOnInit(): void {
    this.user_type = localStorage.getItem('user_type');
    this.clinicianId = this.fromParent;
    this.getCurrentUser(this.clinicianId);
    this.buildForm();    
    this.formControlValueChanged();
    this.fetchSearchDropdowns();
    // this.GetClinicianData();
    /*this.scriptoader.loadScript('https://js.stripe.com/v3/').then(() => {
      this.stripe = Stripe(environment.stripePublishKey);
      var elements = this.stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      var elementStyles = {
        base: {
          iconColor: '#666EE8',
          color: '#495057',
          lineHeight: '40px',
          fontWeight: 400,
          fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#495057'
          }
        },
        invalid: {
          color: '#e4584c',
          iconColor: '#495057'
        }
      };

      var elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      };     

      this.cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardNumber.mount('#card-number');
  
      this.cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardExpiry.mount('#card-expiry');
    
      this.cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardCvc.mount('#card-cvc');
  
      console.log(this.cardNumber)
      this.cardNumber.addEventListener('change',this.cardHandler);
      this.cardExpiry.addEventListener('change',this.cardHandler);
      this.cardCvc.addEventListener('change',this.cardHandler);
  
      // this.cardNumber.on('blur', function(event) {    
      // });

    });*/

  }


  selectAddressType(e: string) {
    this.selectedAddressType = e;    
  }
  is_show_payment: boolean = false;
  selectPlanType(val){
    this.selectedPlanId = val.id;
    // this.is_show_payment = true;
    // this.loadStripe();
    this.spinner.show();
    // this.subscriptionUpdte();
    if(val.type=='Annual'){
        window.location.href = 'https://buy.stripe.com/cN2bLa8UF2AC02k6op'; //live
      }else{
        window.location.href = 'https://buy.stripe.com/28o02s8UF0suaGY4gg'; //live
      }
    // window.location.href = 'https://buy.stripe.com/test_4gw6sm6bV4Oegc87ss';
  }

  formControlValueChanged() {      
    this.customStripeForm.get('addressRadio').valueChanges.subscribe(
        (mode: any) => {
          console.log(mode);
            if (mode == 'addNewAddress') {
              this.newAddressGroupValidation();
              this.clearExistingAddressGroupValidation();
            }
            else {
              this.existingAddressGroupValidation();
               this.clearnewAddressGroupValidation();
            }            
        });

}

  newAddressGroupValidation(){
    const newCity = this.customStripeForm.get('newAddress.newCity');
    const newState = this.customStripeForm.get('newAddress.newState');
    const newZipcode = this.customStripeForm.get('newAddress.newZipcode');
    const newCountry = this.customStripeForm.get('newAddress.newCountry');
    newCity.setValidators([Validators.required]);
    newState.setValidators([Validators.required]);
    newZipcode.setValidators([Validators.required]);
    newCountry.setValidators([Validators.required]);
    newCity.updateValueAndValidity();
    newState.updateValueAndValidity();
    newZipcode.updateValueAndValidity();
    newCountry.updateValueAndValidity();
  }

  clearnewAddressGroupValidation(){
    const newCity = this.customStripeForm.get('newAddress.newCity');
    const newState = this.customStripeForm.get('newAddress.newState');
    const newZipcode = this.customStripeForm.get('newAddress.newZipcode');
    const newCountry = this.customStripeForm.get('newAddress.newCountry');
    newCity.clearValidators();
    newState.clearValidators();
    newZipcode.clearValidators();
    newCountry.clearValidators();
    newCity.updateValueAndValidity();
    newState.updateValueAndValidity();
    newZipcode.updateValueAndValidity();
    newCountry.updateValueAndValidity();
  }

  existingAddressGroupValidation(){  
    const oldCity = this.customStripeForm.get('existingAddress.city');
    const oldState = this.customStripeForm.get('existingAddress.state');
    const oldZipcode = this.customStripeForm.get('existingAddress.zipcode');
    const oldCountry = this.customStripeForm.get('existingAddress.country');
    oldCity.setValidators([Validators.required]);
    oldState.setValidators([Validators.required]);
    oldZipcode.setValidators([Validators.required]);
    oldCountry.setValidators([Validators.required]);
    oldCity.updateValueAndValidity();
    oldState.updateValueAndValidity();
    oldZipcode.updateValueAndValidity();
    oldCountry.updateValueAndValidity();
  }

  clearExistingAddressGroupValidation(){
    const oldCity = this.customStripeForm.get('existingAddress.city');
    const oldState = this.customStripeForm.get('existingAddress.state');
    const oldZipcode = this.customStripeForm.get('existingAddress.zipcode');
    const oldCountry = this.customStripeForm.get('existingAddress.country');
    oldCity.clearValidators();
    oldState.clearValidators();
    oldZipcode.clearValidators();
    oldCountry.clearValidators();
    oldCity.updateValueAndValidity();
    oldState.updateValueAndValidity();
    oldZipcode.updateValueAndValidity();
    oldCountry.updateValueAndValidity();
  }


onChange(error){
  console.log(error.elementType)
  if(error.elementType == 'cardNumber')
  this.cardNumberError = (error.error && error.elementType === 'cardNumber' ) ? error.error.message : null;

  if(error.elementType == 'cardExpiry')
  this.expiryError = (error.error && error.elementType === 'cardExpiry' ) ? error.error.message : null;

  if(error.elementType == 'cardCvc')
  this.csvError = (error.error && error.elementType === 'cardCvc' ) ? error.error.message : null;
  this.cd.detectChanges();
}

formErrors = { 
  'selectPlan': '',
  'nameOnCard': '',
  'addressRadio': '',
  'existingAddress.country': '',
  'existingAddress.zipcode': '',
  'existingAddress.state': '',
  'existingAddress.city': '',
  'existingAddress.addressLine2': '',
  'existingAddress.addressLine1': '',  
  'newAddress.newZipcode': '',
  'newAddress.newCountry':'',
  'newAddress.newState':'',
  'newAddress.newCity':'',
  'newAddress.newAddressLine2':'',
  'newAddress.newAddressLine1':'',  
};

validationMessages = {   
  'selectPlan': { 
    'required': GlobalConstants.PLAN_REQUIRED       
  },
  'nameOnCard': {
      'required': GlobalConstants.NAMEONCARD_REQUIRED,
      'pattern': GlobalConstants.VALID_NAMEONCARD
    }, 
  'addressRadio': {},
  'existingAddress.country': {'required': GlobalConstants.COUNTRY_REQUIRED }, 
  'existingAddress.zipcode': {'required': GlobalConstants.PLAN_REQUIRED },
  'existingAddress.state': {'required': GlobalConstants.PLAN_REQUIRED }, 
  'existingAddress.city': {'required': GlobalConstants.CITY_REQUIRED }, 
  'existingAddress.addressLine2': {},
  'existingAddress.addressLine1': {},
  'newAddress.newZipcode': {'required': GlobalConstants.PLAN_REQUIRED }, 
  'newAddress.newCountry':{'required': GlobalConstants.COUNTRY_REQUIRED }, 
  'newAddress.newState':{'required': GlobalConstants.PLAN_REQUIRED }, 
  'newAddress.newCity':{'required': GlobalConstants.CITY_REQUIRED }, 
  'newAddress.newAddressLine2':'',
  'newAddress.newAddressLine1':'', 
}

  buildForm(): void {
    this.customStripeForm = this.formBuilder.group({
      selectPlan: ['',Validators.required],
      nameOnCard: ['',[Validators.required, Validators.pattern('^[a-zA-Z0-9 \-]+$')]], 
      addressRadio: ['existingAddress',Validators.required],
      existingAddress: this.formBuilder.group({
        addressLine1: [''],
        addressLine2: [''],
        city: ['',Validators.required],
        state: ['',Validators.required],
        zipcode: ['',Validators.required],
        country: ['',Validators.required]
      }),
      newAddress: this.formBuilder.group({
        newAddressLine1: [''],
        newAddressLine2: [''],
        newCity: [''],
        newState: [''],
        newZipcode: [''],
        newCountry: ['']
      }),

    });
  }

  changecountry($e){    
    this.selectedCountryId = $e.target.value;
    console.log($e);  
    this.getStateList();
    this.setCountrywiseLabel()
  }

  setCountrywiseLabel(){
    const postalCode = this.customStripeForm.get('newAddress.newZipcode');
    const existingZipcode = this.customStripeForm.get('existingAddress.zipcode');
    if(this.selectedCountryId == 'd02c7155-0d05-11ea-81b5-8aab01b8e040'){
      this.selectedCountry = 'canada';      
      this.countryError = GlobalConstants.PROVIANCE_REQUIRED;
      this.postalcodeError = GlobalConstants.POASTALCODE_REQUIRED;
      this.postalcodeValidateError = GlobalConstants.POSTALCODE_VALID;    
      if(this.selectedAddressType === 'addNew'){      
        postalCode.setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9 \'\-]+$")]);
      }else{
        existingZipcode.setValidators([Validators.required,Validators.pattern("^[a-zA-Z0-9 \'\-]+$")]);
      }
    }else{
      this.selectedCountry = 'United State';
      this.countryError = GlobalConstants.STATE_REQUIRED;
      this.postalcodeError = GlobalConstants.ZIPCODE_REQUIRED;
      this.postalcodeValidateError = GlobalConstants.ZIPCODE_VALID;
      if(this.selectedAddressType === 'addNew'){    
        postalCode.setValidators([Validators.required, Validators.pattern('[0-9]+$')]);
      }else{
        existingZipcode.setValidators([Validators.required, Validators.pattern('[0-9]+$')]);
      }
    }
    postalCode.updateValueAndValidity();
    existingZipcode.updateValueAndValidity();
  }

  getStateList() {
    this.stateList = [];  
      let data = {
        "countryId":  this.selectedCountryId
      }  
      this.cliniciansService.getStateListCountrywise(data).pipe(
        finalize(() => this.spinner.hide())
       ).subscribe(
        (data) => {      
        this.stateList = data;    
        this.customStripeForm.get("newAddress.newState").setValue('');

        var stateCheck = this.selectedState
        if(this.stripeSubscriptionId){
            var stateid ='';
            stateCheck  = this.selectedStateName;
            this.stateList.some(function(el){              
             if(el.shortName === stateCheck){
                stateid = el.id;
             }
            })
            if(stateid){        
                this.customStripeForm.get("existingAddress.state").setValue(stateid);          
            }
            else{
              this.customStripeForm.get("existingAddress.state").setValue('');
            }
        }else{
            if(stateCheck) {
              this.isPresent = this.stateList.some(function(el){ return el.id === stateCheck});
            if(this.isPresent){        
                this.customStripeForm.get("existingAddress.state").setValue(this.userData[`state`]['id']);          
            }
            else{
              this.customStripeForm.get("existingAddress.state").setValue('');
            }
            }
        }
      
      }, (error) => { }
    );
  }

  ngDoCheck() {
    this.validateForm.validateFormFields(this.customStripeForm, this);  
  }

  creditCardValidator(control: FormControl) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB 
    return new Promise(resolve => {
      if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
        resolve(null);
        } else {            
            resolve({'creditCardValidator': true});
        }
    });
}
 
  renewSubscriptionModal(){
    this.submitted = true;
    if(this.selectedAddressType === 'existing'){
      var stch = this.customStripeForm.get("existingAddress.state").value;
    }else{
      var stch = this.customStripeForm.get("newAddress.newState").value;
    }
    var stnm;
      var stateadd = this.stateList.some(function(el){ 
        if(el.id === stch){
            stnm = el.shortName;
            return stnm;
        }
    });

    if (this.customStripeForm.valid) {    
      var  data = {};     
      if(this.selectedAddressType === 'addNew'){
         data = {          
          "name" : this.customStripeForm.controls.nameOnCard.value,     
          "address_line1": this.customStripeForm.get("newAddress.newAddressLine1").value,
          "address_line2": this.customStripeForm.get("newAddress.newAddressLine2").value,
          "address_city": this.customStripeForm.get("newAddress.newCity").value,
          "address_state": stnm,
          "address_zip": this.customStripeForm.get("newAddress.newZipcode").value,  
          "address_country": this.selectedCountry      
        }
      }else{
        data = {          
          "name" : this.customStripeForm.controls.nameOnCard.value, 
          "address_line1": this.customStripeForm.get("existingAddress.addressLine1").value,
          "address_line2": this.customStripeForm.get("existingAddress.addressLine2").value,
          "address_city": this.customStripeForm.get("existingAddress.city").value,
          "address_state": stnm,
          "address_zip": this.customStripeForm.get("existingAddress.zipcode").value,  
          "address_country": this.selectedCountry
        }
      }       
      this.spinner.show();  
        this.stripe.createToken(this.cardNumber,data).then(response =>  {
          if (response.error) {
              this.spinner.hide();
              // Inform the user if there was an error            
              this.toastr.error(response.error.message);
          } else {
              // Send the token to your server
              var tokens = response.token;
             this.stripeTokenHandler(tokens.id);          
          }
      });
    }else{
      console.log(this.formErrors);
      this.validateForm.MarkControlsAsTouched(this.customStripeForm)
    }
  }

  ///////////Callback to handle the response from stripe
 stripeTokenHandler(token) {
    var  data = {} ;
    if(this.stripeSubscriptionId){
          data = {          
            "subscr_plan":this.selectedPlanId,
            "stripeToken": token,
            "cliniciansId": this.clinicianId,
            "stripeSubscriptionId": this.stripeSubscriptionId,
            "clinicianSubsciptionId": this.clinicianSubsciptionId
          }

          const subscribe =  this.cliniciansService.updateSubscription(data)
          .pipe(
            finalize(() => this.spinner.hide())
          )
          .subscribe(data => {
            if (data && data.success) {
              this.toastr.success(data.message);
              this.activeModal.close(true);
            }else{
              this.toastr.error(data.message);
            }
          }, (error) => 'err');
          subscribe.add(() => console.log('1')); 

    }else{
          data = {          
            "subscr_plan":this.selectedPlanId,
            "stripeToken": token,
            "cliniciansId": this.clinicianId
          }

          const subscribe =  this.cliniciansService.addSubscription(data)
            .pipe(
              finalize(() => this.spinner.hide())
            )
            .subscribe(data => {
              if (data && data.success) {
                this.toastr.success(data.message);
                this.activeModal.close(true);
              }else{
                this.toastr.error(data.message);
              }
            }, (error) => 'err');
            subscribe.add(() => console.log('1')); 
    } 
  }

///////////Callback to handle the response from stripe
subscriptionUpdte() {
  var  data = {} ;
  if(this.stripeSubscriptionIdChk){
        data = {          
          "subscr_plan":this.selectedPlanId,
          "stripeToken": 'token',
          "cliniciansId": this.clinicianId,
          "stripeSubscriptionId": this.stripeSubscriptionIdChk,
          "clinicianSubsciptionId": this.clinicianSubsciptionIdChk
        }
        const subscribe =  this.cliniciansService.updateSubscription(data)
        .pipe(
          finalize(() => this.spinner.hide())
          )
          .subscribe(data => {
            if (data && data.success) {
              this.toastr.success(data.message);
              this.activeModal.close(true);
          }else{
            this.toastr.error(data.message);
          }
        }, (error) => 'err');
        subscribe.add(() => console.log('1')); 
        
      }else{
        data = {          
          "subscr_plan":this.selectedPlanId,
          "stripeToken": 'token',
          "cliniciansId": this.clinicianId
        }
        const subscribe =  this.cliniciansService.addSubscription(data)
          .pipe(
            finalize(() => this.spinner.hide())
          )
          .subscribe(data => {
            if (data && data.success) {
              this.toastr.success(data.message);
              this.activeModal.close(true);
            }else{
              this.toastr.error(data.message);
            }
          }, (error) => 'err');
          subscribe.add(() => console.log('1')); 
  } 
}
  ngOnDestroy() {
    this.cardNumber.removeEventListener('change', this.cardHandler);
    this.cardNumber.destroy();
  }

 
  fetchSearchDropdowns() {
    try {
      const expandList = 'planList,countryList';
      this.cliniciansService.getDropdownsForItems(expandList).subscribe(data => {
        // assign to Type dropdown
      
        this.countryList = data['countryList']
        this.planList =  data['planList'];
        if(this.planList != undefined && this.planList != null){
          if(this.planList.length == 1){
            this.selectedPlanId = this.planList[0].id;
          }
        }      

      }, (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR));

    } catch (error) {
      (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    }
  }

  GetClinicianData() {
    try {    
      this.cliniciansService.GetCliniciansSubscriptionData(this.clinicianId).subscribe(data => {         
         console.log(data);
         if(data !=null){
          this.userData = data;              
          var country = this.userData[`country`];
          this.selectedCountryId = country['id']; 
          var stateSelect = this.userData[`state`];
          this.selectedState = stateSelect['id'];
          this.stripeSubscriptionIdChk =  this.userData[`stripRefId`];
          this.clinicianSubsciptionIdChk = this.userData[`clinicianSubsciptionId`];  
          if(this.userData['subscriptionStatus'] == 'active'){
            this.nextPaymentDue = this.userData[`nextPaymentDue`];  
            if(this.userData[`subscriptionId`] != undefined 
            && this.userData[`subscriptionId`] != null && 
            this.userData[`subscriptionId`] != ''){
              var planId = this.userData[`subscriptionId`];
              this.selectedPlanId = planId['id'];
              this.is_show_payment = true;
              const root = this;
              setTimeout(function(){
                root.loadStripe();
              },3000);
              
            }   
            /*var planId = this.userData[`subscriptionId`];
            this.selectedPlanId = planId['id']; */    
            this.stripeSubscriptionId =  this.userData[`stripRefId`];
            this.clinicianSubsciptionId = this.userData[`clinicianSubsciptionId`];
            this.stripeCardDtls = this.userData[`cardDetails`];
            this.selectedStateName = this.stripeCardDtls[`address_state`];  
            var cntry = this.stripeCardDtls[`address_country`];
            if(cntry == 'canada'){
              this.selectedCountryId = 'd02c7155-0d05-11ea-81b5-8aab01b8e040';
            }else{
              this.selectedCountryId = 'd02cae6d-0d05-11ea-81b5-8aab01b8e040';
            }            
          }
          this.setCountrywiseLabel();
          this.getStateList();
          this.setUserData();   
         }
      }, (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR));
       
    } catch (error) {
      (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    }
  }

  setUserData() {
    var country = this.userData[`country`];
    var cardDtls = this.userData[`cardDetails`];
    this.customStripeForm.get("nameOnCard").setValue(cardDtls[`nameOnCard`]);

    if(this.stripeSubscriptionId){
      var stateId = '';
      var countryId = '';
      var state = this.stripeCardDtls[`address_state`];
      var stateadd = this.stateList.some(function(el){ 
          if(el.shortName === state){
            stateId = el.id;
              return stateId;
          }
      });

      var cntry = this.stripeCardDtls[`address_country`];
      if(cntry == 'canada'){
        this.selectedCountryId = 'd02c7155-0d05-11ea-81b5-8aab01b8e040';
      }else{
        this.selectedCountryId = 'd02cae6d-0d05-11ea-81b5-8aab01b8e040';
      }    

      this.customStripeForm.get("existingAddress.addressLine1").setValue(this.stripeCardDtls[`address_line1`]);
      this.customStripeForm.get("existingAddress.addressLine2").setValue(this.stripeCardDtls[`address_line2`]);
      this.customStripeForm.get("existingAddress.city").setValue(this.stripeCardDtls[`address_city`]);
      this.customStripeForm.get("existingAddress.state").setValue(stateId);
      this.customStripeForm.get("existingAddress.zipcode").setValue(this.stripeCardDtls[`address_zip`]);
      this.customStripeForm.get("existingAddress.country").setValue(this.selectedCountryId);
    }else{
    this.customStripeForm.get("existingAddress.addressLine1").setValue(this.userData[`address`]);
    this.customStripeForm.get("existingAddress.addressLine2").setValue(this.userData[`addressLine2`]);
    this.customStripeForm.get("existingAddress.city").setValue(this.userData[`city`]);
    this.customStripeForm.get("existingAddress.state").setValue(this.userData[`state`]['id']);
    this.customStripeForm.get("existingAddress.zipcode").setValue(this.userData[`postalCode`]);
    this.customStripeForm.get("existingAddress.country").setValue(country['id']);
    }
  }

  closeModal(){
  this.activeModal.close(false);
  }

  public logout() {
    
    try {
          // logout of the system.
          this.authService.logout().subscribe(res => {
           });
          this.authService.clearToken();
          this.router.navigate(['/login']);
          this.closeModal();
    } catch (error) {
      // this.apiService.LogError('Exception in: header.component.ts - In method: Logout. ' + error);
    }
  }

  onBrowserBack(){
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.activeModal.close(false);
      }
    });
  }

  loadStripe(){
    this.scriptoader.loadScript('https://js.stripe.com/v3/').then(() => {
      this.stripe = Stripe(environment.stripePublishKey);
      var elements = this.stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      var elementStyles = {
        base: {
          iconColor: '#666EE8',
          color: '#495057',
          lineHeight: '40px',
          fontWeight: 400,
          fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#495057'
          }
        },
        invalid: {
          color: '#e4584c',
          iconColor: '#e4584c'
        }
      };

      var elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      };

      this.cardNumber = elements.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardNumber.mount('#card-number');

      this.cardExpiry = elements.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardExpiry.mount('#card-expiry');

      this.cardCvc = elements.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardCvc.mount('#card-cvc');

     // console.log(this.cardNumber)
      this.cardNumber.addEventListener('change',this.cardHandler);
      this.cardExpiry.addEventListener('change',this.cardHandler);
      this.cardCvc.addEventListener('change',this.cardHandler);

      // this.cardNumber.on('blur', function(event) {
      // });

    });

  }

  balanceHour  : any
  getCurrentUser(clinicianId){
    //this.spinner.show();
    const subscribe = this.authService.clinicianData(clinicianId).subscribe(data => {
          if (data.status !== '401') {
            if (data.balanceHour != undefined && data.balanceHour != null)
              this.balanceHour =  data.balanceHour
          }else{
            this.toastr.error( data.message);
          }
        }, (error) => this.toastr.error(error.message));
        //Hide Spinner
        //subscribe.add(()=>this.spinner.hide());
  }
}
