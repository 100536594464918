import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ChartsModule } from 'ng2-charts';
import { GameInstructionComponent } from '../shared/components/game-instruction/game-instruction.component';
import { ReactiveFormsModule } from '@angular/forms';

// import { ShowPasswordDirective } from './../app/directive/show-password.directive';

@NgModule({
  declarations: [
    PaginationComponent,
    GameInstructionComponent
    // ShowPasswordDirective
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot( {
      timeOut: 7000,
      positionClass: 'toast-top-center',  
      preventDuplicates: true,
      
    }), 
    ChartsModule,
    ReactiveFormsModule
  ],
  providers:[

  ],
  exports:[
    CommonModule,
    PaginationComponent,
    NgxSpinnerModule,
    NgbModule,
    ChartsModule
    // ShowPasswordDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {

 }
