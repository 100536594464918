import { Component, OnInit } from '@angular/core';
import{ GlobalConstants } from '../../constants/global-constants';
import { DashboardService } from '../../../app/services/dashboard.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router,NavigationStart} from '@angular/router';
@Component({
  selector: 'app-waiting-room-modal',
  templateUrl: './waiting-room-modal.component.html',
  styleUrls: ['./waiting-room-modal.component.scss']
})
export class WaitingRoomModalComponent implements OnInit {

  constructor(private dashboardService: DashboardService, 
     private activeModal: NgbActiveModal,
     private router: Router,) {
    this.onBrowserBack();
   }


  ngOnInit(): void {
    this.getWaitingRoomDetails();
  }

  //Get waiting Room Users
  waitingroom_data = [];
  waitingroom_count = 0;

  getWaitingRoomDetails(){
    
    const root = this;
    const subscribe = this.dashboardService.getWaitingRoomDetails().subscribe(data => {
      if (data.status == '101') {
        this.waitingroom_data = data.app_data;
        this.waitingroom_count = data.app_count;
      }      
    }, (error) => this.errorCallBack(error, root));
    
  }
   /***
   * Hide Loader
   * 
   */
  errorCallBack(error, root){
    root.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    root.spinner.hide();
  }

  cancel(){
    this.activeModal.close(false);
  }

  onBrowserBack(){
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.activeModal.close(false);
      }
    });
  }
}
