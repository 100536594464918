import { Component, OnInit, Inject, ViewChild, OnDestroy ,ElementRef} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from '../../services/appointment.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PusherService } from '../../services/pusher.service';
import { RoomLinkService,linkData } from '../../services/room-link.service';
import{ GlobalConstants } from '../../../shared/constants/global-constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateFormService } from '../../services/validate-form.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { GameInstrctionService } from '../../services/game-instrction.service';
import { environment } from 'src/environments/environment';
declare var joinRoomlocal: any;
declare var endCall: any;
declare var formComponent: any;

@Component({
  selector: 'app-patient-login',
  templateUrl: './patient-login.component.html',
  styleUrls: ['./patient-login.component.scss']
})
export class PatientLoginComponent implements OnInit,OnDestroy {
  gameList = [];
  selectedGamesItems = [];
  dropdownSettings3 = {};
  togglePreviewButton = true;
  role = "";
  roomId:any;
  app_id:any;
  userData: linkData = {
    appId: '',
    link: ''
  };
  waitingRoomList = [];
  regularList = [];
  gamesList :any;
  gameUrl: SafeResourceUrl;
  userAdded: Boolean = false;
  moderatorIn: Boolean =false;
  endcall = false;
  fromRoute;
  clientInFlag  = false;
  client_name = '';
  phone = '';
  @ViewChild('iframe') iframe: ElementRef;
  forgotPasswordForm: FormGroup;
  isLeaveGame :boolean = false;
  constructor(@Inject(DOCUMENT) private document: Document,
  private route: ActivatedRoute,
    private router: Router,
    private toastr:ToastrService,
    private pusherService: PusherService,
  private roomLinkService : RoomLinkService,
  private sanitizer: DomSanitizer,
  private fb: FormBuilder,
  protected validateForm: ValidateFormService,
  private appointmentService: AppointmentService,
  private confirmDialogService: ConfirmDialogService, private gameInstrctionService :GameInstrctionService) { }

  is_show_message : boolean = false;

  ngOnInit(): void {
    this.document.body.classList.add('full-view');
    formComponent = this;  
    // this.document.body.classList.add('patient-login-page');
    this.dropdownSettings3 = {
      singleSelection: false,
      text: "--Select--",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class",
      showCheckbox: true,
      noDataLabel: "Data not found",
      searchPlaceholderText: "Please Select Games",
      badgeShowLimit: 2
    };
    this.gameList = [
      { "id": 1, "itemName": "Pong" },
      { "id": 2, "itemName": "Hangman" },
      { "id": 3, "itemName": "Whiteboard" },
      { "id": 4, "itemName": "Battleship" },
      { "id": 5, "itemName": "Coloring Pages" },
      { "id": 6, "itemName": "Question Decks" },
      { "id": 7, "itemName": "Connect Dots (Blocks)" },
      { "id": 8, "itemName": "War" },
      { "id": 9, "itemName": "Uno" },
    ];
    this.role = this.route.snapshot.queryParams.role;
    this.roomId = this.route.snapshot.queryParams.roomId;
    this.app_id = this.route.snapshot.queryParams.app_id;
    this.fromRoute= this.route.snapshot.queryParams.fromRoute;
    this.client_name = this.route.snapshot.queryParams.client_name;
    this.phone = this.route.snapshot.queryParams.phone;
    this.isLeaveGame = false;
    //this.sanitizer.bypassSecurityTrustHtml(this.html);
    //this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cdn.htmlgames.com/1010Classic/');
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.fetchGamesDropdowns();
    this.connectRoom();
    ///this.loadiframe();
    this.pusherService.subScribeToChannel('my-channel-'+ this.app_id, ['newSub'], (data) => {
      // this.countDown -= 1;  
      if(data){  
       //console.log(data,'inside data');
        if(this.role == 'participant'){
          (<HTMLInputElement>document.getElementById('changelink')).src = data.link; 
          console.log("data.link >>" + data.link)
        }
        
        if(this.role == 'moderator' && !this.isLeaveGame && this.is_show_message){
          this.toastr.success(GlobalConstants.GAME_LINK);
          this.is_show_message = false;
        }
        
      }
    });

    this.forgotPasswordForm = this.fb.group(
      {
        nameOfKid: ['', Validators.required]
      }
    );
  }
  
  formErrors = { 
    'nameOfKid': ''
  };
  
  validationMessages = {
    'nameOfKid': { 'required': GlobalConstants.GAME_LINK_ERROR,},
  };

  buildForm(): void {
    this.forgotPasswordForm = this.fb.group({
      nameOfKid: ['', [Validators.required]]
    });
  } 

  ngOnDestroy() {
    if(this.togglePreviewButton)
    this.exitFullview();
    if(this.endcall == false)
    this.endCallData();
  }

  showFullview() {
    this.document.body.classList.add('full-view');
    this.document.body.classList.remove('game-view');
    this.togglePreviewButton = true;
  }
  exitFullview() {
    this.document.body.classList.remove('full-view');
    this.document.body.classList.add('game-view');
    this.togglePreviewButton = false;
  }

  connectRoom(){
    // var tokenData = this.route.snapshot.queryParams;
    // this.role = this.route.snapshot.queryParams.role;
    // this.roomId = this.route.snapshot.queryParams.roomId;
    // console.log(this.role)
    // this.appointmentService.getAuthToken({ tokenData }).subscribe((data) => {
    //   console.log(data.message, 'tokendata');
    //   if(data.success){

      var data = this.route.snapshot.queryParams.token;
      if(data)
        joinRoomlocal(data , this.regularList);
        else
        this.toastr.error("Invalid request");  

    //   }else{
    //     this.toastr.error(data.message);
    //   }
    // },(error) => this.toastr.error(error.message));
  }

  endCallData(){
    this.endcall = true;
    var users = endCall();
    var flagVal = '0';
    var completeFlag = 0;
    //console.log(this.clientInFlag);
        if ((Array.isArray(users) && users.length && this.role == 'moderator') || (this.clientInFlag && this.role == 'moderator')) {
        
          var flagVal = (<HTMLInputElement>document.getElementById('hiddenCallRecordFlag')).value;           
          completeFlag = 1;        
          var  data = {          
            "room_id" : this.roomId,
            "app_id": this.app_id,
            "flag_record" : flagVal
          }
          this.appointmentService.updateAppointmentStatus(data).subscribe((data) => {
            if(data.success){             
                if(this.fromRoute === 'appointments')
                  this.router.navigate(['/clinicians/dashboard']);  
                else
                  this.router.navigate(['/clinicians/calendar']); 
            }else{
              this.toastr.error(data.message);
            }
          },(error) => this.toastr.error(error.message));       
        }
        else if(this.role == 'moderator'){
          if(this.fromRoute === 'appointments')
            this.router.navigate(['/clinicians/dashboard']);  
          else
            this.router.navigate(['/clinicians/calendar']);       
        }else{
          this.waitingRoomUpdate(0);
          this.router.navigate(['/login']);         
        }     
        //window.location.reload();
  }

  fetchGamesDropdowns() {
    try {
      const expandList =  'waitingroomGameList,regularGameList';
      this.appointmentService.getDropdownsForItems(expandList).subscribe(data => {
        // assign to Type dropdown
        this.waitingRoomList =  data['waitingroomGameList'];
        this.regularList =  data['regularGameList'];
        /*if(this.role == 'moderator'){
          this.gamesList = this.regularList;
        }else{*/
          this.gamesList = this.waitingRoomList;
         // console.log("fectgh game drop Down", this.gamesList);
       //}
        
      }, (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR));

    } catch (error) {
      (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    }
  }

  is_show_leave_btn : boolean = false;
  is_guess_who = false;
  changeGame(gameId){
    var selectedGame;

   //Remove white board Game to client side
    // if(this.is_white_board){
    //   this.userData = {appId:'', link:''} 
    //   this.pusherService.triggerEvent('my-channel-'+ this.app_id, 'newSub', this.userData)
    //         .subscribe( data => {
    //    })
    //   this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    //   (<HTMLInputElement>document.getElementById('pong_msg')).classList.add("hide");
    // }
      this.is_guess_who = false;
      this.is_white_board = false;

      if(this.role == 'moderator' && this.isSendLink && <HTMLInputElement>document.getElementById('nameOfKid')){
        (<HTMLInputElement>document.getElementById('nameOfKid')).value = '';
      }
       
      this.is_show_leave_btn = true;

      if((this.role == 'moderator') || (!this.moderatorIn && this.role == 'participant')){
        for (let key of this.gamesList) {
        if(key.id == gameId){     
          selectedGame = key;
          // this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(key.gameUrl); 
          this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(key.gameUrl+'?playerName='+window.localStorage.getItem('firstName')+' '+window.localStorage.getItem('lastName')+'&tablePlayerNames1='+this.client_name+'&tablePlayerNames0='+window.localStorage.getItem('firstName')+' '+window.localStorage.getItem('lastName'));
          if(key.gameName == 'Engage Therapy Guess Who'){
            this.is_guess_who = true;
          }
          //Only for white Borad Game send Custom link.
          if(key.gameName == 'Whiteboard'){
              this.is_white_board = true;
              if(selectedGame.gameType == 'Regular')
               this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.WBO_URL + 'boards/'+this.app_id);  
              else
               this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.WBO_URL + 'boards/'+this.app_id+'wait');
          }   
         if(!this.moderatorIn && this.role == 'participant' && key.gameName == 'Pong'){
           (<HTMLInputElement>document.getElementById('pong_msg')).classList.remove("hide");
          //this.toastr.error('message');
         }else{
           (<HTMLInputElement>document.getElementById('pong_msg')).classList.add("hide");
         }
         this.gameLinkUpdate(selectedGame);
        }           
      }
    }
  }

  is_white_board = false;
  gameLinkUpdate(selectedGame){
    var data =   {
                        cliniciansId: this.app_id,
                        gameId: selectedGame.id,
                        appointmentId: this.app_id,
                        gameType: selectedGame.gameType,
                      };
    this.appointmentService.addGameUrl(data).subscribe((data) => {
      if(data.success){
       if(this.is_white_board  && this.moderatorIn == true && this.isSendLink == true && selectedGame.gameType == 'Regular'){
           this.sendCustomeLink();
         }

         if(this.is_guess_who  && this.moderatorIn == true && this.isSendLink == true && selectedGame.gameType == 'Regular'){
          this.sendCustomeLink();
        }
      }else{
        this.toastr.error(data.message);
      }
    },(error) => this.toastr.error(error.message));
  }

  sendLink(): void {

    var linking = (<HTMLInputElement>document.getElementById('nameOfKid')).value;  
    if(linking){
    this.userData = {
      appId:  this.app_id,
      link: linking
    };
    try {
    this.roomLinkService.addNewUser(this.userData)
      .subscribe( 
        (data => { 
        this.is_show_message = true;      
        this.userAdded = true 
        this.userData = {appId:'', link:''} 
        this.forgotPasswordForm.controls.nameOfKid.setValue('');
        this.forgotPasswordForm.updateValueAndValidity();     
      }), 
      (error) => {   
        this.toastr.error(GlobalConstants.SERVER_ERROR);
      }
      )
    } catch (error) {
      console.log(error)
    }
    //if(this.userAdded){
        var data: any;
        this.pusherService.triggerEvent('my-channel-'+ this.app_id, 'newSub', this.userData)
            .subscribe( data => {
            // console.log(data['link'])
            //  console.log(data);
            // (<HTMLInputElement>document.getElementById('changelink')).src = data['link'];                  
            
        })
    //}
    }else{
        this.validateForm.MarkControlsAsTouched(this.forgotPasswordForm);
    }
  }

  ngDoCheck() {
    this.validateForm.validateFormFields(this.forgotPasswordForm, this);
  }

  callExpiring() {
    this.toastr.error(GlobalConstants.CALL_LINK_EXPIRING);
  }

  waitingRoomUpdate(flag) {   
    var Data = {
      appId:  this.app_id,
      flag: flag
    };  
      this.appointmentService.updateWaitingroom(Data).subscribe((data) => {
      if(data.success){

      }else{
      this.toastr.error(data.message);
      }
      },(error) => this.toastr.error(error.message));
  }

  userRoomUpdate(){
    this.clientInFlag = true;
  }

  isSendLink: boolean = false;
  doAngularThing() {
    if(!this.moderatorIn){
      this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      (<HTMLInputElement>document.getElementById('pong_msg')).classList.add("hide");
    }
    this.gamesList = this.regularList;
    this.moderatorIn = true;
    this.isSendLink = true;
  }

  errorHandling(msg){
    this.toastr.error(msg);
     if(this.role == 'moderator'){
      if(this.fromRoute === 'appointments')
        this.router.navigate(['/clinicians/dashboard']);  
      else
        this.router.navigate(['/clinicians/calendar']);       
    }else{
      this.router.navigate(['/login']);         
    }     
  }

  userDisconnnects(msg) {
    this.toastr.success(msg);
  }


  UpdateRegularGame() {
    this.gamesList = this.regularList;
   // console.log(this.gamesList, 'inside update Regular Game')
    if(!this.moderatorIn){
      this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
      (<HTMLInputElement>document.getElementById('pong_msg')).classList.add("hide");
    }
    this.moderatorIn = true;
    this.isSendLink = true;
  }


  cancelGame(): void {
    console.log('inside cancel')
    this.is_show_leave_btn = false;
    //console.log("inside leave event");
    this.isLeaveGame = true;
    this.userData = {appId:'', link:''} 
    this.pusherService.triggerEvent('my-channel-'+ this.app_id, 'newSub', this.userData)
            .subscribe( data => {
           // console.log(data);
        })
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    (<HTMLInputElement>document.getElementById('pong_msg')).classList.add("hide");
  }

  leaveGame() {
    this.confirmDialogService.confirm('Activate', 'text-danger', 'Are you sure you want to leave this game?', 'Yes', 'No', 'bg-danger', 'sm', 'icon-x', true)
      .then((confirmed) => this.cancelGame())
      .catch((error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR));
  }


  
  openInstrutionPopup() {
    this.gameInstrctionService.openGameInstructionModal('lg', '', false).then((data) => console.log(data))
      .catch((error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR));
  }


  sendCustomeLink(): void {
      this.is_show_message = false;
      var cutm_link =''
      if(this.is_white_board){
        cutm_link = environment.WBO_URL + 'boards/'+this.app_id;
      }
      if(this.is_guess_who){
        cutm_link = environment.GUESS_WHO_URL;
      }
      this.userData = {
        appId:  this.app_id,
        link: cutm_link,
      };
        try {
        this.roomLinkService.addNewUser(this.userData)
          .subscribe( 
            (data => {       
            this.userAdded = true     
          }), 
          (error) => {   
            this.toastr.error(GlobalConstants.SERVER_ERROR);
          }
          )
        } catch (error) {
          console.log(error)
        }

        var data: any;
        this.pusherService.triggerEvent('my-channel-'+ this.app_id, 'newSub', this.userData)
            .subscribe( data => { })
  
  }
}
