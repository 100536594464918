// shared-popup.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SharedPopupService {
  // Subject to trigger the popup/modal
  private popupSubject = new Subject<boolean>();

  // Observable to subscribe to in your components
  popupObservable$ = this.popupSubject.asObservable();

  // Method to show/hide the popup/modal
  togglePopup(show: boolean) {
    this.popupSubject.next(show);
  }
}