import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ɵConsole,
} from '@angular/core';
import { AuthService } from '../../app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { GlobalConstants } from '../constants/global-constants';
import { WaitingRoomModalService } from 'src/app/services/waiting-room-modal.service';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from 'src/app/services/appointment.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubscriptionExpireModalService } from 'src/app/services/subscription-expire-modal.service';
import { SharedPopupService } from '../video-overlay/shared-video-overlay-service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  isLoggedin: EventEmitter<number> = new EventEmitter();
  interval: any;
  endcall = false;
  unreadCommentCount: any = 0;
  entityName: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private waitingRoomModalService: WaitingRoomModalService,
    private toastr: ToastrService,
    private appointmentService: AppointmentService,
    private ref: ChangeDetectorRef,
    private confirmDialogService: ConfirmDialogService,
    private spinner: NgxSpinnerService,
    private subscriptionExpireModalService: SubscriptionExpireModalService,
    private sharedPopupService: SharedPopupService
  ) {
    this.checkIfAdminLoggedIn();
  }

  user_type: any;
  first_name: any;
  lastName: any;
  profileImagePath: string = '';
  waitingroom_data = [];
  waitingroom_count = 0;
  is_loader_start: boolean = false;
  isAdminLoggedIn: boolean = false;

  ngOnChanges() {
    //console.log('inside on changes');
  }

  cur_url: any;
  ngOnInit(): void {
    this.first_name = localStorage.getItem('firstName');
    this.lastName = localStorage.getItem('lastName');
    this.isLoggedin.emit(1);

    //get session first;
    this.getUnreadCommentCount();

    this.appointmentService.sideBarEmitter.subscribe(() => {
      // this.ngOnInit();
      (<HTMLInputElement>document.getElementById('commentCnt')).classList.add(
        'd-none'
      );
    });

    //get waiting room session only for specific urls.
    if (window.localStorage.getItem('user_type') != 'admin') {
      if (this.interval == undefined) {
        this.interval = setInterval(() => {
          let splt_str = this.router.url.split('?');
          if (
            splt_str != undefined &&
            splt_str != null &&
            splt_str.length > 1
          ) {
            this.cur_url = splt_str['0'];
          }

          if (
            GlobalConstants.sidebarArray.indexOf(this.router.url) > 0 ||
            GlobalConstants.sidebarArray.indexOf(this.cur_url) > 0
          ) {
            this.getSessions();
          }
        }, 5000);
      }
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  checkIfAdminLoggedIn() {
    if (window.localStorage.getItem('user_type') === 'admin') {
      this.isAdminLoggedIn = true;
    }
  }

  getAccessControl() {
    let authService = this.authService.getAccessControl();
    return authService;
  }

  /***
   * Get user's last Name.
   *
   */
  getLastName() {
    var lastName = this.authService.getlastName();
    return lastName;
  }

  /***
   * Get user's first Name.
   *
   */
  getFirstName() {
    var firstName = this.authService.getfirstName();
    return firstName;
  }

  /***
   * Get user's first Name.
   *
   */
  getProfileImage() {
    var profileImagePath = this.authService.getProfileImage();
    return profileImagePath;
  }

  public logout() {
    try {
      // logout of the system.
      this.authService.logout().subscribe((res) => { });
      this.authService.clearToken();
      this.router.navigate(['/login']);
    } catch (error) {
      // this.apiService.LogError('Exception in: header.component.ts - In method: Logout. ' + error);
    }
  }

  //Get waiting Room Users
  openWaitingListModule() {
    this.waitingRoomModalService
      .openWaitingRoomAppointmentModal('lg')
      .then((data) => this.ngOnInit())
      .catch((error) =>
        this.toastr.error(error.message || GlobalConstants.SERVER_ERROR)
      );
  }

  /***
   * Hide Loader
   *
   */
  errorCallBack(error, root) {
    root.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    root.spinner.hide();
    this.is_loader_start = false;
  }

  getWaitingRoomDetails() {
    const root = this;
    const subscribe = this.dashboardService.getWaitingRoomDetails().subscribe(
      (data) => {
        if (data.status == '101') {
          this.waitingroom_data = data.app_data;
          this.waitingroom_count = data.app_count;
        }
      },
      (error) => this.errorCallBack(error, root)
    );
  }
  getSessionsRefresh() {
    this.spinner.show();
    this.getSessions();
  }
  count: any;
  url: any;
  getSessions() {
    const root = this;
    this.appointmentService.getSessions().subscribe(
      (data) => {
        if (data.status == '101') {
          this.spinner.hide();
          this.waitingroom_data = data.session_data.session;
          this.count = data.session_data.count;
          this.url = data.session_data.waiting_room_url;
          this.ref.detectChanges();
        } else {
          this.spinner.hide();
          this.toastr.error(data.message);
        }
      },
      (error) => this.errorCallBack(error, root)
    );
  }

  waiting_room_id: any;
  app_id: any;
  pre_session_data: any;
  cur_session_data: any;
  is_expired: any;
  startSession(data) {
    this.cur_session_data = data;
    if (window.localStorage.getItem('is_session_started') == '1') {
      this.alertMessage(GlobalConstants.CALL_MSG);
      return true;
    }

    if (data.is_already_booked == 1) {
      //if already Booked appointment then Directly join room.
      this.app_id = data.app_id;
      this.joinTheRoom(
        data.room_id,
        this.waiting_room_id,
        data.client_name,
        data.phone
      );
    } else {
      this.spinner.show();
      this.is_loader_start = true;
      const timezone = localStorage.getItem('timezone');
      //Book appointment join meeting.
      let app_data = {
        patientsId: data.client_id,
        waitingRoomId: data.waiting_id,
        app_id: data.app_id,
        is_edit_change: 'do not update',
        timezone: timezone,
      };
      this.waiting_room_id = data.waiting_id;
      const root = this;

      const subscribe = this.appointmentService
        .startSession(app_data)
        .subscribe(
          (data) => {
            if (data.status !== '101') {
              this.toastr.success(data.message);
              this.app_id = data.app.appId;
              this.pre_session_data = {
                app_id: data.app.appId,
                waiting_id: data.waiting_id,
              };
              this.joinTheRoom(
                data.app.roomId,
                this.waiting_room_id,
                data.app.firstName,
                data.app.phone
              );
            } else {
              this.spinner.hide();
              this.is_loader_start = false;
            }
          },
          (error) => this.errorCallBack(error, root)
        );
    }
  }

  roomData: any;
  joinTheRoom(roomId, waiting_id, client_name, phone) {
    if (!this.is_loader_start) {
      this.spinner.show();
    }
    const root = this;
    let data = {
      id: roomId,
    };
    this.appointmentService.getSessionRoomData(data).subscribe(
      (data) => {
        if (data.success) {
          this.roomData = data.message.room;
          this.connectRoom(waiting_id, client_name, phone);
        } else {
          this.spinner.hide();
          this.is_loader_start = false;
          this.toastr.error(data.message);
        }
      },
      (error) => this.errorCallBack(error, root)
    );
  }

  connectRoom(waiting_id, client_name, phone) {
    if (!this.is_loader_start) {
      this.spinner.show();
    }
    var tokenData = {
      roomId: this.roomData.room_id,
      name: this.roomData.name,
      role: 'moderator',
      user_ref: this.roomData.owner_ref,
      waiting_id: waiting_id,
      app_id: this.app_id,
    };
    const root = this;
    this.appointmentService.getAuthToken({ tokenData }).subscribe(
      (data) => {
        if (data.success) {
          this.router.navigate(['/session/appointment-session'], {
            queryParams: {
              token: data.message.token,
              role: 'moderator',
              roomId: this.roomData.room_id,
              waiting_id: waiting_id,
              app_id: this.app_id,
              client_name: client_name,
              phone: phone,
              url: this.url,
            },
          });
          window.localStorage.setItem('is_session_started', '1');
        } else {
          this.toastr.error(data.message);
        }
        this.spinner.hide();
        this.is_loader_start = false;
      },
      (error) => this.errorCallBack(error, root)
    );
  }

  deleteSession(wait_data) {
    this.appointmentService.updateAppointmentSession(wait_data).subscribe(
      (data) => {
        if (data.success) {
          this.toastr.success(data.message);
          this.getSessionsRefresh();
        } else {
          this.toastr.error(data.message);
        }
      },
      (error) => this.toastr.error(error.message)
    );
  }

  isdeleteSession(data) {
    let wait_data = {
      waiting_id: data.waiting_id,
    };
    this.confirmDialogService
      .confirm(
        'Activate',
        'text-danger',
        'Are you sure you want to delete this Waiting Room?',
        'Yes',
        'No',
        'bg-danger',
        'sm',
        'icon-x',
        true
      )
      .then((confirmed) => this.deleteSession(wait_data))
      .catch((error) =>
        this.toastr.error(error.message || GlobalConstants.SERVER_ERROR)
      );
  }

  redirectToYoutube() {
    this.sharedPopupService.togglePopup(true);
  }

  redirectToCalendly() {
    window.open("https://calendly.com/engageplaytherapy/demo", '_blank');
  }

  /* * * * *
   * Date: 15-Sep-2020
   * Added by: Bhagyashri Nikam<bhagyashrinikam@benchmarkit.solutions>
   * Alert Component
   */
  alertMessage(msg) {
    this.confirmDialogService
      .confirm(
        ' ',
        'text-success',
        msg,
        'Ok',
        'Cancel',
        'bg-success',
        'sm',
        'icon-user-check',
        true
      )
      .then((confirmed) => console.log('confirmed'));
  }

  //Switch call.
  endCallData() {
    window.localStorage.removeItem('is_session_started');
    let data = {
      room_id: this.waiting_room_id,
      app_id: this.app_id,
      flag_record: 0,
      is_session: 1,
    };
    this.appointmentService.updateAppointmentStatus(data).subscribe(
      (data) => {
        if (data.success) {
          this.router.navigate(['/clinicians/dashboard']);
          //Start Session
          this.startSession(this.cur_session_data);
        } else {
          this.toastr.error(data.message);
        }
      },
      (error) => this.toastr.error(error.message)
    );
  }

  current_user_id: any;
  isValidSession(session_data) {
    const root = this;
    const subscribe = this.authService.me().subscribe(
      (data) => {
        this.current_user_id = data.id;
        if (data.status !== '401') {
          if (
            data.status == 'E' || data.is_subscription_expire == 'invalid'
          )  //data.paymentStatus != 'paid' ||  
          {
            this.renewSubscriptionModal(this.current_user_id,'isValidSession');
            return true;
          }
          this.startSession(session_data);
        }
      },
      (error) => this.toastr.error(error.message)
    );
  }

  renewSubscriptionModal(id,functionName) {
    var fromParent = id;
    const flag = (functionName == 'subscriptionPopup') ? true : false;
    const modalRef = this.subscriptionExpireModalService
      .openModal(true, fromParent, flag)
      .then((data) => console.log(data, 'data'))
      .catch((error) =>
        this.toastr.error(error.message || GlobalConstants.SERVER_ERROR)
      );
  }

  getUnreadCommentCount() {
    const root = this;
    this.appointmentService.getUnreadCommentCount().subscribe(
      (data) => {
        if (data.status == '101')
          this.unreadCommentCount = Number(data.commentCount);
      },
      (error) => this.errorCallBack(error, root)
    );
  }

  // OnClick(){
  //   // window.location.href = "https://headway.co/";
  //   window.open('https://headway.co/', "_blank");

  // }

  subscriptionPopup(){

    this.spinner.show();
    const subscribe = this.authService.me().subscribe(data => {
      this.current_user_id = data.id;
          if (data.status !== '401' && this.current_user_id != '') {     
            this.renewSubscriptionModal(this.current_user_id,'subscriptionPopup');
          } else {
            this.toastr.error( data.message);
          }
        }, (error) => this.toastr.error(error.message));
        //Hide Spinner
        subscribe.add(()=>this.spinner.hide());

  }
}
