import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import {CanActivate, Router,ActivatedRouteSnapshot,RouterStateSnapshot,CanLoad, Route,UrlTree, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
      const token = localStorage.getItem('accessToken');
      console.log(token);
      if (token === undefined || token !== null) {
        let path: string = route.routeConfig.path;
        let is_valid = this.getAccessControl(path);
        return is_valid;
      } else {
       console.log("inside else"+token);
        this.router.navigate(['/login']);
        return false;
      }
  
    }  
    
    is_valid = false;
    getAccessControl(path){
      if(localStorage.getItem('user_type') == 'admin'){
        if(path == 'dashboard' ||
          path == 'admin-profile' || 
          path == 'clinicians-management' ||  
          path == 'clinicians-add' || 
          path == 'survey-dashboard'||
          path == 'message-mgmt'){
            return true;
         }
       
      }else if(localStorage.getItem('user_type') == 'clinician'){
          if(path == 'clinician-profile' ||
            path == 'clinicians-dashboard' || 
            path == 'appointments' ||  
            path == 'patients-management' || 
            path == 'survey-dashboard'||
            path == 'calendar' ||
            path == 'subscription' ||
            path == 'dashboard' ||
            path == 'game' ||
            path == 'memo-pad' ||
            path == 'tutorial-videos-link' ||
            path == 'message'){
            return true;
        }
      }
      this.router.navigate(['/page-not-found'], { queryParams: { message:'Sorry, Page not found.',
      title:'Oops!!!'}});
   }
}
