import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,private router: Router,
    private activatedRoute: ActivatedRoute , 
  ) { }
  message: string = "Oops, The Page you are looking for can't be found!"
  title: string = "Oops!!";
  ngOnInit(): void {
    this.document.body.classList.add('page-not-body');
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params,'params');
      this.message = params['message'];
      this.title =  params['title'];
    });
  }
  ngOnDestroy() {
    this.document.body.classList.remove('page-not-body');
  }

}
