import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService, HttpReqMethod } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private httpClient: HttpClient, 
              private apiService: ApiService) { }


  //get login users details
  addClient(data){
    return this.apiService.Request(environment.userServiceUrl + 'patient/add-patient', HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );
  }


  queryString: string;
  pageNumber: string;
  pageSize: string;
  like: any;
  searchParams: any = '';
  showType: string;
  sortString: string;
  emailBody: [];
  noStatus;
  url: string;
  patient_query = '&' + 'patient_data[]';
  public searchPatients(searchParams, sortParams, pageNumber, pageSize) {
    this.pageNumber = 'page=' + pageNumber;
    this.pageSize = '&per-page=' + pageSize;
    this.queryString = '';
    this.url = 'patient' + '?' + this.pageNumber + this.pageSize;
    this.patient_query = '&' + 'patient_data[]';
    if (typeof (sortParams) !== 'undefined') {
      this.queryString = '&sort=' + sortParams.sort;
    }
    //console.log(searchParams,'searchParams');
    if (typeof (searchParams) !== 'undefined') {
      for (const key in searchParams) {
         if (searchParams[key]) {
          if (key === 'fullName' || key === 'email' || key === 'phone' || key === 'parentGuardianName' 
            || key === 'parentGuardianEmail' || key === 'emergencyContactName' || key === 'emergencyContactNo') {
               // this.queryString += '&' + 'filter[' + key + '][like]=' + searchParams[key];
               this.patient_query += '&' + 'patient_data['+ key+ ']=' + searchParams[key];
          }else if (key === 'status') {            
            for (const val in searchParams[key]) {
              if (val) {
                this.queryString += '&' + 'filter[' + key + '][]=' + searchParams[key][val]['id'];
              }
            }         
          } 
        }
        
      }
      }else{
        
      }
      this.queryString = this.queryString + this.patient_query;
    return this.apiService.Request(environment.userServiceUrl + this.url + this.queryString, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }
  //handle error
  handleError(error: HttpErrorResponse) {
    return throwError(error.error || 'Server error');
   }

   //change Patient status
  changePatientStatus(data){
     this.url = 'patient/change-patient-status';
      return this.apiService.Request(environment.userServiceUrl + this.url,
        HttpReqMethod.POST.toString(), data).pipe(
          map(res => {
            return res;
          }),
         catchError(this.handleError)
        )
   }

   getPatientData(data){ 
    this.url = 'patient/get-patient-details';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
   }

   getStateCountryList(){
    this.url = 'patient/state-country-list';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.GET.toString()).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
   }
}
