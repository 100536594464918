import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SupportService } from 'src/app/services/support.service';
import { ValidateFormService } from 'src/app/services/validate-form.service';
import { GlobalConstants } from 'src/shared/constants/global-constants';
@Component({
  selector: 'app-game-instruction',
  templateUrl: './game-instruction.component.html',
  styleUrls: ['./game-instruction.component.scss'],
})
export class GameInstructionComponent implements OnInit {
  formErrors = {
    username: '',
    email: '',
    subject: '',
    message: '',
  };

  validationMessages = {
    username: { required: 'Username cannot be blank.' },
    email: {
      required: 'Email cannot be blank.',
      pattern: GlobalConstants.EMAIL_PATTERN,
    },
    subject: { required: 'Subject cannot be blank.' },
    message: { required: 'Message cannot be blank.' },
  };
  createdBy: any;
  email: any;
  userName: any;
  submitted: boolean;
  data: {
    userName: any;
    email: any;
    subject: any;
    message: any;
    createdBy: any;
  };
  supportClinicianForm : FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    protected validateForm: ValidateFormService,
    public supportService: SupportService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.fetchClinicianData();
  }

  buildForm(): void {
    this.supportClinicianForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{1,}')]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  cancel() {
    this.activeModal.close(false);
  }

  ngDoCheck() {
    this.validateForm.validateFormFields(this.supportClinicianForm, this);
  }

  fetchClinicianData(){
    this.supportService.me().subscribe(res => {
      if (res.id !== '') {
        this.createdBy = res.id;
        this.email = res.email;
        this.userName = res.userName;
      }else{
        // this.toastr.error(res.message);
      }
     });
  }

  sendMail() {
    this.submitted = true;
   
    if (this.supportClinicianForm.valid) {
      this.data = {
        "userName": this.supportClinicianForm.controls.username.value,
        "email": this.supportClinicianForm.controls.email.value,
        "subject": this.supportClinicianForm.controls.subject.value,
        "message": this.supportClinicianForm.controls.message.value,
        "createdBy": this.createdBy
      }

      this.supportService.sendMails(this.data).subscribe(res => {

        if (res.success) {
          this.toastr.success(res.message);
          // this.router.navigate(['clinicians/appointments']);
        }else{
          this.toastr.error(res.message);
        }
  
      });

    } else {
      //on submit make field touched.
      this.validateForm.MarkControlsAsTouched(this.supportClinicianForm)
    }
  }

  trimVal(obj){
    switch(obj){
      case 'u':
        this.supportClinicianForm.controls['username'].setValue(this.supportClinicianForm.controls.username.value.trim());
        break;
      case 'e':
        this.supportClinicianForm.controls['email'].setValue(this.supportClinicianForm.controls.email.value.trim());
        break;
      case 's':
        this.supportClinicianForm.controls['subject'].setValue(this.supportClinicianForm.controls.subject.value.trim());
        break;
      case 'm':
        this.supportClinicianForm.controls['message'].setValue(this.supportClinicianForm.controls.message.value.trim());
        break;
    }
  }

}
