import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionExpireModalComponent} from '../../shared/components/subscription-expire-modal/subscription-expire-modal.component';


@Injectable({
  providedIn: 'root'
})

 export class SubscriptionExpireModalService {
    constructor(private modalService: NgbModal) { }
    public openModal(center: true | false, fromParent: string, cancel: true | false): Promise<boolean> {
      const modalRef = this.modalService.open(SubscriptionExpireModalComponent, { size: 'lg' });
      modalRef.componentInstance.fromParent = fromParent;
      modalRef.componentInstance.cancel = cancel;
      return modalRef.result;
    }
}





