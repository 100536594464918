import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ValidateFormService } from '../../../app/services/validate-form.service';
import{ GlobalConstants } from 'src/shared/constants/global-constants';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { cliniciansService } from '../../../app/services/clinicians.service';
import { Router,NavigationStart} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-admin-subscription-modal',
  templateUrl: './admin-subscription-modal.component.html',
  styleUrls: ['./admin-subscription-modal.component.scss'],
  providers: [ValidateFormService,cliniciansService]
})

export class AdminSubscriptionModalComponent implements OnInit {

  @Input() clinicianId;
  submitted: boolean = false; 
  addMinForm: FormGroup;
  selectedPlanId: any;
  balanceHour: any
  planList: any;
  userData: any;
  startDate: any;
  nextPaymentDue: any;
  email: any;
  planName: any;
  selected_date: any;
  today: any;
  pipe = new DatePipe('en-US');
  
  formErrors = { 
    'mins': '',
    'date': '',
  }

  validationMessages = {   
    'mins': {
      'required': GlobalConstants.MINS_REQUIRED,
      'pattern': GlobalConstants.VALID_NAMEONCARD,
      'zeroMins': GlobalConstants.ZEROMINS,
      // 'maxMin': GlobalConstants.MAXMIN,
      }, 
    'date': {
      'required': GlobalConstants.DATE_REQUIRED,
      'minDate' : GlobalConstants.MINDATE,
    }
  }

  constructor(
    private formBuilder: FormBuilder,  
    protected validateForm: ValidateFormService,
    public activeModal: NgbActiveModal,
    public cliniciansService: cliniciansService,
    private toastr: ToastrService,
    private router: Router,
    ) {
      this.onBrowserBack();
     }


  ngOnInit(): void {
  
    this.fetchSearchDropdowns();
    this.GetClinicianData();
    this.buildForm();
  }

  ngDoCheck() {
    this.validateForm.validateFormFields(this.addMinForm, this);  
  }

  buildForm(): void {
    this.today = this.pipe.transform(new Date(), 'yyyy-MM-dd');
    
    this.addMinForm = this.formBuilder.group({
      mins: ['',[Validators.required, Validators.pattern('^[0-9\-]+$')] ],//,this.minValidation.bind(this) 
      date: ['',[Validators.required],this.dateValidation.bind(this)],
    });
  }

  minValidation(control : FormControl){

    return new Promise(resolve => {
      console.log(this.balanceHour + (control.value * 2));
      if(control.value < 1)
        resolve({ zeroMins: { valid: true }})

        if(Number(this.balanceHour+( Number(control.value) * 2)) > 500001){
                resolve({ maxMin: { valid: true }})
        }
          resolve(null)
        
      })

  }

  dateValidation(control : FormControl){
    
    return new Promise(resolve => {
      
      const selectedDate = new Date(control.value)
      const today = new Date(this.pipe.transform(this.today, 'yyyy-MM-dd'))

      if(selectedDate.getTime() < today.getTime())
        resolve({ minDate: { valid: true }})

      resolve(null)  
      })

  }
 
  fetchSearchDropdowns() {
    try {
      const expandList = 'planList';
      this.cliniciansService.getDropdownsForItems(expandList).subscribe(data => {
        this.planList =  data['planList'];
        if(this.planList != undefined && this.planList != null){
          if(this.planList.length == 1){
            this.selectedPlanId = this.planList[0].id;
          }
        }      

      }, (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR));

    } catch (error) {
      (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    }
  }

  closeModal(){
  this.activeModal.close(false);
  }

  onBrowserBack(){
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.activeModal.close(false);
      }
    });
  }

  GetClinicianData() {
    try {    
      this.cliniciansService.getSubscriptionDetails(this.clinicianId).subscribe(data => {         
         
         if(data !=null){

          this.balanceHour = data[`balanceMin`];
          this.selectedPlanId = data[`subPlanId`];
          this.email = data[`email`];
          this.startDate = new Date(data[`subStartDate`]);  
          this.nextPaymentDue = new Date(data[`subEndDate`]);  
          this.selected_date = this.pipe.transform(this.nextPaymentDue, 'yyyy-MM-dd');
          this.planName = data[`planName`];
         }
      }, (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR));
       
    } catch (error) {
      (error) => this.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    }
  }

  addMins(){
    this.submitted = true;
    const data = {          
      "clinicianId" : this.clinicianId,
      "mins": this.addMinForm.value.mins,
      "nextPaymentDue": this.addMinForm.value.date,
    };
    
    if (this.addMinForm.valid) {    
      this.cliniciansService.addMins(data).subscribe(data => {
        if(data.success == true){            
          this.balanceHour =  data.clinician.subscriptionDuration;
          this.nextPaymentDue =  data.nextPaymentDue;
          this.toastr.success('Minutes Added Successfully');
          this.addMinForm.get("mins").setValue('');
        }else{      
          this.toastr.success('Please Try Again');
        }
     }, (error) => this.errorCallback(error)); 
    }
  }

  errorCallback(error: any){
    this.toastr.error("Please try again");
  }

}