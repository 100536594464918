import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './services/auth.service';
import * as $ from 'jquery';
import { SharedPopupService } from '../shared/video-overlay/shared-video-overlay-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  showVideo = false;
  constructor(
    public authService: AuthService,
    @Inject(DOCUMENT) private document: Document, private sharedPopupService: SharedPopupService
  ) { }

  @ViewChild("mainContent")
  private mainContentDiv!: ElementRef<HTMLElement>;

  title = 'engageteletherapy';
  sidebarToggle: boolean = false
  toggleSidebar() {
    this.sidebarToggle = !this.sidebarToggle
  }
  ngOnInit() {
    this.sharedPopupService.popupObservable$.subscribe(show => {
      this.showVideo = show;
    });
  }
  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.sideBarInit();

  }
  ngDoCheck() {
    this.sideBarInit();
  }
  /* * * * * *
  * Check user logged in or not.
  */
  isUserLoggedin() {
    let is_user_logged_in = this.authService.isUserLoggedIn();
    return is_user_logged_in;
  }

  isUserTypeSet() {
    let userRole = window.localStorage.getItem('user_type');
    if (userRole) {
      return true
    }
    return false;
  }
  closeVideo() {
    this.sharedPopupService.togglePopup(false);
    localStorage.removeItem('previousLogin');
    localStorage.removeItem('lastLogin');
  }
  onActivate(_event: any): void {
    // Scrolling back to the top
    // Reference: https://stackoverflow.com/questions/48048299/angular-5-scroll-to-top-on-every-route-click/48048822
    (this.document.body as HTMLElement).scrollTop = 0;
    // if (this.mainContentDiv) {
    //   (this.mainContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    // }
  }

  public sideBarInit() {
    $('#sidebar-menu li a').on('click', (e) => {
      this.sidebarToggle = false
      e.preventDefault();
    });
    $('#sidebar-actions li a').on('click', (e) => {
      this.sidebarToggle = false
      e.preventDefault();
    });
  }
}
