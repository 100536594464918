import { Component, OnInit, SimpleChanges, EventEmitter, Output, Input, OnChanges} from '@angular/core';
import { PagerService } from '../../app/services/pager.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalRecords: number;
  @Input() pageSize: number;
  @Input() show: boolean;
  @Input() label: string
  @Output() pageChanged = new EventEmitter();
  @Output() paginationSizeChanged = new EventEmitter();
  pages: number[] = [];
  totalPages = 0;
  @Input() currentPage: number;
  //pageSizeArray = this.appSettings.PaginationArray;
  totalPageLinkButtons = 10;
  startPage = 1;
  endPage = this.totalPageLinkButtons;
  nextPage = 0;
  previousPage = 0;
  i = 0;
  record_label = "Total Records";
  constructor(private pagerService: PagerService) { }
  ngOnInit() {       
    this.currentPage = 1;
    if(this.label != undefined && this.label != null && this.label){
      this.record_label = this.label;
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {
    //this.createPages();
    this.setPage(this.currentPage);
  }

  createPages() {
    this.pages = [];
    this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
    if (this.currentPage > this.totalPages) {
      if (this.totalRecords > 0) {
        this.currentPage = this.totalPages;
        this.changePage(this.currentPage, 0);
      }
    }
    if (this.totalPages <= this.totalPageLinkButtons) {
      // less than totalPageButtons then show all
      // 1,2,3,.., totalPages are buttons
      this.startPage = 1;
      this.endPage = this.totalPages;
    } else {
      if (this.currentPage <= Math.ceil(this.totalPageLinkButtons / 2)) {
        this.startPage = 1;
        this.endPage = this.totalPageLinkButtons;
      } else if (this.currentPage + Math.ceil(this.totalPageLinkButtons / 2) > this.totalPages) {
        this.startPage = this.totalPages - this.totalPageLinkButtons + 1;
        this.endPage = this.totalPages;
      } else {
        this.startPage = this.currentPage - Math.ceil(this.totalPageLinkButtons / 2) + 1;
        this.endPage = this.startPage + this.totalPageLinkButtons - 1;
      }
    }

    for (let i = this.startPage; i <= this.endPage; i++) {
      this.pages.push(i);
    }

    if (this.totalPages === 1) {
      this.nextPage = 1;
      this.previousPage = 1;
    } else {
      this.nextPage = (this.currentPage === this.totalPages) ? this.currentPage : this.currentPage + 1;
      this.previousPage = (this.currentPage === 1) ? this.currentPage : this.currentPage - 1;
    }

  }
  // change page size on click of pagination pages
  changePage(page: number, flag: number) {
    console.log(page);
    console.log(this.currentPage);
    if(this.currentPage != page){
      if (flag === 0) {
        this.currentPage = page;
        this.createPages();
        this.pageChanged.emit(this.currentPage);
      }
    }
  }
  // change page size on change of show entries
  ChangingPageSize(newPageSize: any) {
      this.pageSize = newPageSize;
      this.paginationSizeChanged.emit(this.pageSize);
  }


    // pager object
  pager: any = {};
  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
        return;
    }      
    this.pager = this.pagerService.getPager(this.totalRecords, page, this.pageSize);

    if(this.currentPage != page){
      this.currentPage = page;  
      this.pageChanged.emit(this.currentPage);
    }
    if(this.totalRecords < 1)
      delete  this.pager.totalPages;
  }

}
