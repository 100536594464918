import { Component, OnInit, Inject, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import{ GlobalConstants } from '../../../shared/constants/global-constants';
import { ValidateFormService } from '../../services/validate-form.service';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {finalize } from 'rxjs/operators';
import { PatientService } from '../../services/patient.service';
import { NgxSpinnerService } from "ngx-spinner";
import { cliniciansService } from '../../services/clinicians.service';
import { AppointmentService } from '../../services/appointment.service';
import { SupportService } from 'src/app/services/support.service';

@Component({
  selector: 'app-client-checkout',
  templateUrl: './client-checkout.component.html',
  styleUrls: ['./client-checkout.component.scss'],
  providers: [PatientService,cliniciansService]
})
export class ClientCheckoutComponent implements OnInit {

  clientCheckoutForm: FormGroup;
  public submitted: boolean = false;
  invite: boolean = false;
  readonlyFlag: boolean = false;
  stackCount = 0;
  app_id = '';
  patient_id = '';
  roomId = '';
  clientName = '';
  clientEmail = '';
  waiting_id:any;
  formErrors = { 
    'client_name': '',
    'client_email': '',
  };

  validationMessages = {
    'client_name': { 'required': GlobalConstants.CLIENTNAME_REQUIRED,},
    'client_email': { 'required': GlobalConstants.CLIENTEMAIL_REQUIRED,
                       'pattern': GlobalConstants.CLIENTEMAIL__VALID 
      },
  };
  
  constructor(@Inject(DOCUMENT) private document: Document,
  private formBuilder: FormBuilder,
 
              protected validateForm: ValidateFormService,
              private router: Router,
              private toastr: ToastrService,
              private patientService: PatientService , 
              private activatedRoute: ActivatedRoute ,  
              public authService : AuthService,
              private spinner :  NgxSpinnerService,
              private cliniciansService: cliniciansService,
              public appointmentService : AppointmentService,public supportService: SupportService,) { }

  clinician_name : any;
  url : any;
  ngOnInit(): void {
    this.buildForm();
    this.document.body.classList.add('login-page-body');
    this.url =this.activatedRoute.snapshot.paramMap.get("N");
    this.clinician_name = this.activatedRoute.snapshot.paramMap.get("N");
    this.app_id = this.activatedRoute.snapshot.queryParamMap.get("app_id");
    this.patient_id = this.activatedRoute.snapshot.queryParamMap.get("patientId");
    if(this.patient_id !=null){
      this.getPatientDetails(this.patient_id);
      this.readonlyFlag=true;
    }else{
      this.readonlyFlag=false;
    }
    if(this.activatedRoute.snapshot.queryParamMap.get("invite")){
      this.invite=true;
      this.roomId=this.activatedRoute.snapshot.queryParamMap.get("invite");
      this.waiting_id=this.activatedRoute.snapshot.queryParamMap.get("waiting_id");
    }
    this.getClinicianNameFromUrl();
  }

  getPatientDetails(patientid){
          let data = {
          'patient_id' : patientid
        }
       this.patientService.getPatientData(data).subscribe(
          (data) => {    
            this.clientCheckoutForm.patchValue({
              client_name: data.items[0].firstName,
              client_email : data.items[0].email,
            });
        }, (error) => { }
      );
    }
  

  buildForm(): void {
    this.clientCheckoutForm = this.formBuilder.group({
      client_name: ['', [Validators.required]],
      client_email: ['', [Validators.required, 
                          Validators.maxLength(100),
                          Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{1,}')]],
    });
  } 

  ngDoCheck() {
    this.validateForm.validateFormFields(this.clientCheckoutForm, this);
  }

  ngOnDestroy() {
    this.document.body.classList.remove('login-page-body');
  }

  doCheckout(){
    this.router.navigate(['session/session-waiting-room']);
  }


  doToken(){
    var tokenData =  {
                    roomId: this.roomId,
                    name: this.clientCheckoutForm.controls.client_name.value,
                    role: 'participant',
                    user_ref: this.clientCheckoutForm.controls.client_email.value,
                    waiting_id: this.waiting_id,
                    app_id: this.app_id,
                  };        
    this.appointmentService.getAuthToken({ tokenData }).subscribe((data) => {
      if(data.success){   
        this.router.navigate(['/session/appointment-session'], {
          queryParams: {
            token: data.message.token,
            role: 'participant',
            roomId: this.roomId,
            app_id: this.app_id,
            url: this.url
          },
        });        
      }else{
        this.router.navigate(['checkin/'+this.url]);
        this.toastr.error(data.message);
      }
    },(error) => this.toastr.error(error.message));
  } 

  doCheckin(){
    this.submitted = true;
    this.spinner.show();
    if(this.clientCheckoutForm.valid){
      var  data  = {
        "firstName": this.clientCheckoutForm.controls.client_name.value,
        "email":this.clientCheckoutForm.controls.client_email.value, 
        "userName": this.clientCheckoutForm.controls.client_email.value,
        "is_session" : 1,
        "clinician_id": this.clinician_id,
        "app_id": this.app_id
      }
      let root = this;
        this.spinner.show();
        this.patientService.addClient(data).subscribe(data => {
        if (data.status !== '401') {
            this.spinner.hide();
            localStorage.setItem('client_name', this.clientCheckoutForm.controls.client_name.value); 
            this.router.navigate(['/session/client-waiting-room'] , { queryParams: { 
            id: data.wait_data.waiting_id,
            url: this.url} });
        }else{
          this.spinner.hide();
          this.toastr.error( data.message);
        }
      }, (error) => this.errorCallBack(error, root));
    }else{
      this.spinner.hide();
      this.validateForm.MarkControlsAsTouched(this.clientCheckoutForm);
    }
   
  }
  /***
   * Hide Loader
   * 
   */
  errorCallBack(error, root){
    root.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    root.spinner.hide();
  }

  clinician_id: any = '';
  getClinicianNameFromUrl(){
          let data = {
          'url' : this.url
        }
        this.cliniciansService.getClinicianNameFromUrl(data).subscribe(
          (data) => {    
           this.clinician_name = data.clinician_data.name;
           this.clinician_id = data.clinician_data.id;
           localStorage.setItem('clinician_firstname',this.clinician_name.split(" ")[0])
        }, (error) => { }
      );
    }
  
    
  openYoutubeModel(userType,videoUrl){
    let params = {userType:userType,videoUrl:videoUrl}
    const modalRef = this.supportService.openVideoModal(true,params,true).then().catch();
  }

  get userVideoUrl(){
    return GlobalConstants.userVideoUrl;
  }

}
