import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  first_name: any;
  lastName: any;
  is_user_logged_in : any = 0;
  profileImagePath:string = "";
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }
  ngOnInit(): void {
    this.first_name = localStorage.getItem('firstName');
    this.lastName = localStorage.getItem('lastName');
    this.is_user_logged_in = localStorage.getItem('is_user_logged_in');
    this.authService.isUserLoggedInHeader.subscribe(res => {
      this.is_user_logged_in = res;
    });
  }

  ngDoCheck(){this.is_user_logged_in = localStorage.getItem('is_user_logged_in');}
  
  public logout() {
    try {
      // logout of the system.
      this.authService.logout().subscribe(res => {
      });
      this.authService.clearToken();
      this.router.navigate(['/login']);
    } catch (error) {
      // this.apiService.LogError('Exception in: header.component.ts - In method: Logout. ' + error);
    }
  }

  /***
   * Get user's last Name.
   * 
   */
  getLastName() {
    var lastName = this.authService.getlastName();
    return lastName
  }

  /***
  * Get user's first Name.
  * 
  */
  getFirstName() {
    var firstName = this.authService.getfirstName();
    return firstName
  }

  
     /***
    * Get user's Profile Image.
    * 
    */
   getProfileImage(){
    var profileImagePath = this.authService.getProfileImage();
    return profileImagePath
  }


  getAccessControl(){
    var authService = this.authService.getAccessControl();
    return authService
  }
}
