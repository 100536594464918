import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
// import 'rxjs/add/operator/map';


export enum HttpReqMethod {
  POST,
  GET,
  DELETE,
  PUT
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private httpClient: HttpClient) { }

  public Request(url: string, method: string, body?: object) {
    try {
      if (method === HttpReqMethod.GET.toString()) {
        return this.httpClient.get<any>(url);
      } else if (method === HttpReqMethod.POST.toString()) {
        return this.httpClient.post<any>(url, body);
      } else if (method === HttpReqMethod.DELETE.toString()) {
        return this.httpClient.delete<any>(url);
      } else if (method === HttpReqMethod.PUT.toString()) {
        return this.httpClient.put<any>(url, body);
      }
    } catch (error) {
      this.LogError('Error In api.service.ts at Method: Request. ' + error);
    }
  }

  public async LogError(exception: string) {
    
  }

}

