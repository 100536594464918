import { Injectable } from '@angular/core';
import { ApiService, HttpReqMethod } from './api.service';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { YoutubeVideoModalComponent} from '../modules/support/youtube-video-modal/youtube-video-modal.component';
@Injectable({
  providedIn: 'root'
})
export class SupportService {
url : string;
  constructor(private apiService: ApiService,private modalService: NgbModal,) { }

  sendMails(data){
    this.url = 'supportemails/add-email';
     return this.apiService.Request(environment.userServiceUrl + this.url,
       HttpReqMethod.POST.toString(), data).pipe(
         map(res => {
           return res;
         }),
        catchError(this.handleError)
       )
  }

  me(){
    this.url = 'me';
     return this.apiService.Request(environment.userServiceUrl + this.url,
       HttpReqMethod.GET.toString()).pipe(
         map(res => {
           return res;
         }),
        catchError(this.handleError)
       )
  }

    //handle error
    handleError(error: HttpErrorResponse) {
      return throwError(error.error || 'Server error');
     }


  public openVideoModal(center: true | false, params: any, cancel: true | false): Promise<boolean> {
      const modalRef = this.modalService.open(YoutubeVideoModalComponent, { size: 'lg' });
      modalRef.componentInstance.params = params;
      modalRef.componentInstance.cancel = cancel;
      return modalRef.result;
  }

}
