import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import{GameInstructionComponent} from '../../shared/components/game-instruction/game-instruction.component'

@Injectable({
  providedIn: 'root'
})
export class GameInstrctionService {

  constructor(private modalService: NgbModal) { }

  public openGameInstructionModal( 
    dialogSize: 'sm'|'lg' = 'lg',
    app_id : string, is_view : boolean): Promise<boolean> {
    
    if(app_id != undefined && app_id != null && app_id != null){
      const modalRef = this.modalService.open(GameInstructionComponent, { size: dialogSize, backdrop: 'static', centered: true});
      modalRef.componentInstance.app_id = app_id;
      modalRef.componentInstance.is_view = is_view;
      return modalRef.result;
    }
  }
}
