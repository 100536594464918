<div class="custom-modal-overlay">
    <div class="custom-modal custom-modal-xs {{btnCancelClass}} alert-box">
        <div class="custom-modal-content p-0">
            <div class="alert-icon {{btnCancelClass}}">
                <!-- <i class="icon-user-x"></i> -->
                <i class="{{iconClass}}"></i>
            </div>
            <div class="p-3 mb-2">
                <h2 class="text-center text-white px-3">{{message}}</h2>
            </div>
            <div class="p-3 mb-3 text-center">
                <button class="btn btn-white btn-sm mr-3" (click)="accept()">{{ btnOkText }}</button>
                <button class="btn btn-white-link btn-sm" (click)="dismiss()">{{ btnCancelText }}</button>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal-body text-center">
    <h5 class="{{titleColor}} mb-3">{{ title }}</h5>
    <p class="text-center"> {{ message }} </p>
</div>
<div class="modal-footer no-top-border justify-content-center">
    <button type="button" class="btn btn-secondary" (click)="dismiss()">{{ btnCancelText }}</button>
    <button type="button" class="btn {{btnCancelClass}}" (click)="accept()">{{ btnOkText }}</button>
</div> -->