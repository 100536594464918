<div class="custom-modal-overlay">
    <div class="custom-modal  custom-modal-sm subscribe-modal">
        <div class="custom-modal-content p-3 pb-0">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title"><i class="icon-dollar-sign text-info mr-2"></i>Clinician
                    Subscription Details</h4>
                <button type="button" class="close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row mx-0">
                <div class="col-md bg-details pt-5">

                    <form [formGroup]="addMinForm">
                        <div class="row  pricing-plans">
                            <div class="col-md-12">
                              <h4 for="subscriptionPlan" class="w-100 mb-5 d-flex align-items-center">
                                  <!-- <span>Your Plan & Subscription</span> -->
                                  <span>Click the below plan to renew your Subscription</span>

                                  <span class="ml-auto mt-0 clearfix">Email : {{email}}</span>
                                <span class="ml-auto mt-0 clearfix"> Balance Session Time : {{balanceHour}}
                                </span>
                              </h4>
                            
                                <div class="form-group plans-box">
                                    <div class="custom-control custom-radio custom-control-inline" *ngFor="let plans of planList">
                                      <label class="custom-control-label" for="plans_{{plans.name}}">
                                        <input id="plans_{{plans.name}}" value='{{plans.id}}' type="radio" name="selectPlan" class="custom-control-input">
                                        <div class="plan-wrapper"  [class.plan_selected] = "plans.id == selectedPlanId">
                                          <div class="plan-header">
                                            <h4 class="text-center plan-name">{{plans.name}}</h4>
                                            <h1 class="text-center plan-time">${{plans.amount}}<span><!-- /500000{{plans.display_hrs}}--></span></h1>
                                            <!-- <h5 class="text-center plan-time"><span>Billed Monthly</span></h5> -->
                                          </div>

                                          <div class="plan-note">
                                            Note: {{plans.notes}}
                                          </div>

                                        </div>
                                      </label>
                                   </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                            <div class="form-group d-flex payment-due-date">
                                <div class="">
                                    <label for="Subscription Start Date" class="w-100">{{planName}} Start Date: {{startDate | date:'dd-MMM-yyyy'}}</label>
                                </div>
                                <div class="">
                                    <label for="Subscription End Date" class="w-100">{{planName}} End Date: {{nextPaymentDue | date:'dd-MMM-yyyy'}}</label>
                                </div>
                            </div>
                            </div>
                            
                            <div class="col-md-6 text-center">
                                <div class="form-group text-center d-flex align-items-center justify-content-center mb-1">
                                    <label for="Subscription Start Date" class="pr-3">Add Minutes:</label>
                                    <input class="form-control w-25" type="text" id="minutes" placeholder="500000" formControlName="mins">
                                    <div class="clearfix"></div>
                                    
                                </div>
                                <!-- <span *ngIf="formErrors.mins" class="error-msg">
                                    {{formErrors.mins}}
                                 </span> -->
                            </div>

                            <div class="col-md-6 text-center">
                                <div class="form-group text-center d-flex align-items-center justify-content-center mb-1">
                                    <label for="Subscription Start Date" class="pr-3">Subscription End Date:</label>
                                    <input class="form-control w-30" type="date" [(ngModel)]="selected_date" min="{{today}}"
                                         formControlName="date" id="date" placeholder="{{selected_date}}">
                                    <div class="clearfix"></div>
                                    
                                </div>
                                <span *ngIf="formErrors.date" class="error-msg">
                                    {{formErrors.date}}
                                 </span>
                            </div>
                            
                            <div class="col-md-12 mt-5 text-center">
                                <button class="btn btn-primary mr-3" (click)="addMins()">Save</button>
                                <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>