<nav class="navbar navbar-expand-lg navbar-light fixed-top"> 
    <div>
        <a class="navbar-brand ml-5" href="javascript:void(0)">
            <img src="assets/img/logo.png" alt="logo" class="img-fluid">
        </a>
    </div>
    <div class="d-flex align-items-center user-details" *ngIf="is_user_logged_in">
        <div class="username text-white mr-4">
            <span>Welcome {{getFirstName()}} {{getLastName()}}</span>
        </div>
        <div ngbDropdown class="d-inline-block">
            <div class="profile-img mb-3" ngbDropdownToggle>
                <div *ngIf="!getProfileImage()" class="profile-initial">
                    {{getFirstName() | slice:0:1 }}
                </div>
                <img *ngIf="getProfileImage()" src="{{getProfileImage()}}" alt="profile" class="img-fluid">
            </div>
            <!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Toggle dropdown</button> -->
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem *ngIf="getAccessControl() == 'admin'" routerLink="/admin-profile"><i
                        class="icon-user text-info mr-2"></i> Profile</button>
                <button ngbDropdownItem *ngIf="getAccessControl() == 'clinician'"
                    routerLink="/clinicians/clinician-profile"><i class="icon-user text-info mr-2"></i>
                    Profile</button>
                <button ngbDropdownItem *ngIf="getAccessControl() == 'clinician'" routerLink="/change-password"><i
                        class="icon-key text-info mr-2"></i> Change Password</button>
                <button ngbDropdownItem *ngIf="getAccessControl() == 'admin'" routerLink="/change-password"><i
                        class="icon-key text-info mr-2"></i> Change Password</button>
            </div>
        </div>
        <button class="btn text-white logout-btn mr-4 ml-5"  (click)="logout()"><i class="icon-power"></i></button>
    </div>
</nav>