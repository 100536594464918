import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpErrorResponse} from '@angular/common/http';
import { ApiService, HttpReqMethod } from './api.service';
import { map, catchError } from 'rxjs/operators';
import {  throwError } from 'rxjs';
import { Router} from '@angular/router';
import{ GlobalConstants } from '../../shared/constants/global-constants';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class AppointmentService {
  pipe = new DatePipe('en-US');
  sideBarEmitter : EventEmitter<null> = new EventEmitter()
  constructor(private httpClient: HttpClient, 
    private apiService: ApiService,
    private router: Router) { }

    //handle error
    handleError(error: HttpErrorResponse) {
      return throwError(error.error || 'Server error');
    }

    getClients(){
      return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/get-clients', HttpReqMethod.GET.toString()).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }


    getSessions(){
      return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/get-waiting-room-session', HttpReqMethod.GET.toString()).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }

    getUnreadCommentCount(){
      return this.apiService.Request(environment.userServiceUrl + 'message/get-unread-comment-count', HttpReqMethod.GET.toString()).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }

    public changeReload(): void{
      this.sideBarEmitter.emit();
    }

    /* * *
    * Date: 19-Sep-2020.
    * Added By: Bhagyashri Nikam.
    */
    bookAppointment(data){
      return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/book-appointment', HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }


    updateAppointmentSession(data){
      this.url = 'schedule-appointments/update-appointment-session';
      return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
      catchError(this.handleError)
      );    
    }

    updatePatientLastSeen(data){
      return this.apiService.Request(environment.userServiceUrl + 'patient/update-patient-last-seen', HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }

    isClinicianJoinedMeeting(data){
      return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/is-clinician-joined', HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }
    /***
     * get All Appointments for Clinician Appointments.
     * 
     */

    queryString: string;
    pageNumber: string;
    pageSize: string;
    like: any;
    searchParams: any = '';
    showType: string;
    sortString: string;
    emailBody: [];
    noStatus;
    url: string;
    is_filter_applied: any;
    patient_query = '&' + 'patient_data[]';
    public searchAppointments(searchParams, sortParams, pageNumber, pageSize, is_filter_applied = false) {
      this.pageNumber = 'page=' + pageNumber;
      this.pageSize = '&per-page=' + pageSize;
      this.is_filter_applied = '&is_filter_applied=' + is_filter_applied;
      this.queryString = '';
      this.url = 'schedule-appointments' + '?' + this.pageNumber + this.pageSize + this.is_filter_applied;
      this.patient_query = '&' + 'patient_data[]';
      if (typeof (sortParams) !== 'undefined') {
        this.queryString = '&sort=' + sortParams.sort;
      }
      this.queryString += '&' + 'patient_data[]';
      if (typeof (searchParams) !== 'undefined') {
        for (const key in searchParams) {
           if (searchParams[key]) {
            if (key === 'firstName') {
                  //this.queryString += '&' + 'filter[' + key + '][like]=' + searchParams[key];
                  this.patient_query += '&' + 'patient_data['+ key+ ']=' + searchParams[key];
            }else if (key === 'duration' ||  key === 'status') {
                for (const val in searchParams[key]) {
                  if (val) {
                    this.queryString += '&' + 'filter[' + key + '][]=' + searchParams[key][val]['id'];
                  }
                }
              
            }else if(key === 'startDate' || key === 'endDate'){
              this.queryString += '&' +  key + '=' + searchParams[key];
            } 
          }
          
        }
       
        }else{
          
        }
        this.queryString = this.queryString + this.patient_query;
      return this.apiService.Request(environment.userServiceUrl + this.url + this.queryString, HttpReqMethod.GET.toString()).pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
    }

    /* * *
    * Get EST time from UTC TIME.
    */
    getCurrentTime(){
      let offset = -5.0;
      if(localStorage.getItem('timezone') == "CST")
        offset = -6.0
      else if(localStorage.getItem('timezone') == "MST")
        offset = -7.0
      else if(localStorage.getItem('timezone') == "PST")
        offset = -8.0
      let clientDate = new Date();
      let  utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
      let est_time = new Date(utc + (3600000*offset)); 
      return est_time;
    }

    getAppointmentDetails(data){
      return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/get-appointment-details', HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }

    cancelAppointment(data){
      return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/delete-appointment', HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }

    /* *
     * get Appointments for Calendar view.
     */
    getAppointments(){
      return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/get-appointments', HttpReqMethod.GET.toString()).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      );
    }


    getRoomData(roomId: string) {
      this.url = 'schedule-appointments/room-start?id='+ roomId;
    
      return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
    }

    getSessionRoomData(data) {
      this.url = 'schedule-appointments/session-room-start';
      return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(),data).pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      )
    }

    getAuthToken({ tokenData }) {
      this.url = 'schedule-appointments/room-connect';
      return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(), tokenData).pipe(
      map(res => {
          return res;
        }),
       catchError(this.handleError)
   )}

   updateAppointmentStatus( tokenData) {
    this.url = 'schedule-appointments/update-appointment-status';
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(), tokenData).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );    
  }
 
  SendOtp(data) {
    this.url = 'schedule-appointments/send-otp';
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );    
  }

  verifyOtp(data){
    this.url = 'schedule-appointments/verify-otp';
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );    
  }

  getDropdownsForItems(expandList) {
    this.url = 'utility/drop-down-list?expand=' + expandList;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString());
  }

  addGameUrl(data) {
    this.url = 'schedule-appointments/add-appointment-games';
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );    
  }

  updateWaitingroom(Data){
    this.url = 'schedule-appointments/update-waitingroom';

    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.POST.toString(), Data).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }
  
    /* * *
    * Date: 19-Sep-2020.
    * Added By: Bhagyashri Nikam.
    */
   startSession(data){
    return this.apiService.Request(environment.userServiceUrl + 'schedule-appointments/book-session', HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );
  }

}


