import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError} from 'rxjs';
import {catchError,tap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { Router} from '@angular/router';
import { GlobalConstants } from 'src/shared/constants/global-constants';


@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    pendingRequests = 0;
    expiryTime: any;

    constructor( public authService: AuthService, 
                 private spinner :  NgxSpinnerService,
                 private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

       
        const that = this;
        if (req.method !== 'OPTIONS' && req.url.indexOf('oauth2/token') <= 0) {
            this.expiryTime = localStorage.getItem('expiryTime');
            //console.log(new Date(),'curenttime');
            setTimeout( () => { that.refreshToken(); /* console.log('here after time' + this.expiryTime); */ }, (this.expiryTime * 1000));
        }
        if(!(GlobalConstants.globalArray.indexOf(req.url) < 0))
            this.pendingRequests++;
        //console.log(this.pendingRequests,'pending request');
        if (this.pendingRequests >= 1) {
            if(GlobalConstants.globalArray.indexOf(req.url) < 0){
                this.spinner.show();
            } 
        }

        const dummyrequest = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                // Vary : '*',
                // Connection: 'Keep-Alive'
            }
        });
        return next.handle(dummyrequest).pipe(
                catchError((err: any) => {
                    if(!(GlobalConstants.globalArray.indexOf(req.url) < 0))
                        this.pendingRequests--;
                    if (this.pendingRequests === 0) {
                        // if(GlobalConstants.globalArray.indexOf(req.url) < 0){
                          
                            this.spinner.hide();
                        // }
                    }
                    if (err.status === 401 ||err.status === 403) {
                        this.handleAuthError();
                    }
                    return throwError (err);
                }))
                .pipe(tap(event => {
                    if (event instanceof HttpResponse) {
                        if (req.method !== 'OPTIONS' && req.url.indexOf('oauth2/token') > 0) {
                            this.expiryTime = (event.body.expires_in - 20);
                            localStorage.setItem('expiryTime', this.expiryTime);
                        }
                        if(!(GlobalConstants.globalArray.indexOf(req.url) < 0))
                            this.pendingRequests--;
                        if (this.pendingRequests === 0) {
                            // if(GlobalConstants.globalArray.indexOf(req.url) < 0){
                                // console.log(req.url,"interceptor");
                              this.spinner.hide();
                            // }
                        }
                    }
                }));
      }
    

    /* * *
    * Author: Bhagyashri Nikam.
    @ Date:25-Aug-2020
    * Generate Refresh Token.
    */ 
   refreshToken() {
        const token = localStorage.getItem('accessToken');
        if (token != null && token !== undefined) {
            this.authService.refreshToken();
        }
    }

    /* * *
    * Author: Bhagyashri Nikam.
    @ Date:25-Aug-2020
    * To handle auth error.
    */ 

    handleAuthError() {
        this.authService.clearToken();
        this.router.navigate(['/login']);
    }
}
