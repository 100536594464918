<div class="modal-header">
  <h3 class="modal-title">Help</h3>
  <button type="button" class="close" (click)="cancel()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="container">
    <div class="mx-auto col-sm-12 col-md-12 col-xl-12 col-lg-12">
      <h2 class="my-5"><i class="icon-headphones"></i> Help/Support</h2>
      <form [formGroup]="supportClinicianForm" novalidate class="mt-5">
        <div class="row">
          <div class="clearfix mb-5 w-100"></div>
          <div class="col-md-12">
            <div class="form-group has-float-label">
              <input
                class="form-control"
                type="text"
                id="username"
                placeholder="Username"
                formControlName="username"
                [(ngModel)]="userName"
                (change)="trimVal('u')"
              />
              <label for="username">Username</label>
              <span *ngIf="formErrors.username" class="error-msg">
                {{ formErrors.username }}
              </span>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="
                form-group
                has-float-label
                field-with-icon
                position-relative
              "
            >
              <input
                class="form-control"
                type="text"
                id="email"
                placeholder="Email"
                formControlName="email"
                [(ngModel)]="email"
                (change)="trimVal('e')"
              />
              <label for="email">Email</label>
              <span *ngIf="formErrors.subject" class="error-msg">
                {{ formErrors.email }}
              </span>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="
                form-group
                has-float-label
                field-with-icon
                position-relative
              "
            >
              <input
                class="form-control"
                type="text"
                id="subject"
                placeholder="Subject"
                formControlName="subject"
                (change)="trimVal('s')"
              />
              <label for="subject">Subject</label>
              <span *ngIf="formErrors.subject" class="error-msg">
                {{ formErrors.subject }}
              </span>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="
                form-group
                has-float-label
                field-with-icon
                position-relative
              "
            >
              <textarea
                class="form-control"
                id="message"
                placeholder="Message"
                formControlName="message"
                rows="4"
                (change)="trimVal('m')"
              ></textarea>
              <label for="Message">Message</label>
              <span *ngIf="formErrors.message" class="error-msg">
                {{ formErrors.message }}
              </span>
            </div>
          </div>

          <div
            class="
              col-md-12
              mt-3
              mb-5
              pb-3
              position-sticky
              fixed-bottom
              text-center
              stickey-btn-bg
            "
          >
            <button class="btn btn-primary mr-3 mb-3" (click)="sendMail()">
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
