export const environment = {
  production: true,
  staging: false,
  authServiceUrl: 'https://app.engageplaytherapy.com/services/api/',
  userServiceUrl: 'https://app.engageplaytherapy.com/services/api/v1/',
  clinicianURL:   'https://app.engageplaytherapy.com/#/',
  clientId: 'testclient',
  clientSecret: 'testpass',
  loginGrantType: 'password',
  refreshGrantType: 'refresh_token',
  stripePublishKey: 'pk_live_51HaoMbAclNHRiKmCVSk8lvMCf1E9TxWKFHabAWLQCqptgAJ8mdsxv8qak65NDCwbahySYgUc1Ma0ENgv2XqOcRrS00LvAR0kaH',
  apiUrl: 'https://app.engageplaytherapy.com:3000',
  WBO_URL: 'https://app.engageplaytherapy.com:9998/',
  // WBO_URL: 'https://app.engageplaytherapy.com/wbo/',
  GUESS_WHO_URL: 'https://php7.benchmarkit.solutions/engageteletherapy/gamesdemo/EngageTherapyGuessWho/',
  PUSHER_API_KEY: '0e7e70a6d15d1653a716',
  PUSHER_API_CLUSTER: 'ap2',
  PATIENT_LAST_SEEN_TIME_OUT:300000,//300000 milliseconds means 5 minutes
};

// export const environment = {
//   production: true,
//   staging: false,
//   authServiceUrl: 'https://php7.benchmarkit.solutions/engageteletherapy/demo/api/',
//   userServiceUrl: 'https://php7.benchmarkit.solutions/engageteletherapy/demo/api/v1/',
//   clinicianURL:   'https://php7.benchmarkit.solutions/engageteletherapy/qa/#/',
//   clientId: 'testclient',
//   clientSecret: 'testpass',
//   loginGrantType: 'password',
//   refreshGrantType: 'refresh_token',
//   stripePublishKey: 'pk_test_zPUqzYZc9lrUr3W2hKyu4yv300ZZVMLXkf',
//   apiUrl: 'https://php7.benchmarkit.solutions:3000',
//   WBO_URL: 'https://php7.benchmarkit.solutions/wbo/',
//   GUESS_WHO_URL: 'https://php7.benchmarkit.solutions/engageteletherapy/gamesdemo/EngageTherapyGuessWho/',
//   PUSHER_API_KEY: '0e7e70a6d15d1653a716',
//   PUSHER_API_CLUSTER: 'ap2'
// };

// export const environment = {
//   production: true,
//   staging: false,
//   authServiceUrl: 'https://php7.benchmarkit.solutions/engageteletherapy/live_backend/api/',
//   userServiceUrl: 'https://php7.benchmarkit.solutions/engageteletherapy/live_backend/api/v1/',
//   clinicianURL:   'https://engage.benchmarkit.solutions/#/',
//   clientId: 'testclient',
//   clientSecret: 'testpass',
//   loginGrantType: 'password',
//   refreshGrantType: 'refresh_token',
//   stripePublishKey: 'pk_live_51HaoMbAclNHRiKmCVSk8lvMCf1E9TxWKFHabAWLQCqptgAJ8mdsxv8qak65NDCwbahySYgUc1Ma0ENgv2XqOcRrS00LvAR0kaH',
//   apiUrl: 'https://php7.benchmarkit.solutions:3000',
//   WBO_URL: 'https://php7.benchmarkit.solutions/wbo/',
//   GUESS_WHO_URL: 'https://php7.benchmarkit.solutions/engageteletherapy/gamesdemo/EngageTherapyGuessWho/',
//   PUSHER_API_KEY: '0e7e70a6d15d1653a716',
//   PUSHER_API_CLUSTER: 'ap2'
// };