import { Component, OnInit, Inject,Input,ChangeDetectionStrategy,ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ValidateFormService } from '../../../app/services/validate-form.service';
import { AuthService } from '../../../app/services/auth.service';
import { Router,NavigationStart} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from '../../../app/services/appointment.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import{ GlobalConstants } from '../../constants/global-constants';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-schedule-appointments',
  templateUrl: './schedule-appointments.component.html',
  styleUrls: ['./schedule-appointments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ScheduleAppointmentComponent implements OnInit {

  duration_array = GlobalConstants.SERVICE_DURATION;
  @Input() app_id: string;
  @Input() is_view: string;
  @Input() clickedDate: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  public time : any;
  public startAt : any; 
  pipe = new DatePipe('en-US');
  now = new Date();
  today :any; 
  id : any;
  scheduleAppointmentForm: FormGroup;
  flag = false;
  date_valid = "";
  time_valid = "";
  title = "Schedule Appointment";
  timezone = "EST";
  //form Errors
  formErrors = { 
    'client_name': '',
    'duration': '',
    'date': '',
    'time': '',
    'note': '',
    'owl_time': '',
  };

  //validation messages
  validationMessages = {
    'client_name': { 'required': GlobalConstants.CLIENTNAME_REQUIRED,},
    'duration':  { 'required': GlobalConstants.DURATION_REQUIRED, },
    'date': { 'required': GlobalConstants.DATE_REQUIRED, },
    'time': { 'required': GlobalConstants.TIME_REQUIRED, },
    'note': '',
    'owl_time': '',
  };
  
  constructor(@Inject(DOCUMENT) private document: Document,
                                private formBuilder: FormBuilder,
                                protected validateForm: ValidateFormService,
                                public authService : AuthService,
                                private router: Router,
                                private toastr: ToastrService,
                                private spinner :  NgxSpinnerService,
                                private appointmentService: AppointmentService,
                                private activeModal: NgbActiveModal,
                                private el: ElementRef
                                ) { 
                                  
                                  this.onBrowserBack();
                                  this.startAt = this.appointmentService.getCurrentTime();
                                
                                }
 is_disabled : boolean = false;
 is_read_only:any = false;
  ngOnInit(): void {
    
      //get active clients
      this.getClients();
      this.spinner.show();
      //only for edit get Appointment details.
      if(this.app_id){
           this.flag = true;
          //get Appintment Details
          this.getAppointmentDetails();
          this.title = "Edit Appointment";
          this.is_disabled = true;
      }
    
    //dropdown settings.

     this.dropdownSettings = { 
      singleSelection: true, 
      text:"--Select--",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class singleselect",
      showCheckbox:true,
      noDataLabel:"Result not found",
      searchPlaceholderText:"Please Select Client Name",
      closeDropDownOnSelection: false,
      enableFilterSelectAll : false,
      disabled:this.is_disabled
    };
    this.buildForm();

    if(this.is_view){
      this.is_read_only = this.is_view;
      this.title = "View Appointment";
      this.scheduleAppointmentForm.controls['date'].disable();
      this.scheduleAppointmentForm.controls['duration'].disable();
      this.scheduleAppointmentForm.controls['note'].disable();
      this.scheduleAppointmentForm.controls['time'].disable();
      this.scheduleAppointmentForm.updateValueAndValidity();
    }

    if(this.clickedDate != undefined && this.clickedDate != null && this.clickedDate != '')
      this.selected_date = this.pipe.transform(this.clickedDate, 'yyyy-MM-dd');

    this.timezone = (localStorage.getItem('timezone') != '') ? localStorage.getItem('timezone') : 'EST';
}


  buildForm(): void {
    this.scheduleAppointmentForm = this.formBuilder.group({
      client_name: ['', [Validators.required]],
      duration: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      note: [''],
      owl_time: [''],
    });
  } 

  ngDoCheck() {
    this.validateForm.validateFormFields(this.scheduleAppointmentForm, this);
  }

  client_id : any
  onItemSelect(item:any){
   // console.log(item,'item');
    this.client_id = item.id;
  }

  OnItemDeSelect(item:any){
    // console.log(item);
    // console.log(this.selectedItems);
  }

  onSelectAll(items: any){
    // console.log(items);
  }
  onDeSelectAll(items: any){
    this.selectedItems = [];
  }

  isShown: boolean = false ; // hidden by default
  toggleShow() {
    this.isShown = ! this.isShown;
  }

  // //set Current Time 
  navigateEvent(event){
   //console.log(event,'this.cur_time');
  }

   //set Current Date 
  selected_date : any;
  currentDate(){
    this.today = this.pipe.transform(this.now, 'yyyy-MM-dd','GMT -4')
    if(this.selected_date != undefined && this.selected_date != null && this.selected_date != ''){
      this.selected_date = this.pipe.transform(this.selected_date, 'yyyy-MM-dd');
    }else{
      this.selected_date = this.pipe.transform(this.now, 'yyyy-MM-dd','GMT -4');
    }
  }

   /* * * *
   * Get Clinician Clients.
   * 
   */
    getClients(){
      this.appointmentService.getClients().subscribe(data => {
            if (data.status == '101') {
              this.dropdownList = data.clients;
            }else{
              this.toastr.error( data.message);
            }
          }, (error) => this.toastr.error(error.message));
    }

   /* * * *
   * Save Appointments
   * 
   */
  data: any;
  bookAppointment(){
      const root = this;
      this.time_valid='';
      this.date_valid='';
      if (this.scheduleAppointmentForm.valid) {     
            let time = this.scheduleAppointmentForm.controls.time.value;
            time = this.pipe.transform(time, 'HH:mm');
            function convertTZ(date, tzString) {
                return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
            }
            const today = new Date();        
            var convertTime= convertTZ(today, this.timezone);
            let currentTime = this.pipe.transform(convertTime, 'HH:mm');        
             const yesterday = new Date(today);             
             yesterday.setDate(yesterday.getDate() - 1);
             let startDate = new Date(this.scheduleAppointmentForm.controls.date.value);
             let pastDate = yesterday;
             
             let selected_date = this.pipe.transform(startDate, 'yyyy-MM-dd');
             let current_date = this.pipe.transform(today, 'yyyy-MM-dd');
             if(startDate < pastDate) {
                 this.spinner.hide();
                 this.date_valid="Date does not allow for past.";
                 setTimeout(() =>  this.date_valid='', 1000);
                 return;
                }else if(selected_date == current_date && time <= currentTime) {
                    this.spinner.hide();
                    this.time_valid="Time did not allow for less than the current time.";
                    setTimeout(() =>  this.time_valid='', 1000);
                     return;
                }else{
                        this.data = {
                            "patientsId":this.client_id,
                            "duration":this.scheduleAppointmentForm.controls.duration.value,
                            "startDate":this.scheduleAppointmentForm.controls.date.value,
                            "startTime":time,
                            "notes": this.scheduleAppointmentForm.controls.note.value,
                            "app_id": this.app_id,
                            "timezone":this.timezone,
                        }
                        const subscribe =  this.appointmentService.bookAppointment(this.data).subscribe(data => {
                        if (data.status !== '101') {
                            if(data.status === 208){
                                    this.toastr.error(data.message);
                                }else{
                                    this.toastr.success(data.message);
                                    this.activeModal.close(true);
                                }
                           
                        }else{
                            this.activeModal.close(false);
                        }
                        }, (error) => this.errorCallBack(error, root));
              }

    }else{
       //on submit make field touched.
       this.validateForm.MarkControlsAsTouched(this.scheduleAppointmentForm);
    }
  }

   /***
   * Hide Loader
   * 
   */
  errorCallBack(error, root){
    root.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    root.spinner.hide();
  }

  cancel(){
    this.activeModal.close(false);
  }

  /***
  * After DatePicker Opens 
  * 
  */
  pickerOpen(){
    let time = this.scheduleAppointmentForm.controls.time.value;
    time = this.pipe.transform(time, 'HH:mm');
    if(time != undefined && time != null && time != ''){
      this.startAt = time;
    }else{
      this.startAt = this.appointmentService.getCurrentTime();
    }
  }

  app_data : any;
  getAppointmentDetails(){
    let data = {
      'app_id' : this.app_id
    }
    this.appointmentService.getAppointmentDetails(data).subscribe(data => {
      if (data.status == '101') {
        this.app_data = data.app_data;
        //console.log(this.app_data.duration,this.app_data.note,this.app_data.startDateTime)
        this.scheduleAppointmentForm.controls['duration'].setValue( this.app_data.duration);
        this.scheduleAppointmentForm.controls['note'].setValue(this.app_data.note);
        this.scheduleAppointmentForm.controls['date'].setValue( this.app_data.startDate);
        this.time = new Date( parseInt(this.app_data.year), 
                             parseInt(this.app_data.month), 
                             parseInt(this.app_data.date), 
                             parseInt(this.app_data.hour),
                             parseInt(this.app_data.min),
                             parseInt(this.app_data.sec));
     
        this.startAt = this.pipe.transform(this.time, 'HH:mm');
        this.scheduleAppointmentForm.controls['time'].setValue(this.time);
        this.selectedItems.push(this.app_data.selectedItems);
        this.scheduleAppointmentForm.controls.client_name.setValue(this.selectedItems);
        this.client_id = this.app_data.selectedItems.id
        this.timezone = this.app_data.timezone;
        //this.selectedItems = this.app_data.selectedItems;
        this.scheduleAppointmentForm.updateValueAndValidity();
      }else{
        this.toastr.error( data.message);
      }
    }, (error) => this.toastr.error(error.message));
  }

  onBrowserBack(){
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.activeModal.close(false);
      }
    });
  }
}
