
        <div class="modal-header">
            <h3 class="modal-title">Waiting Room</h3>
            <button type="button" class="close" (click)="cancel() "><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
            <div class="list-view mt-4 table-responsive" id="no-more-tables">
                <div class="col-md-12 mb-5">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 14%" class="border-top-0">Client Name</th>
                                <th scope="col" style="width: 18%" class="border-top-0"> Client Email </th>
                                <th scope="col" style="width: 12%" class="border-top-0">Date & Time</th>
                                <th scope="col" style="width: 12%" class="border-top-0">Duration</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="waitingroom_count;else no_records">
                            <tr *ngFor="let clinicians of waitingroom_data">
                                <td data-title="Name">{{clinicians.firstName}}</td>
                                <td data-title="User Name">{{clinicians.email}}</td>
                                <td data-title="User Name">{{clinicians.startDate}}</td>
                                <td data-title="User Name">{{clinicians.duration}}</td>
                            </tr>
                        </tbody>

                        <ng-template #no_records>
                            <tr>
                                <td colspan="4" class="text-center"> No Result Found </td>
                            </tr>
                        </ng-template>

                    </table>
                </div>
            </div>
        </div>