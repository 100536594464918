<div class="login-page">
    <!--ACCOUNT CREATED FORM-->
    <div class="forgot-page">
        <div class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </div>
        <h1 class="text-center mt-0">Welcome</h1>
        <div class="mb-4">
            Please Checkin below to let {{clinician_name}} know you are here
        </div>
         <!-- start User Type Youtube video link  -->
            <div class="row">
                <div class="col-md-12 m-0 p-0 text-center">
                    <a href="javascript:void(0)" (click)="openYoutubeModel('Client',userVideoUrl.Client)" ngbTooltip="Checkout client login tutorial"><i class="icon-video"></i>&nbsp;Client login tutorial </a>
                </div>
            </div>    
         <!-- end -->
        <form  [formGroup]="clientCheckoutForm" novalidate class="mt-5">
            <div class="form-group has-float-label field-with-icon position-relative" *ngIf="readonlyFlag">
                <input class="form-control new-text-white" type="text" id="client_name" placeholder="Client Name" formControlName="client_name" readonly>
                <label for="client_name">Client Name</label>
                <span *ngIf="formErrors.client_name" class="error-msg">
                        {{formErrors.client_name}}
                 </span>
            </div>
            <div class="form-group has-float-label field-with-icon position-relative" *ngIf="readonlyFlag">
                <input class="form-control new-text-white" type="text" id="client_email" placeholder="Client Email" formControlName="client_email" readonly>
                <label for="client_email">Client Email</label>
                <span *ngIf="formErrors.client_email" class="error-msg">
                    {{formErrors.client_email}}
                </span>
            </div>
            <div class="form-group has-float-label field-with-icon position-relative" *ngIf="!readonlyFlag">
                <input class="form-control new-text-white" type="text" id="client_name" placeholder="Client Name" formControlName="client_name">
                <label for="client_name">Client Name</label>
                <span *ngIf="formErrors.client_name" class="error-msg">
                        {{formErrors.client_name}}
                 </span>
            </div>
            <div class="form-group has-float-label field-with-icon position-relative" *ngIf="!readonlyFlag">
                <input class="form-control new-text-white" type="text" id="client_email" placeholder="Client Email" formControlName="client_email">
                <label for="client_email">Client Email</label>
                <span *ngIf="formErrors.client_email" class="error-msg">
                    {{formErrors.client_email}}
                </span>
            </div>
            <div class="d-flex mb-3">
                <button class="btn btn-lg btn-primary w-100" (click)="doCheckin()" *ngIf="!invite">Checkin</button>
                <button class="btn btn-lg btn-primary w-100" (click)="doToken()" *ngIf="invite">Checkin</button>
            </div>
        </form> 
    </div>
</div>
