import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent} from '../../shared/components/confirm-dialog/confirm-dialog.component';


@Injectable({
  providedIn: 'root'
})

 export class ConfirmDialogService {
    constructor(private modalService: NgbModal) { }
    public confirm(
      title: string,
      titleColor:'text-danger' | 'text-success' = 'text-danger',
      message: string,
      btnOkText: string = 'OK',
      btnCancelText: string = 'Cancel',
      btnCancelClass: 'bg-danger' | 'bg-success' = 'bg-danger',
      dialogSize: 'sm'|'lg' = 'sm',
      iconClass: string,
      center: true | false): Promise<boolean> {
      const modalRef = this.modalService.open(ConfirmDialogComponent, { size: dialogSize, backdrop: 'static', centered: center });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.titleColor = titleColor;
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.btnOkText = btnOkText;
      modalRef.componentInstance.btnCancelClass = btnCancelClass;
      modalRef.componentInstance.btnCancelText = btnCancelText;
      modalRef.componentInstance.iconClass = iconClass;
      return modalRef.result;
    }
}





