import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService, HttpReqMethod } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';



@Injectable() export class cliniciansService {

  url: string;
  queryString: string;
  pageNumber: string;
  pageSize: string;
  like: any;
  searchParams: any = '';
  showType: string;
  sortString: string;
  emailBody: [];
  noStatus;
  constructor(private httpClient: HttpClient, private apiService: ApiService) { }

  public searchClinicians(searchParams, sortParams, pageNumber, pageSize) {
    this.pageNumber = 'page=' + pageNumber;
    this.pageSize = '&per-page=' + pageSize;
    this.queryString = '';
    this.url = 'clinician' + '?' + this.pageNumber + this.pageSize;

    if (typeof (sortParams) !== 'undefined') {
      this.queryString = '&sort=' + sortParams.sort;
    }
    this.queryString += '&' + 'professionalRole[]=';

    if (typeof (searchParams) !== 'undefined') {
      for (const key in searchParams) {
           
        if (searchParams[key]) {
          if (key === 'userName' || key === 'email' || key === 'shortName' || key === 'lastLogin') {
                this.queryString += '&' + 'filter[' + key + '][like]=' + searchParams[key];
          } 
          else if (key === 'fullName') {
            this.queryString += '&' + 'filter[or][0][' + key + '][like]=' + searchParams[key];
            this.queryString += '&' + 'filter[or][1][companyName][like]=' + searchParams[key];
          }  
          else if (key === 'status') {            
            for (const val in searchParams[key]) {
              if (val) {
                this.queryString += '&' + 'filter[' + key + '][]=' + searchParams[key][val]['id'];
              }
            }         
          }
          else if (key === 'professionalRole') {      
            var i= 0;    
            for (const val in searchParams[key]) {             
              if (val) {
                this.queryString += '&' +  key + '[]=' + searchParams[key][val]['itemName'];
              }
              i = i + 1;
            }
          } 
          // else if (key === 'professionalRole') {      
          //   var i= 0;    
          //   for (const val in searchParams[key]) {             
          //     if (val) {
          //       this.queryString += '&' + 'filter[or]['+ i +'][' + key + '][like][]=' + searchParams[key][val]['itemName'];
          //     }
          //     i = i + 1;
          //   }
          // } 
          else if (key === 'approvalStatus') {
            for (const val in searchParams[key]) {
              if (val) {
                this.queryString += '&' + 'filter[' + key + '][]=' + searchParams[key][val]['id'];
              }
            }
          } 
          // if(searchParams['status']==''){
          //   this.noStatus='1';
          // }
        }
      }
        // if(this.noStatus == '1'){
        //   this.queryString += '&filter[status][]=A&filter[status][]=U&filter[status][]=E';     
        // }       
      }else{
        //this.queryString += '&filter[status][]=A&filter[status][]=U&filter[status][]=E';  
      }
    
    return this.apiService.Request(environment.userServiceUrl + this.url + this.queryString, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }
  
  public getStateList() {
    const url = environment.userServiceUrl + 'clinician/state-list/';
    return this.apiService.Request(url, HttpReqMethod.GET.toString());
  }

  public getCountryList() {
    const url = environment.userServiceUrl + 'clinician/country-list/';
    return this.apiService.Request(url, HttpReqMethod.GET.toString());
  }
  
  
  public gettimeZoneList() {
    const url = environment.userServiceUrl + 'clinician/timezone-list/';
    return this.apiService.Request(url, HttpReqMethod.GET.toString());
  }


  public getClinicianNameFromUrl(data) {
   return this.apiService.Request(environment.userServiceUrl + 'clinician/clinician-name', HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    );
  }

  public isUserExists(data){
    this.url = 'clinician/validate-unique-user';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  public isEmailExists(data){
    this.url = 'clinician/validate-unique-email';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Server error';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  public addClinicianInfo(data) {
    this.url = 'clinician/create';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  public getStateListCountrywise(data) {
    this.url = 'clinician/statelist-countrywise';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }
  
  public GetClinicianData(userId: string) {
    this.url = 'clinician/view?id='+ userId;

    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }
  public addClinicianFeedback(data) {
    this.url = 'survey/add-patient-survey';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }  

  public updateBasicInfo(data,userId: string) {
    this.url = 'clinician/update-basicinfo/'+ userId;
    if(localStorage.getItem('user_type') == 'clinician')
      localStorage.setItem('timezone',data.get('timeZone'));
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  public updateNameLocationInfo(data,userId: string) {
    this.url = 'clinician/update-namelocationinfo/'+ userId;
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  public updateVerificationInfo(data,userId: string) {
    this.url = 'clinician/update-verificationinfo/'+ userId;
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.PUT.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  approveDisapproveClinician(data){
    console.log(data,'inside approveDisapproveClinician');
    this.url = 'clinician/approve-disapprove-clinician';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }


  changeClinicianStatus(data){
    this.url = 'clinician/change-clinician-status';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  public SignupClinicianInfo(data) {
    this.url = 'clinician/create-signup';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  public addMins(data) {
    this.url = 'clinician/add-mins';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }
  
  public addSubscription(data) {
    this.url = 'subscription/create';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  
  public getCustomerSessionData(userUrl: string) {
    this.url = 'subscription/get-customer-session-data?session-id='+ userUrl;

    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  } 

  public updateSubscription(data) {
    this.url = 'subscription/update-plan';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  getDropdownsForItems(expandList) {
    this.url = 'utility/drop-down-list?expand=' + expandList;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString());
  }

  public GetCliniciansSubscriptionData(userId: string) {
    this.url = 'subscription/view?id='+ userId;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  public getSubscriptionDetails(userId: string) {
    this.url = 'subscription/get-subscription-details?cliniciansid='+ userId;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  deleteClinician(data){
    this.url = 'clinician/delete-clinician';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  cancelSubscriptionService(data){
    this.url = 'clinician/cancel-subscription';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  addNotes(data){
    this.url = 'clinician/create-notes';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  getNotes(){
    this.url = 'clinician/notes';
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  addpost(data){
    this.url = 'clinician/create-post';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  getPosts(myPosts = false,searchQuery = '',markCommentRead = false,searchQueryCategory=''){
    this.url = 'clinician/posts?mypost='+myPosts+'&searchQuery='+searchQuery+'&searchQueryCategory='+searchQueryCategory+'&markCommentRead='+markCommentRead;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  getAdminPosts(myPosts = false,searchQuery = '',markCommentRead = false,searchQueryCategory=''){
    this.url = 'clinician/admin-posts?mypost='+myPosts+'&searchQuery='+searchQuery+'&searchQueryCategory='+searchQueryCategory+'&markCommentRead='+markCommentRead;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }
  
  addComment(data){
    this.url = 'clinician/create-comment';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  addPostCategory(data){
    this.url = 'clinician/create-post-category';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  deletePostComment(data){
    this.url = 'clinician/delete-post-comment';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  getMessageandComments(sortParams,pageNumber,pageSize){
    this.pageNumber = 'page=' + pageNumber;
    this.pageSize = '&per-page=' + pageSize;
    this.url = 'message' + '?' + this.pageNumber + this.pageSize;
    if (typeof (sortParams) !== 'undefined') {
      this.url += '&sort=' + sortParams.sort;
    }
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  getMessage(type,id){

    this.url = 'message/get-message' + '?type=' + type + '&id=' + id;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  acceptTerms(data){
    this.url = 'me/accept-terms';
    return this.apiService.Request(environment.userServiceUrl + this.url,
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

  resendVerificationEmail(clinician_id){

    this.url = 'clinician/resend-verification-email?id=' + clinician_id;
    return this.apiService.Request(environment.userServiceUrl + this.url, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

   //get login users details
   getClinicianBalanceDetails(clinicianId){
    return this.apiService.Request(environment.userServiceUrl + 'me/clinician-balance-details?isadmin=true&clinicianId='+clinicianId, HttpReqMethod.GET.toString()).pipe(
      map(res => {return res;}),
     catchError(this.handleError)
    );
  }
}
