import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router,NavigationStart} from '@angular/router';
@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
})

export class ConfirmDialogComponent {

    @Input() title: string;
    @Input() titleColor: string;
    @Input() message: string;
    @Input() btnOkText: string;
    @Input() btnCancelClass: string;
    @Input() btnCancelText: string;
    @Input() iconClass: string; 
    constructor(private activeModal: NgbActiveModal, public router : Router ) { 
      this.onBrowserBack();
    }
    public decline() {
      this.activeModal.close(false);
    }

    public accept() {
      this.activeModal.close(true);
    }

    public dismiss() {
      this.activeModal.dismiss();
    }

    onBrowserBack(){
      this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.activeModal.dismiss();
        }
      });
    }
}
