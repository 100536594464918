import { Component, OnInit , Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidateFormService } from 'src/app/services/validate-form.service';
import { SupportService } from 'src/app/services/support.service';
import { GlobalConstants } from 'src/shared/constants/global-constants';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  supportForm: FormGroup;
  public submitted: boolean = false;
  isUserLoggedIn = false;
  userName: string;
  email: string;
  data: any;
  BBAPDFPath: string;
  formErrors = {
    'username': '',
    'email': '',
    'subject': '',
    'message': '',
  };

  validationMessages = {
    'username': { 'required': 'Username cannot be blank.' },
    'email': { 'required': 'Email cannot be blank.' , 'pattern': GlobalConstants.EMAIL_PATTERN },
    'subject': { 'required': 'Subject cannot be blank.' },
    'message': { 'required': 'Message cannot be blank.' },
  };
  constructor(@Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    protected validateForm: ValidateFormService,
    public supportService: SupportService,
    private router: Router,
    
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.document.body.classList.add('login-page-body');
    this.buildForm();
    this.checkLoginStatus();
    this.BBAPDFPath = environment.authServiceUrl + '../uploads/EngageBAA.pdf';
  }

  ngDoCheck() {
    this.validateForm.validateFormFields(this.supportForm, this);
  }

  ngOnDestroy() {
    this.document.body.classList.remove('login-page-body');
  }

  buildForm(): void {
    this.supportForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{1,}')]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  sendMail() {
    this.submitted = true;
    const root = this;
    if (this.supportForm.valid) {
      this.data = {
        "userName": this.supportForm.controls.username.value,
        "email": this.supportForm.controls.email.value,
        "subject": this.supportForm.controls.subject.value,
        "message": this.supportForm.controls.message.value,
        "createdBy": "0"
      }

      if(this.isUserLoggedIn){

        this.supportService.me().subscribe(res => {

          if (res.status !== '401') {
            this.data.createdBy = res.id;
            this.hitMailService();
          }
        });
      }else{
        this.hitMailService();
      }

      

    } else {
      //on submit make field touched.
      this.validateForm.MarkControlsAsTouched(this.supportForm)
    }

  }

  hitMailService(){
    this.supportService.sendMails(this.data).subscribe(res => {

      if (res.success) {
        this.toastr.success( res.message);
      }else{
        this.toastr.error( res.message);
      }

    });
  }

  errorCallBack(error, root){
    root.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
    root.spinner.hide();
  }

  checkLoginStatus(){

    if(localStorage.getItem('is_user_logged_in') && localStorage.getItem('user_type') == 'clinician'){
      this.isUserLoggedIn = true;
      this.userName =  this.titleCaseWord(localStorage.getItem('firstName')) + ' ' + this.titleCaseWord(localStorage.getItem('lastName'));
      this.email = localStorage.getItem('userEmail');
    }

  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  trimVal(obj){
    switch(obj){
      case 'u':
        this.supportForm.controls['username'].setValue(this.supportForm.controls.username.value.trim());
        break;
      case 'e':
        this.supportForm.controls['email'].setValue(this.supportForm.controls.email.value.trim());
        break;
      case 's':
        this.supportForm.controls['subject'].setValue(this.supportForm.controls.subject.value.trim());
        break;
      case 'm':
        this.supportForm.controls['message'].setValue(this.supportForm.controls.message.value.trim());
        break;
    }
  }

  openYoutubeModel(userType,videoUrl){
    let params = {userType:userType,videoUrl:videoUrl}
    const modalRef = this.supportService.openVideoModal(true,params,true).then().catch();
  }

  get userVideoUrl(){
    return GlobalConstants.userVideoUrl;
  }

}