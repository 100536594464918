<form class="appoinment" [formGroup]="scheduleAppointmentForm" novalidate>
        <div class="modal-header">
            <h5 class="modal-title"><i class="icon-grid text-primary mr-2"></i> {{title}}</h5>
            <button type="button" class="close" (click)="cancel() "><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body" style="padding:0.5rem!important;">
            <div class="mx-auto pt-5 col-sm-12 col-md-12 col-xl-6 col-lg-6">
                <h2 class="mb-5">
                    <i class="icon-user text-info mr-2"></i> Who
                </h2>
                <div class="row">
                    <div class="clearfix mb-2 w-100"></div>
                    <div class="col-md-12">
                        <div class="form-group has-float-label">
                            <angular2-multiselect [data]="dropdownList" formControlName="client_name" name="client_name"
                                [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                                (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)" readonly>
                            </angular2-multiselect>

                            <label for="patientName">Client Name</label>
                            <span *ngIf="formErrors.client_name" class="error-msg">
                                {{formErrors.client_name}}
                            </span>
                        </div>
                    </div>
                </div>
                <h2 class="mb-3">
                    <i class="icon-book-open text-success mr-2"></i> Duration
                </h2>
                <div class="row">
                    <div class="clearfix mb-2 w-100"></div>
                    <div class="col-md-12">
                        <div class="form-group has-float-label">
                            <select id="duration" name="duration" formControlName="duration" class="form-control">
                                <option value="">-- Select One --</option>
                                <option *ngFor="let duration of duration_array" [value]=duration.id>
                                    {{duration.itemName}}
                                </option>
                            </select>
                            <span *ngIf="formErrors.duration" class="error-msg">
                                {{formErrors.duration}}
                            </span>
                        </div>
                    </div>
                </div>
                <h2 class="mb-3">
                    <i class="icon-clock text-warning mr-2"></i> When
                </h2>
                <div class="row">
                    <div class="clearfix mb-5 w-100"></div>
                    <div class="col-md-6">
                        <div class="form-group has-float-label">
                            <input class="form-control" type="date" [(ngModel)]="selected_date" min="{{today}}"
                                (click)="currentDate()" formControlName="date" id="date" placeholder="DD-MM-YYYY">
                            <label for="date">Date</label>
                            <span *ngIf="formErrors.date" class="error-msg">
                                {{formErrors.date}}
                            </span>
                            <span *ngIf="date_valid" class="error-msg">
                                {{date_valid}}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group has-float-label timepicker">
                            <!-- <input class="form-control" [(ngModel)]="time" formControlName="edit_time" *ngIf="flag"> -->

                            <input class="form-control" placeholder="Time:" [value]="time" readonly
                                formControlName="time" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2">
                            <owl-date-time [pickerType]="'timer'" (afterPickerOpen)="pickerOpen()" [startAt]="startAt "
                                [hour12Timer]=true #dt2></owl-date-time>
                            <label for="time ">Time ({{timezone}})</label>
                            <span *ngIf="formErrors.time " class="error-msg ">
                                {{formErrors.time}}
                            </span>
                            <span *ngIf="time_valid" class="error-msg">
                                {{time_valid}}
                            </span>
                        </div>
                    </div>
                </div>

                <h2 class="mb-3">
                    <i class="icon-edit text-dark mr-2 "></i> Note
                </h2>
                <div class="row ">
                    <div class="clearfix mb-5 w-100 "></div>
                    <div class="col-md-12 ">

                        <div class="mt-2 ">
                            <div class="form-group has-float-label ">
                                <textarea class="form-control " id="addNote " formControlName="note"
                                    placeholder="Add Note " rows="3 "></textarea>
                                <label for="addNote ">Add Note</label>
                                <span *ngIf="formErrors.note " class="error-msg ">
                                    {{formErrors.note}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="modal-footer justify-content-center" *ngIf="!is_read_only" style="margin-bottom:75px;">
                <button class="btn btn-primary mr-3 " (click)="bookAppointment() ">Save Appointment</button>
                <button class="btn btn-secondary " (click)="cancel() ">Cancel</button>
        </div>
    </form>