import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '../shared/header/header.component';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { AuthService } from './services/auth.service';
import { PatientLoginComponent } from './modules/patient-login/patient-login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './interceptors/HeaderInterceptor';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WorkflowService } from '../app/modules/clinicians-management/workflow/workflow.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PageNotFoundComponent } from '../shared/page-not-found/page-not-found.component';
import { ScheduleAppointmentComponent } from '../shared/components/schedule-appointments/schedule-appointments.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SubscriptionExpireModalComponent } from '../shared/components/subscription-expire-modal/subscription-expire-modal.component';
import { ChartsModule } from 'ng2-charts';
import { WaitingRoomModalComponent } from '../shared/components//waiting-room-modal/waiting-room-modal.component';
import { ClientCheckoutComponent } from './modules/client-checkout/client-checkout.component';
import { SupportComponent } from './modules/support/support-guest/support.component';
import { AdminSubscriptionModalComponent } from './modules/admin-subscription-modal/admin-subscription-modal.component';
import { SharedPopupService } from '../shared/video-overlay/shared-video-overlay-service';
// import { StripeSubscriptionComponent } from './clinicians/stripe-subscription/stripe-subscription.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    PatientLoginComponent,
    PageNotFoundComponent,
    ScheduleAppointmentComponent,
    SubscriptionExpireModalComponent,
    WaitingRoomModalComponent,
    ClientCheckoutComponent,
    SupportComponent,
    AdminSubscriptionModalComponent,
    // StripeSubscriptionComponent
  ],
  imports: [
    BrowserModule,
    SocialLoginModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    SharedModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ChartsModule
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthService, SharedPopupService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('926330392641-k3u7u9vdinr53k788vtu92e9217fkl2i.apps.googleusercontent.com', {
              scope: 'profile email',
              plugin_name: 'engageteletherapy'
            }),
          },
        ],
      } as SocialAuthServiceConfig

    },
    { provide: WorkflowService, useClass: WorkflowService },
    NgbActiveModal,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
