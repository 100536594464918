<button *ngIf="togglePreviewButton" class="btn btn-info exit-button" (click)="exitFullview()">Exit Full Preview</button>
<button *ngIf="togglePreviewButton" class="btn btn-info hide-on-web" (click)="exitFullview()"><i
        class="icon-exit-full-screen"></i></button>
<button *ngIf="!togglePreviewButton" class="btn btn-info show-on-device" (click)="showFullview()"><i
        class="icon-full-screen-selector"></i></button>
<div class="main-content gaming-wrapper pt-5">
    <div class="container-fluid px-0 pt-0" [ngClass]="togglePreviewButton ? 'pt-0 pt-md-5' : 'pt-0 pt-md-0'">

        <div class="row no-gutters mt-2 mt-lg-0">
            <div class="col-md-8 text-center my-0 my-lg-4">
                <span id='pong_msg' class="hide" style="color: white;">
                    Now you can play game by select Play Now option.There is no need of create or join room.You will
                    able to join room when clinician sent room invitation.
                </span>
            </div>
            <div class="col-md-4 text-center text-lg-right my-4 d-none d-lg-block">
                <!-- <li class="record hide" id="recording"><i class="icon-bell" style="color:white"></i></li> -->
                <button *ngIf="!togglePreviewButton" class="btn btn-info" (click)="showFullview()">Click to View Full
                    Preview</button>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-md-4 text-center mb-3">
                <div class="cal-wrapper h-100 d-flex">
                    <div class="row mx-0 w-100">
                        <div class="w-100 video-call">
                            <!-- <div class="clearfix"> </div> -->

                            <div class="align-self-center mr-2 ml-2" id="hidenotify" style="color:white"
                                *ngIf="role=='participant'">
                                <!-- Thank you for joining the calls. Clinicians not joined the call yet. Till the time you can play the games which you wants. -->
                                Hi! Your clinician has not joined the meeting yet. While you wait, sit back, relax, and
                                enjoy a game!
                            </div>

                            <div class="align-self-center" id="hidenotify" style="color:white"
                                *ngIf="role=='moderator'">
                                {{client_name}} not joined meeting yet.
                                <a *ngIf="phone" style="color: white;" href="tel:{{phone}}" title="{{phone}}"><i
                                        class="icon-phone"></i></a>
                            </div>

                            <!------ See self video stream  -->
                            <div id="local_video_div" class="float-right" style="margin-bottom: 20px;"></div>
                            <!------ end here See self video stream  -->
                            <div class="clearfix"></div>
                            <div class="h-100 clinician-video">
                                <div id="multi_video_container_div">
                                </div>
                            </div>
                            <div class="embed-responsive embed-responsive-16by9 d-none" id="showScreen"></div>
                            <div class="embed-responsive embed-responsive-16by9 d-none" id="stick_to_top"></div>
                            <input type="hidden" name="recordCallFlag" id="hiddenCallRecordFlag" value="0">
                        </div>
                    </div>
                    <div class="recording-buttons" id="controls">

                        <ul>
                            <li class="audio" id="audioOn" placement="top" ngbTooltip="Turn off microphone"><a
                                    href="javascript:void(0)" onclick="audioMute()"><i class="icon-mic"></i></a></li>
                            <li class="audio disabled hide" id="audioOff"><a href="javascript:void(0)" placement="top"
                                    ngbTooltip="Turn on microphone" onclick="audioUnMute()"><i
                                        class="icon-mic-off"></i></a></li>
                            <li class="video disabled hide" id="videoMute" placement="top" ngbTooltip="Turn on camera">
                                <a href="javascript:void(0)" onclick="videoUnMute()"><i class="icon-video-off"></i></a>
                            </li>
                            <li class="video" id="videoUnMute" placement="top" ngbTooltip="Turn off camera"><a
                                    href="javascript:void(0)" onclick="videoMute()"><i class="icon-video"></i></a></li>
                            <li class="phone" placement="top" ngbTooltip="Leave call"><a href="javascript:void(0)"
                                    (click)="endCallData()"><i class="icon-phone"></i></a></li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-8 mb-3 bg-white pt-1 pt-md-3">
                <div class="select-games clearfix">
                <div class="float-left">                   
                    <div class="select-games-wrapper ml-2 ml-md-3">
                        <button *ngIf="role=='moderator' && is_show_leave_btn" class="btn btn-primary btn-sm mr-3" (click)="leaveGame()">Leave Game</button>
                        <button class="games-button mr-3" placement="bottom" *ngIf="role=='moderator'" ngbTooltip="Help" (click)="openInstrutionPopup()">
                            <img src="assets/img/Help-icon.png" />
                        </button>
                    </div>
                </div>
                <div ngbDropdown class="d-inline-block pr-3 pr-md-3 mb-2 float-right d-block d-sm-none">
                    <div class="select-games-wrapper">
                        <span class="mr-2" id="dropdownBasic1" ngbDropdownToggle>Games</span>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <div class="select-games-wrapper d-flex flex-wrap justify-content-center">
                            <button *ngFor="let game of gamesList" class="games-button mx-2 my-2"
                                [ngStyle]="{'cursor': this.moderatorIn && this.role == 'participant'  ? 'default' : 'pointer'}"
                                placement="bottom" ngbTooltip="{{game.gameName}}" (click)="changeGame(game.id)">
                                <img src="assets/img/{{game.createdBy}}" />
                            </button>                                                  
                        </div>
                    </div>
                </div>
                <div class="form-group pr-0 pr-md-3 mb-3 float-right d-none d-sm-block">
                    <div class="select-games-wrapper">
                        
                        <span class="mr-2">Games</span>
                        <button *ngFor="let game of gamesList" class="games-button mr-3"
                            [ngStyle]="{'cursor': this.moderatorIn && this.role == 'participant'  ? 'default' : 'pointer'}"
                            placement="bottom" ngbTooltip="{{game.gameName}}" (click)="changeGame(game.id)">
                            <img src="assets/img/{{game.createdBy}}" />
                        </button>
                    </div>
                </div>
            </div>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe #iframe class="embed-responsive-item bg-black" id="changelink" [src]="gameUrl"
                        frameborder="0">
                    </iframe>
                </div>
                <form [formGroup]="forgotPasswordForm">
                    <div class="text-right link-send clearfix mb-4"
                        *ngIf="role=='moderator' && isSendLink && !is_white_board && !is_guess_who">
                        <div class="form-group has-float-label float-right pt-5  mb-4">
                            <button class="btn btn-primary btn-sm mr-3" (click)="sendLink()">Send</button>
                        </div>
                        <div class="form-group has-float-label mt-5 w-50 float-right w-5 mx-3 mb-4">
                            <input class="form-control" type="text" id="nameOfKid" formControlName="nameOfKid"
                                placeholder="Game Link">
                            <label for="nameOfKid">Game Link</label>
                            <span *ngIf="formErrors.nameOfKid" class="error-msg mt-3">
                                {{formErrors.nameOfKid}}
                            </span>
                        </div>

                    </div>

                </form>
            </div>

        </div>

    </div>
</div>
<!-- <div class="container-fluid">
    <div class="row">

      <div class="col-md-12">
        <div class="video_container_div">
          <div class="row">
            <div class="col-md-6 overflow-hidden">
              <div id="local_video_div"></div>
            </div>
            <div class="col-md-6 overflow-hidden">
              <div id="multi_video_container_div"></div>
            </div>
          </div>
          <div class="row">
            <div class="controls" id="controls" style="background-color: grey;
                position: fixed;
                bottom: 0;
                width: 100%;
                text-align: center;">
              <img src="../assets/img/mike.png" style="margin-right: 20px; cursor: pointer;" class="cus_img_icon icon-confo-mute" onclick="audioMute()" title="mute audio" />
              <img src="../assets/img/video.png" style="margin-right: 20px; cursor: pointer;" class="cus_img_icon icon-confo-video-mute" title="mute video" onclick="videoMute()" />
              <img src="../assets/img/end-call.png" style="margin-right: 20px; cursor: pointer;" class="cus_img_icon end_call" title="End_call" onclick="endCall()" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div> -->