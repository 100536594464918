import { Injectable } from '@angular/core';
import { id } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class ValidateFormService {

  constructor() { }

  validateFormFields(injectedForm, obj) {

    if (!injectedForm) { return; }
    const form = injectedForm;
    for (const field in obj.formErrors) {
        obj.formErrors[field] = '';
        const control = form.get(field);
        if ((control && control.touched && !control.valid) || (control && obj.submitted)) {        
            control.markAsTouched(true);
            const messages = obj.validationMessages[field];
            for (const key in control.errors) {
                obj.formErrors[field] = messages[key];
            }
        }
    }
}


MarkControlsAsTouched(formRef) {
  for (const property in formRef.controls) {
    if (property) {
      formRef.controls[property].markAsTouched();
    }
  }
}
 touchSelect(control) {
    control.markAsTouched();
  }
}
