<div class="total-count mt-3" *ngIf="!show"><strong>{{record_label}}:</strong> {{totalRecords}}</div>
<div class="col-md-12">
<div class="justify-content-between align-items-center table-pagination">
    <!-- <div class="d-flex align-items-center">
        Show <span class="plain-select">
            <select class="form-control ml-2 mr-2" id="select3" (change)="ChangingPageSize($event.target.value)">
                <option *ngFor="let i of pageSizeArray" value="{{i}}" [selected]="i===pageSize">{{i}}</option>
            </select>
        </span>
        Entries
    </div> -->
    <nav *ngIf="totalRecords > pageSize && show" aria-label="Page navigation example" class="mt-3">
        <!-- <ul class="pagination justify-content-center">
            <li [ngClass]="{ 'page-item': true, 'disabled':currentPage===startPage }">
                <a class="page-link" href="javascript:void(0)" (click)="changePage(previousPage,0)" tabindex="-1">
                    Previous
                </a>
            </li>
            <li class="page-item" *ngFor="let page of pages" [ngClass]="{'active':currentPage==page}">
                <a class="page-link" (click)="changePage(page,0)">
                    {{page}}
                </a>
            </li>
            <li [ngClass]="{ 'page-item': true, 'disabled':currentPage===endPage }">
                <a class="page-link" href="javascript:void(0)" (click)="changePage(nextPage,0)">
                    Next
                </a>
            </li>
        </ul>         -->
      
            <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-center">
                <!-- <li  class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link" (click)="setPage(1)">First</a>
                </li> -->
                <li  class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                    <a class="page-link" (click)="setPage(pager.currentPage - 1)">Previous</a>
                </li>
                <li  class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
                    <a class="page-link"  (click)="setPage(page)">{{page}}</a>
                </li>               
           
                <li  class="page-item" *ngIf="(pager.totalPages - pager.startPage) > 3 ">
                    <a class="page-link"  (click)="setPage(pager.startPage + 3)">...</a>
                </li>
                 <li  class="page-item" *ngIf="(pager.totalPages - pager.startPage) > 3" >
                    <a class="page-link"  (click)="setPage(pager.totalPages)">{{pager.totalPages}}</a>
                </li>               
                
                <li  class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
                </li>
                <!-- <li  class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                    <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                </li> -->
            </ul>
        
    </nav>
</div>
</div>