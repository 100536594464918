import { environment } from '../../environments/environment';
export class GlobalConstants {
  public static USERNAME_REQUIRED: string = 'Username cannot be blank.';
  public static PASSWORD_REQUIRED: string = 'Password cannot be blank.';
  public static USERNAME_EXIST: string = 'Username already exists!';
  public static EMAIL_EXIST: string = 'Email already exists!';
  public static USERNAME_VALID: string = 'Enter valid username.';
  public static EMAIL_REQUIRED: string = 'Email Address cannot be blank.';
  public static COUNTRY_REQUIRED: string = 'Country cannot be blank.';
  public static EMAIL_VALID: string = 'Enter valid email address.';
  public static PROFESSIONAL_ROLE_REQUIRED: string = 'Professional Role cannot be blank.';
  public static FIRST_NAME_VALID: string = 'Enter valid First Name.';
  public static MIDDLE_NAME_VALID: string = 'Enter valid Middle Name.';
  public static FIRST_NAME_REQUIRED: string = 'First Name cannot be blank.';
  public static LAST_NAME_VALID: string = 'Enter valid Last Name.';
  public static LAST_NAME_REQUIRED: string = 'Last Name cannot be blank.';
  public static COMAPNY_NAME_VALID: string = 'Enter valid Company Name.';
  public static COMPANY_NAME_REQUIRED: string = 'Company Name cannot be blank.';
  public static CITY_VALID: string = 'Enter valid City.';
  public static CITY_REQUIRED: string = 'City cannot be blank.';
  public static PROVIANCE_VALID: string = 'Enter valid Province.';
  public static PROVIANCE_REQUIRED: string = 'Province cannot be blank.';
  public static STATE_REQUIRED: string = 'State cannot be blank.';
  public static ZIPCODE_VALID: string = 'Enter valid Zip Code.';
  public static ZIPCODE_REQUIRED: string = 'Zip Code cannot be blank.';
  public static POSTALCODE_VALID: string = 'Enter valid Postal Code.';
  public static POASTALCODE_REQUIRED: string = 'Postal Code cannot be blank.';
  public static PHONE_VALID: string = 'Enter valid Phone.';
  public static EXTENSION_VALID: string = 'Enter valid Extension.';
  public static PHONE_REQUIRED: string = ' Phone cannot be blank.';
  public static LICENCE_PROVIANCE_REQUIRED: string = 'License Province cannot be blank.';
  public static LICENCE_STATE_REQUIRED: string = 'License State cannot be blank.';
  public static MONTH_REQUIRED: string = 'Month cannot be blank.';
  public static YEAR_REQUIRED: string = 'Year cannot be blank.';
  public static YEAR_VALID: string = 'Enter valid  Year.';
  public static SUPERVISOR_NAME_REQUIRED: string = "Supervisor's Name cannot be blank.";
  public static SUPERVISOR_LICENCE_NO_REQUIRED: string = "Supervisor's License Number cannot be blank.";
  public static SUPERVISOR_LICENCE_PROVIANCE_REQUIRED: string = "Supervisor's License Province cannot be blank.";
  public static SUPERVISOR_LICENCE_STATE_REQUIRED: string = "Supervisor's License State cannot be blank.";
  public static LICENCE_NO_REQUIRED: string = 'License No cannot be blank.';
  public static CREDENTIAL_TYPE_REQUIRED: string = 'Credential Type cannot be blank.';
  public static MESSAGE: string = 'Please check your internet connection.';
  public static NEW_PASSWORD_REQUIRED: string = 'New Password cannot be blank.';
  public static OLD_PASSWORD_REQUIRED: string = 'Old Password cannot be blank.';
  public static FIRSTNAME_REQUIRED: string = 'First Name cannot be blank.';
  public static MIDDLENAME_REQUIRED: string = 'Middle Name cannot be blank.';
  public static LASTNAME_REQUIRED: string = 'Last Name cannot be blank.';
  public static EMAIL_PATTERN: string = 'Please enter a valid email address.';
  public static MOBILE_REQUIRED: string = 'Mobile cannot be blank.';
  public static MOBILE_PATTERN: string = 'Please enter a valid mobile number.';
  public static LICENCE_VALID: string = 'Enter valid License Number.';
  public static MEMBERID_VALID: string = 'Enter valid Membership ID.';
  public static CERTICICATE_VALID: string = 'Enter valid Certificate ID.';

  public static CONFIRM_PASSWORD_REQUIRED: string = 'Confirm Password cannot be blank.';
  public static VALID_PASSWORD_REQUIRED: string = 'Password should be of minimum 8 characters. It must contain at least one uppercase,one lowercase,one special character & one number.';
  public static PASSWORD_NOT_MATCH: string = 'New Password & Confirm Password does not matched.';
  public static PASSWORD_NOTE: string = ' Note: Password should be of minimum 8 characters. It must contain at least one uppercase,one lowercase,one special character & one number.';

  public static MUST_NOT_MATCH: string = 'New Password must be different from old password';

  public static SERVER_ERROR: string = 'Cannot connect to server.';

  public static CLIENTNAME_REQUIRED: string = 'Client Name cannot be blank.';
  public static CLIENTNAME_VALID: string = 'Enter valid Client Name.';
  public static CLIENTMOBILE_REQUIRED: string = 'Client Mobile cannot be blank.';
  public static CLIENTMOBILE_VALID: string = 'Enter valid Client mobile number.';
  // public static PARRENTNAME_REQUIRED : string= 'Parent/Guardian Name cannot be blank.';
  public static PARRENT_NAME_VALID: string = 'Enter valid Parent/Guardian Name.';
  public static EMAIL_OF_PARRENT_REQUIRED: string = 'Parent/Guardian Email Address cannot be blank.';
  public static EMAIL_OF_PARRENT_VALID: string = 'Enter valid Parent/Guardian Email.';
  // public static PARRENT_GUARDIAN_MOBILE_REQUIRED: string = 'Parent/Guardian Mobile cannot be blank.';
  public static PARRENT_GUARDIAN_MOBILE_VALID: string = 'Enter valid Parent/Guardian mobile number.';
  // public static EMR_CONTACT_NAME_REQUIRED: string = 'Emergency contact Name cannot be blank.';
  public static EMR_CONTACT_NAME_VALID: string = 'Enter valid Emergency contact Name.';
  public static CLIENTEMAIL_REQUIRED: string = ' Client Email Address cannot be blank.';
  public static CLIENTEMAIL__VALID: string = 'Enter valid Client Email.';
  public static EMR_CONTACTELATION_VALID: string = 'Enter valid Emergency contact Relation.';
  public static EMR_CONTACT_VALID: string = 'Enter valid Emergency contact.';
  public static ZIP_VALID: string = 'Enter valid zip code.';
  public static POSTAL_VALID: string = 'Enter valid Postal code.';
  public static ISAGREE_REQUIRED: string = 'Please accept Terms & Conditions.';
  public static DEACTIVATE_PATIENT: string = 'Are you sure you want to deactivate this client?';
  public static ACTIVATE_PATIENT: string = 'Are you sure you want to activate this client?';
  public static END_CALL: string = 'Are you sure you want to end this session?';
  public static CLINICAL_END_CALL: string = 'Are you sure you want to end this session? Your client will be prompted for Survey.';
  /***********************REGEX******************************************************************/
  public static PASSWORDPATTERN = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  public static IS_CONSNT_TO_TREAT = 'Please select "I give give consent to clinician to treat my child".';
  public static TIMEZONE_REQUIRED = 'Please select Time zone.';
  public static URL_COPIED = 'URL copied Successfully.';
  public static CALL_MSG = 'To start new session, Please end your current session.';
  public static ZEROMINS = 'Minutes should be greater than 0';
  public static MAXMIN = 'The maximum allowed minutes are 5,00000';
  // public static MAXMIN = 'The maximum allowed minutes are 299,970';
  //Push method name only when you do not want to Interceptor loader.
  public static globalArray = [environment.authServiceUrl + 'oauth2/token',
  environment.userServiceUrl + 'me',
  environment.userServiceUrl + 'me/logout',
  environment.userServiceUrl + 'clinician/view',
  environment.userServiceUrl + 'clinician/statelist-countrywise',
  environment.userServiceUrl + 'clinician/update-basicinfo',
  environment.userServiceUrl + 'clinician/update-namelocationinfo',
  environment.userServiceUrl + 'clinician/update-verificationinfo',
  environment.userServiceUrl + 'clinician/validate-unique-user',
  environment.userServiceUrl + 'clinician/country-list/',
  environment.userServiceUrl + 'patient/get-patient-details',
  environment.userServiceUrl + 'patient/state-country-list',
  environment.userServiceUrl + 'dashboard/appointment-search-data',
  environment.userServiceUrl + 'dashboard/client-search-data',
  environment.userServiceUrl + 'schedule-appointments/get-waiting-room-session',
  environment.userServiceUrl + 'schedule-appointments/is-clinician-joined',
  environment.userServiceUrl + 'schedule-appointments/book-session',
  environment.userServiceUrl + 'schedule-appointments/session-room-start',
  environment.userServiceUrl + 'schedule-appointments/room-connect',
  environment.userServiceUrl + 'schedule-appointments/update-patient-last-seen',
  ];

  public static sidebarArray = ['/change-password',
    '/clinicians/clinician-profile',
    '/clinicians/appointments',
    '/clinicians/dashboard',
    '/clinicians/patients-management',
    '/clinicians/add-patient',
    '/clinicians/calendar',
    '/clinicians/subscription',
    '/clinicians/dashboard',
    '/clinicians/survey-dashboard'];
  public static dropdownList = [
    { "id": "ABA Therapist", "itemName": "ABA Therapist" },
    { "id": 'Child Life Specialist', "itemName": "Child Life Specialist" },
    { "id": 'Clinical Psychologist', "itemName": "Clinical Psychologist" },
    { "id": "Coach", "itemName": "Coach" },
    { "id": 'Creative Arts Therapist', "itemName": "Creative Arts Therapist" },
    { "id": 'Developmental Psychologist', "itemName": "Developmental Psychologist" },
    { "id": 'Dietitian', "itemName": "Dietitian" },
    { "id": 'Early Education', "itemName": "Early Education" },
    { "id": 'Forensic Psychologist', "itemName": "Forensic Psychologist" },
    { "id": 'Marriage & Family Therapist', "itemName": "Marriage & Family Therapist" },
    { "id": "Master's Level Clinician", "itemName": "Master's Level Clinician" },
    { "id": "Medical Professional", "itemName": "Medical Professional" },
    { "id": "Mental Health Counselor", "itemName": "Mental Health Counselor" },
    { "id": "Nurse Practitioner", "itemName": "Nurse Practitioner" },
    { "id": "Nutritionist", "itemName": "Nutritionist" },
    { "id": "Occupation Therapist", "itemName": "Occupation Therapist" },
    { "id": "Pastoral Counseling", "itemName": "Pastoral Counseling" },
    { "id": "Pediatrician", "itemName": "Pediatrician" },
    { "id": "Physical Therapist", "itemName": "Physical Therapist" },
    { "id": "Psychiatrist", "itemName": "Psychiatrist" },
    { "id": "Psychologist", "itemName": "Psychologist" },
    { "id": "Play Therapist", "itemName": "Play Therapist" },
    { "id": "School Administration", "itemName": "School Administration" },
    { "id": "Secondary Education", "itemName": "Secondary Education" },
    { "id": "Social Worker", "itemName": "Social Worker" },
    { "id": "Special Education", "itemName": "Special Education" },
    { "id": "Speech & Language Pathologist", "itemName": "Speech & Language Pathologist" },
    { "id": "Student Intern", "itemName": "Student Intern" },
    { "id": "Substance Abuse Counselor", "itemName": "Substance Abuse Counselor" },
    { "id": "Other Professional Role", "itemName": "Other Professional Role" }
  ];

  public static dropdownListOld = [
    { "id": 'Creative Arts Therapist', "itemName": "Creative Arts Therapist" },
    { "id": "Play Therapist", "itemName": "Play Therapist" },
    { "id": "Occupation Therapist", "itemName": "Occupation Therapist" },
    { "id": "Physical Therapist", "itemName": "Physical Therapist" },
    { "id": "Speech & Language Pathologist", "itemName": "Speech & Language Pathologist" },
    { "id": "Social Worker", "itemName": "Social Worker" },
    { "id": "Mental Health Counselor", "itemName": "Mental Health Counselor" },
    { "id": 'Clinical Psychologist', "itemName": "Clinical Psychologist" },
    { "id": "Nurse Practitioner", "itemName": "Nurse Practitioner" },
    { "id": "Psychiatrist", "itemName": "Psychiatrist" },
    { "id": 'Marriage & Family Therapist', "itemName": "Marriage & Family Therapist" },
    { "id": "Substance Abuse Counselor", "itemName": "Substance Abuse Counselor" },
    { "id": "Coach", "itemName": "Coach" },
    { "id": 'Dietitian', "itemName": "Dietitian" },
    { "id": "Nutritionist", "itemName": "Nutritionist" },
    { "id": "Psychologist", "itemName": "Psychologist" },
    { "id": 'Forensic Psychologist', "itemName": "Forensic Psychologist" },
    { "id": 'Developmental Psychologist', "itemName": "Developmental Psychologist" },
    { "id": "Pediatrician", "itemName": "Pediatrician" },
    { "id": "ABA Therapist", "itemName": "ABA Therapist" },
    { "id": "Special Education", "itemName": "Special Education" },
    { "id": 'Early Education', "itemName": "Early Education" },
    { "id": "Secondary Education", "itemName": "Secondary Education" },
    { "id": 'Child Life Specialist', "itemName": "Child Life Specialist" },
    { "id": "Student Intern", "itemName": "Student Intern" },
    { "id": "Pastoral Counseling", "itemName": "Pastoral Counseling" },
    { "id": "Medical Professional", "itemName": "Medical Professional" },
    { "id": "School Administration", "itemName": "School Administration" },
    { "id": "Master's Level Clinician", "itemName": "Master's Level Clinician" },
    { "id": "Other Professional Role", "itemName": "Other Professional Role" },
  ];

  public static expirationMonth = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
  ];

  public static expirationYear = [
    '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'
  ];

  public static preExpirationYear = [
    '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020'
  ];

  public static DTAILS_ADDED: string = 'Clinician details saved successfully.';
  public static PROFILE_UPDATED: string = 'Profile updated successfully.';
  public static PLAN_REQUIRED: string = 'Please select Plan.';
  public static NAMEONCARD_REQUIRED: string = 'Name on Card cannot be blank.';
  public static MINS_REQUIRED: string = 'Minutes cannot be blank.';
  public static VALID_NAMEONCARD: string = 'Enter valid Name on Card.';
  public static SERVICE_DURATION = [
    { "id": 30, "itemName": "30 minute appointment" },
    { "id": 45, "itemName": "45 minute appointment" },
    { "id": 60, "itemName": "60 minute appointment" },
  ];
  public static DURATION_REQUIRED = 'Duration cannot be blank.';
  public static DATE_REQUIRED = 'Date cannot be blank.';
  public static MINDATE = "Subscription end date should be greater than today's date.";
  public static TIME_REQUIRED = 'Time cannot be blank.';
  public static OTP_REQUIRED: string = 'One Time Password cannot be blank.';
  public static IMAGE_TYPE: string = 'Please upload jpg, jpeg, png, gif files only.';
  public static IMAGE_SIZE: string = 'File size should be upto 10 MB.';
  public static GAME_LINK: string = 'Game link sent successfully.';
  public static CALL_LINK_EXPIRING: string = 'Kindly make a note that call will end in 5 mins.';
  public static CALL_ERROR: string = 'Something went wrong.';
  public static PONG_MSG: string = 'Choose play now, no create or join room. Pong will be able to create room and join room when clinician and client are playing.';
  public static GAME_LINK_ERROR: string = 'Game link cannot be blank .';
  // public static WBO_DEMO_URL: string = 'https://php7.benchmarkit.solutions/wbo/';
  // public static WBO_LIVE_URL: string = 'https://app.engageplaytherapy.com/wbo/';
  // public static GUESS_WHO_URL: string = 'https://app.engageplaytherapy.com/Games/regular_game/EngageTherapyGuessWho/';
  public static STATUS_LIST = [
    { "id": "upcoming", "itemName": "Upcoming" },
    { "id": "cancelled", "itemName": "Cancelled" },
    { "id": "completed", "itemName": "Completed" }
  ];

  public static MONTHS = [
    { "id": "01", "itemName": "January" },
    { "id": "02", "itemName": "February" },
    { "id": "03", "itemName": "March" },
    { "id": "04", "itemName": "April" },
    { "id": "05", "itemName": "May" },
    { "id": "06", "itemName": "June" },
    { "id": "07", "itemName": "July" },
    { "id": "08", "itemName": "August" },
    { "id": "09", "itemName": "September" },
    { "id": "10", "itemName": "October" },
    { "id": "11", "itemName": "November" },
    { "id": "12", "itemName": "December" },
  ];
  public static showSharedScreen = true;

  public static CLINICIAN_VIEW_VIDEO_LINK = "https://youtube.com/embed/1lHAY5CKxiw";
  public static CLIENT_VIEW_VIDEO_LINK    = "https://youtube.com/embed/C71V6AJ_EVU";
  public static SESSION_VIEW_VIDEO_LINK   = "https://youtube.com/embed/s-0D0xg6Jfc";
  
  public static userVideoUrl = {
    'Clinician':GlobalConstants.CLINICIAN_VIEW_VIDEO_LINK,
    'Client'   :GlobalConstants.CLIENT_VIEW_VIDEO_LINK,
    'Session'  :GlobalConstants.SESSION_VIEW_VIDEO_LINK
  }

}