import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

interface link {
  link: String,
}

export interface linkData {
  appId: String;
  link: String;    
}

@Injectable({
  providedIn: 'root'
})
export class RoomLinkService {

  constructor(private http: HttpClient) { }

  getLink (): Observable<link> {
    return this.http.get<link>(`${environment.apiUrl}/userCount`)
  }

  addNewUser (linkData: linkData): Observable<linkData> {
    return this.http.post<linkData>(`${environment.apiUrl}/addUser`, linkData)
  }
}
