import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WaitingRoomModalComponent} from '../../shared/components/waiting-room-modal/waiting-room-modal.component';



@Injectable({
  providedIn: 'root'
})
export class WaitingRoomModalService {

  constructor(private modalService: NgbModal) {
    
   }

  public openWaitingRoomAppointmentModal( 
    dialogSize: 'sm'|'lg' = 'lg',
    ): Promise<boolean> {
      const modalRef = this.modalService.open(WaitingRoomModalComponent, { size: dialogSize, backdrop: 'static', centered: true});
      return modalRef.result;
  }
}
