import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanLoad, Route,UrlTree 
} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from './auth.service';
import{ SubscriptionExpireModalService} from './subscription-expire-modal.service';
import { ToastrService } from 'ngx-toastr';
import{ GlobalConstants } from '../../shared/constants/global-constants';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
 
@Injectable()
export class SubscriptionGuard implements CanActivate {

    current_user_id:string = '';
    constructor(private router: Router,private spinner :  NgxSpinnerService,
         public authService : AuthService ,
         private toastr:ToastrService,
         private subscriptionExpireModalService: SubscriptionExpireModalService,
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree  {
        // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        // this.initcall(); 
        return this.getCurrentUser();
        // var _this = this;
        // var timeout = setTimeout(function(){
        //     console.log("setTimeout >>>>: " );
        //     _this.getCurrentUser();
        //   }, 1000);
            
        //   return true;

        // return of(this.getCurrentUser()).pipe(
        //     delay(2000), // Delay for 3 seconds
        //   ); 
    }
    
    getCurrentUser(): boolean {  
        this.spinner.show();
        let root = this;     
        const clinicianProfile = this.authService.me().subscribe(data => {
            if (data.status !== '401') {
                if(data.entityName == 'clinician')
                {   
                    ///////////////////////Existing Old code///////////////////////////////////                // if(data.email == null || data.timeZone == null || data.userName == null ||  data.firstName == null || data.lastName == null || data.city == null|| data.state == null|| data.zipCode == null|| data.country == null || data.professionalRole == null|| data.phone == null){
                        /* if(data.email == null || data.userName == null ||  data.firstName == null || data.lastName == null  ){
                                this.toastr.error( 'Please update your profile information.');
                                this.spinner.hide();
                                this.router.navigate(['/clinicians/clinician-profile']); 
                            return false; 
                            }else{                        
                                // return true; 
                                const subscribe = this.authService.me().subscribe(data => {
                                    if (data.status !== '401') {
                                    this.current_user_id = data.id;
                                        if(data.stripeStatus != 'paid' || data.status == 'E' || (data.is_subscription_expire == 'invalid')){                        
                                            this.renewSubscriptionModal(this.current_user_id);
                                            return false;
                                        }else{
                                            return true;
                                        }
                                    }
                            
                                }, (error) => console.log(error.message));
                                subscribe.add(()=>this.spinner.hide());
                                return true;
                            } */
                    ///////////////////////Existing Old code///////////////////////////////////

                    //////////////////////Added by Ankita - start subscription conditions////////////////////
                        var balanceHour = data.balanceHour; 
                        if( (data.after30days || balanceHour == 0) && data.paymentStatus != 'paid')
                        {  
                            this.callRenewSubscriptionModal();
                            return false; 
                        }
                        else if( !data.after30days )
                        {    
                            if( (data.balanceHour == 0 || data.balanceHour == "" || data.is_subscription_expire == 'invalid' || data.status == 'E') && (data.paymentStatus != 'paid' || data.stripeStatus != 'paid') ) 
                                { 
                                    // console.log(data.paymentStatus ,">>after30days qqqqqqqqqqqqqqqqqq");
                                    //    this.toastr.error( 'Click the below plan to renew your Subscription.');
                                    this.spinner.hide();
                                    this.current_user_id = data.id; 
                                    this.callRenewSubscriptionModal(); 
                                    return false;
                            }
                            else { 
                                // console.log("lllllllllllllll");
                                if(data.paymentStatus == 'paid'){
                                    if(data.email == null || data.timeZone == null || data.userName == null ||  data.firstName == null || data.lastName == null || data.city == null|| data.state == null|| data.zipCode == null 
                                        || data.country == null || data.professionalRole == null|| data.phone == null 
                                    //   || (data.userGoal == null) || data.socialMediaPlatform == null || data.scalerate == null || data.hearAbout == null || data.hopeForEngage == null || data.businessWebsite == null || data.jobtitle == null
                                        ||  data.userGoal == "" || (data.socialMediaPlatform == "") || data.hearAbout ==  "" || data.hopeForEngage == "" || data.businessWebsite == ""  || data.jobtitle == ""     
                                        )
                                    { 
                                        this.toastr.error( 'Please update your profile information.');
                                        this.spinner.hide();
                                        this.router.navigate(['/clinicians/clinician-profile']); 
                                        return false;  
                                    }
                                }else{
                                    // console.log("dashboard");  
                                    return true; 
                                } 
                            }  
                        }
                        else {  
                            if(data.paymentStatus == 'paid')
                            {
                                // console.log(data.paymentStatus, ">>>>data.paymentStatus");
                                //existing user
                                const subscribe = this.authService.me().subscribe(datas => {
                                    if (datas.status !== '401') {
                                        // console.log(data.balanceHour, ">>>>data.balanceHour");
                                        // console.log(datas , ">>>>ELSE datas");

                                        if(datas.balanceHour < 10 || datas.balanceHour == 0)
                                        {
                                            // console.log("jjjjjjjjjjjjjjjjjj");
                                            this.callRenewSubscriptionModal();
                                            return false; 
                                        }else 
                                        {
                                            // console.log("aaaaaaaaaaaaaaaaaaa");
                                            if(datas.email == null || datas.timeZone == null || datas.userName == null ||  datas.firstName == null || datas.lastName == null || datas.city == null|| datas.state == null|| datas.zipCode == null 
                                                || datas.country == null || datas.professionalRole == null|| datas.phone == null 
                                            //   || (datas.userGoal == null) || datas.socialMediaPlatform == null || datas.scalerate == null || datas.hearAbout == null || datas.hopeForEngage == null || datas.businessWebsite == null || datas.jobtitle == null
                                            ||  datas.userGoal == "" || (datas.socialMediaPlatform == "") || datas.hearAbout ==  "" || datas.hopeForEngage == "" || datas.businessWebsite == ""  || datas.jobtitle == ""     
                                            )
                                            { 
                                                this.toastr.error( 'Please update your profile information.');
                                            this.spinner.hide();
                                            this.router.navigate(['/clinicians/clinician-profile']); 
                                            return false;  
                                            }
                                            else{
                                            // console.log("kkkkkkkkkkkkkkkk");  
                                            return true; 
                                            } 
                                        }
        
                                    }
                            
                                }, (error) => console.log(error.message));
                                subscribe.add(()=>this.spinner.hide()); 
                            }
                        }   
                    //////////////////////Added by Ankita - End subscription conditions////////////////////
                }else{ 
                    return true;       
                } 
            }else{
                this.spinner.hide();
                this.toastr.error( data.message);
                return true;   
            }
        }, (error) =>this.errorCallBack(error, root));
        /*const subscribe = this.authService.me().subscribe(data => {
              if (data.status !== '401') {
               this.current_user_id = data.id;
            //    console.log(this.current_user_id)
                    if(data.stripeStatus != 'paid' || data.status == 'E' || (data.is_subscription_expire == 'invalid')){                        
                        //this.router.navigate(['/clinicians/clinicians-dashboard']);
                        this.router.navigate(['/clinicians/dashboard']);
                        this.renewSubscriptionModal(this.current_user_id);
                        return false;
                    }else{
                        return true;
                    }
              }
            //   else{
            //     this.toastr.error( data.message);
            //   }
            }, (error) => console.log(error.message));
            subscribe.add(()=>this.spinner.hide());
            return true;*/
            return true;
      }  

      errorCallBack(error, root){
        root.toastr.error(error.message || GlobalConstants.SERVER_ERROR);
        root.spinner.hide();
      }
      
      renewSubscriptionModal(id) {   
        var fromParent = id;
        const modalRef = this.subscriptionExpireModalService.openModal(true,fromParent,false).then((data) =>console.log(data,'data'))
        .catch((error) => this.toastr.error(error.message));
      }
    
    //Added by Ankita - for subscription renew popup.
    callRenewSubscriptionModal( )
    {    
        const subscribe = this.authService.me().subscribe(data => {
            if (data.status !== '401') {
                this.current_user_id = data.id; 
                // console.log(data , ">>data renew modal");    
                    if(data.paymentStatus != 'paid' || data.status == 'E' || (data.is_subscription_expire == 'invalid')){                        
                        this.renewSubscriptionModal(this.current_user_id);
                        return false;
                    }else{
                        return true;
                    }
            } 
        }, (error) => console.log(error.message));
            subscribe.add(()=>this.spinner.hide());
        return true;
    } 
}


