import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkflowGuard }        from '../app/modules/clinicians-management/workflow/workflow-guard.service';
import { PageNotFoundComponent } from 'src/shared/page-not-found/page-not-found.component';
import { SubscriptionGuard }        from '../app/services/subscription-guard.service';
import { AuthGuardService }        from '../app/services/auth-guard.service';
import { ClientCheckoutComponent } from '../app/modules/client-checkout/client-checkout.component';
import { SurveyComponent } from '../app/modules/survey/survey.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  { path: 'admin-profile',canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/admin-profile/admin-profile.module').then(m => m.AdminProfileModule)
  },
  { path: 'message-mgmt',canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/admin-msg-mgmt/admin-msg-mgmt.module').then(m => m.AdminMsgMgmtModule)
  },
  { path: 'message-board-mgmt',
    loadChildren: () => import('./modules/admin-msg-board/admin-msg-board.module').then(m => m.AdminMsgBoardModule)
  },
  { path: 'survey-dashboard',canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/survey-dashboard/survey-dashboard.module').then(m => m.SurveyDashboardModule)
  },
  { path: 'change-password',
      loadChildren: () => import('./modules/admin-change-password/admin-change-password.module').then(m => m.AdminChangePasswordModule )
  },
  { 
    path: 'set-password/:token/:verifyUser',
    loadChildren: () => import('./modules/set-password/set-password.module').then(m => m.SetPasswordModule)
  },
  {
    path: 'dashboard',canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'clinicians-management',canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/clinicians-management/clinicians-management.module').then(m => m.CliniciansManagementModule)
  }, 
  {
    path: 'clinicians',
    loadChildren: () => import('./modules/clinicians/clinicians.module').then(m => m.CliniciansModule)
  },
  {
    path: 'clinicians-meeting',
    loadChildren: () => import('./modules/patient-login/patient-login.module').then(m => m.PatientLoginRoutingModule)
  },
  {
    path: 'meeting-otp',
    loadChildren: () => import('./modules/meeting-otp/meeting-otp.module').then(m => m.MeetingOtpModule)
  },
  {
    path: 'session',
    loadChildren: () => import('./modules/session/session.module').then(m => m.SessionModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'survey/:N',
    loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule)
  }, 
  {
    path: 'checkin/:N',
    component: ClientCheckoutComponent
  },

  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  }, 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: true,
      }
    )
  ],
  exports: [RouterModule],
  providers: [WorkflowGuard,SubscriptionGuard,AuthGuardService]
})
export class AppRoutingModule { }
