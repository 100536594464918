import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpErrorResponse} from '@angular/common/http';
import { ApiService, HttpReqMethod } from './api.service';
import { map, catchError } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { Router} from '@angular/router';
import{ GlobalConstants } from '../../shared/constants/global-constants';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user_type: any;
  isUserLoggedInHeader = new Subject<any>();
  constructor(private httpClient: HttpClient, 
              private apiService: ApiService,
              private router: Router) {

   }

  //user authentication
  public authenticate(data) {
    return this.apiService.Request(environment.authServiceUrl + 'oauth2/token',
      HttpReqMethod.POST.toString(), data).pipe(
        map(res => {
          return res;
        }),
       catchError(this.handleError)
      )
  }

 //handle error
  handleError(error: HttpErrorResponse) {
   return throwError(error.error || 'Server error');
  }

 
  //get users role
  getAccessControl(){
    this.user_type = localStorage.getItem('user_type');
    return this.user_type;
  }

  //get login users details
  me(){
    return this.apiService.Request(environment.userServiceUrl + 'me', HttpReqMethod.GET.toString()).pipe(
      map(res => {
        console.log(res,"me auth");
        if(res.url != null && res.url != '' && res.url != undefined){
          localStorage.setItem('url', res.url);
          localStorage.setItem('validAfter30DaysSubscription', res.after30days); 
        }
       return res;
      }),
     catchError(this.handleError)
    );
  }
  //get clinicianId details
  clinicianData(clinicianId:string){
    return this.apiService.Request(environment.userServiceUrl + 'me?id='+clinicianId, HttpReqMethod.GET.toString()).pipe(
      map(res => {
        if(res.url != null && res.url != '' && res.url != undefined){
          localStorage.setItem('url', res.url);
        }
       return res;
      }),
     catchError(this.handleError)
    );
  }

  data: {};
  refreshToken(){
    this.data ={
      "grant_type" : environment.loginGrantType,
      "client_id" : environment.clientId,
      "client_secret" : environment.clientSecret,
      "refresh_token" : localStorage.getItem('refreshToken'),
    }
    if (this.isAuthenticated() === true) {
      this.apiService.Request(environment.authServiceUrl + 'oauth2/token',
        HttpReqMethod.POST.toString(), this.data).subscribe(data => {
          if (data !== 'undefined' && data !== null) {
            if (data.access_token !== 'undefined' && data.access_token !== null) {
              this.setLocalStorageData(data);
            }
          }
        }, (error) => {
          if (error.status === 400 || error.status === 401 || error.status === 500) {
            localStorage.clear();
            this.router.navigate(['/login']);
          }
          // Error on post request.
          return throwError(error);
        });
    } 
  }

  // Get Login status
  isAuthenticated(): boolean {
    const token = localStorage.getItem('accessToken');
    if (token === undefined || token == null) {
      return false;
    } else {
      return true;
    }
  }


setLocalStorageData(data){
    localStorage.setItem('accessToken', data.access_token);
    localStorage.setItem('accessTokenUser', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);
    localStorage.setItem('is_user_logged_in', '1');
 }

 clearToken(){
  localStorage.removeItem('accessToken');
  localStorage.removeItem('accessTokenUser');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('expiryTime');
  localStorage.removeItem('user_type');
  localStorage.removeItem('is_user_logged_in');
  localStorage.removeItem('is_session_started');
  localStorage.removeItem('url');
  localStorage.removeItem('profileImagePath');
  localStorage.removeItem('msgBrdTermsAcpted');
 }

 logout(){
  return this.httpClient.post(environment.userServiceUrl + 'me/logout', HttpReqMethod.POST.toString()).pipe(
    map(res => {
      return res;
    }),
   catchError(this.handleError)
  );
 }

 setLoggedInUsername(val: string) {
  localStorage.setItem('fullName', val);
 }

  tost_obj : any = {};
  isAlert = false;
  type: string;
  message: string;
  showToastMessage(type: any, message: any){
    setTimeout(() => this.tost_obj.isAlert = false, 10000);
    this.tost_obj.isAlert = true;
    this.tost_obj.type = type;
    this.tost_obj.message = message ? message : GlobalConstants.MESSAGE;
    return this.tost_obj;
   }

  /**
   *  Bhagyashri Nikam 
   * 14-Feb-2019
   * This function get userNameInitial globally.
   */
  first_name_initial = '';
  get loggedInUserNameInitial(): string {
      if(localStorage.getItem('firstName') === null) 
         this.first_name_initial = '';
      else 
         this.first_name_initial =  localStorage.getItem('firstName').trim().charAt(0).toUpperCase();
    return this.first_name_initial;
  }

  getUserfromUserName(data){
    return this.apiService.Request(environment.userServiceUrl + 'forgot-password/forgot-password',
    HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    )
  }
  verifyLink(data){
    return this.apiService.Request(environment.userServiceUrl + 'forgot-password/verify-link',
    HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    )
  }

  setPasswordLink(data){
    return this.apiService.Request(environment.userServiceUrl + 'forgot-password/set-password',
    HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    )
  }

  changePassword(data){
    return this.apiService.Request(environment.userServiceUrl + 'me/change-password',
    HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    )
    
  }

  updateProfile(data){
    return this.apiService.Request(environment.userServiceUrl + 'me/update-profile',
    HttpReqMethod.POST.toString(), data).pipe(
      map(res => {
        return res;
      }),
     catchError(this.handleError)
    )
  }

  getfirstName(){
   return localStorage.getItem('firstName');
  }

  getlastName(){
    return localStorage.getItem('lastName');
  }

  getProfileImage(){
    if(localStorage.getItem('profileImagePath') != 'undefined')
       return localStorage.getItem('profileImagePath');   
  }

  isUserLoggedIn(){
   let is_user_logged_in =  localStorage.getItem('is_user_logged_in');
   if(is_user_logged_in == '1'){
      return true;
    }
   return false;
  }
  isUserSidebar(){
   let is_user_sidebar_in =  localStorage.getItem('user_type');
   if(is_user_sidebar_in != ''){
      return true;
    }
   return false;
  }
}