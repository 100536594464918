import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-youtube-video-modal',
  templateUrl: './youtube-video-modal.component.html',
  styleUrls: ['./youtube-video-modal.component.scss']
})
export class YoutubeVideoModalComponent implements OnInit {
  safeurl:any;
  selectedUsertype:any;
  @Input() params:any;

  constructor(private _sanitizer: DomSanitizer,private activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.selectedUsertype = this.params.userType;
    this.safeurl = this._sanitizer.bypassSecurityTrustResourceUrl(this.params.videoUrl);
  }

  closeVideoModel(){
    this.activeModal.close();
  }

}
